import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TASK_TYPES } from '../shared/data.js'
import { Button, ButtonGroup, DropdownButton, Glyphicon, MenuItem } from 'react-bootstrap'
import { updateCalendarTaskType } from '../state/actions.js'

class SelectedTaskType extends Component {
    render () {
        const { taskType, updateCalendarTaskType, calendarJob } = this.props

        if (!taskType) {
            return null
        }

        const bookingTypes = TASK_TYPES
            .filter((type) => !!calendarJob === type.job && type.booking)
            .map((type) => type.name)
            .map((type) => (
                <MenuItem
                    key={type}
                    eventKey={type}
                    onClick={() => {
                        updateCalendarTaskType(type)
                    }}
                >
                    {type}
                </MenuItem>
            ))

        return (
            <ButtonGroup>
                <DropdownButton
                    type="button"
                    bsStyle="success"
                    title={'Booking: ' + taskType}
                    id={'SelectedTaskType'}
                >
                    {bookingTypes}
                </DropdownButton>
                <Button
                    bsStyle="success"
                    onClick={() => {
                        updateCalendarTaskType(null)
                    }}
                >
                    <Glyphicon glyph={'remove'} />
                </Button>
            </ButtonGroup>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        taskType: state.calendar.taskType,
        calendarJob: state.calendar.job,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateCalendarTaskType: (taskType) => {dispatch(updateCalendarTaskType(taskType))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedTaskType)
