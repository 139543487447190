import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Selector } from "../components"
import {JOB_STATES} from "../shared/data"
import {updateJobsFilter} from "../state/actions"

class Status extends Component {
    render () {
        const { status, updateJobsFilter } = this.props

        const options = Object.values(JOB_STATES)
            .filter(state => state !== 'Deleted')
            .map(state => ({
                    id: state,
                    value: state,
                }))

        return (
            <Selector
                id={'jobs-status-selector'}
                items={options}
                selectedId={status}
                title={'Status'}
                valueUpdated={updateJobsFilter}
                valueUpdatedId={'status'}
                multiSelect={true}
                hideIfNullValue={true}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.job.status,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateJobsFilter: (key, value) => {dispatch(updateJobsFilter(key, value))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Status)
