import React, {Component} from 'react'
import moment from 'moment'
import 'moment/locale/en-gb'
import {
    HashRouter as Router,
    Route,
    NavLink
} from 'react-router-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'

import './App.css'
import Home from './Home'
import ToDo from './ToDo'
import Calendar from './Calendar'
import Job, { ViewJob, NewJob } from './Job'
import Login from './Login'
import Logout from './Logout'
import ConfigLoader from './Config/ConfigLoader'
import reducer from './state/reducer.js'
import {Glyphicon} from 'react-bootstrap'
import Loader from './RequestLoader'
import { SearchCustomersRequester } from './Customer'
import Modals from './Modals'
import { getStartingState } from './state/startingState.js'
import Reset from "./Reset"
import FormPage from "./Form/FormPage"
import FormListPage from "./Form/FormListPage"
import PeoplePage from "./People/PeoplePage"
import ConditionalRole from "./ConditionalRole"

class App extends Component {
    render() {
        moment.locale('en-GB')

        return (
            <Provider store={createStore(reducer, getStartingState())}>
                <div>
                    <ConfigLoader />
                    <Router>
                        <div>
                            <div className={'btn-group'} style={{margin: '10px'}}>
                                <NavLink className="btn btn-lg btn-primary" activeClassName="active" exact to="/" title="Home">
                                    <Glyphicon glyph="home" /><span className="hidden-xs">&nbsp;&nbsp;Home</span>
                                </NavLink>
                                <NavLink className="btn btn-lg btn-primary" activeClassName="active" exact to="/todo" title="To do">
                                    <Glyphicon glyph="check" /><span className="hidden-xs">&nbsp;&nbsp;To do</span>
                                </NavLink>
                                <NavLink className="btn btn-lg btn-primary" activeClassName="active" to="/calendar" title="Calendar">
                                    <Glyphicon glyph="calendar" /><span className="hidden-xs">&nbsp;&nbsp;Calendar</span>
                                </NavLink>
                                <NavLink className="btn btn-lg btn-primary" activeClassName="active" to="/job" title="Jobs">
                                    <Glyphicon glyph="cog" /><span className="hidden-xs">&nbsp;&nbsp;Jobs</span>
                                </NavLink>
                                <NavLink className="btn btn-lg btn-primary" activeClassName="active" to="/job-new" title="New job">
                                    <Glyphicon glyph="plus" /><span className="hidden-xs">&nbsp;&nbsp;New job</span>
                                </NavLink>
                                <NavLink className="btn btn-lg btn-primary" activeClassName="active" to="/form" title="Forms">
                                    <Glyphicon glyph="list-alt" /><span className="hidden-xs">&nbsp;&nbsp;Forms</span>
                                </NavLink>
                                <ConditionalRole role={"Administrator"}>
                                    <NavLink className="btn btn-lg btn-primary" activeClassName="active" to="/people" title="People">
                                        <Glyphicon glyph="user" /><span className="hidden-xs">&nbsp;&nbsp;People</span>
                                    </NavLink>
                                </ConditionalRole>
                                <Logout />
                            </div>
                            <Route exact path="/" component={Home}/>
                            <Route exact path="/todo" component={ToDo}/>
                            <Route path="/calendar" component={Calendar}/>
                            <Route path="/job" exact component={Job}/>
                            <Route path="/job-new" exact component={NewJob}/>
                            <Route path="/job/:jobId" component={ViewJob}/>
                            <Route path="/form" exact component={FormListPage}/>
                            <Route path="/form/job/:jobId/task/:taskId/:formName" component={FormPage}/>
                            <Route path="/form/job/:jobId/:formName" component={FormPage}/>
                            <Route path="/people" exact component={PeoplePage}/>
                            <Modals />
                            <SearchCustomersRequester />
                        </div>
                    </Router>
                    <Login />
                    <Reset />
                    <Loader />
                </div>
            </Provider>
        );
    }
}

export default App;
