import React, { Component } from 'react'
import {connect} from "react-redux"
import { TaskList } from "../Task"

class Tasks extends Component {
    render () {
        return (
            <div>
                <TaskList
                    tasks={this.props.tasks}
                    showJobInfo={true}
                    shortInfo={false}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tasks: state.tasks.tasks,
    }
}

export default connect(mapStateToProps)(Tasks)
