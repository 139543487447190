import React, { Component } from 'react'
import { Col, ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap'

import 'react-datepicker/dist/react-datepicker.css';

class CustomerForm extends Component {
    render () {
        const {
            customer,
            handleChange,
        } = this.props

        return (
            <Form horizontal>
                <FormGroup controlId="name">
                    <Col xs={12} sm={3}>
                        <ControlLabel>Name</ControlLabel>
                    </Col>
                    <Col xs={12} sm={9}>
                        <FormControl
                            type="text"
                            onChange={handleChange}
                            value={customer.name}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="email">
                    <Col xs={12} sm={3}>
                        <ControlLabel>Email</ControlLabel>
                    </Col>
                    <Col xs={12} sm={9}>
                        <FormControl
                            type="email"
                            onChange={handleChange}
                            value={customer.email}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="telephoneNumber">
                    <Col xs={12} sm={3}>
                        <ControlLabel>Telephone</ControlLabel>
                    </Col>
                    <Col xs={12} sm={9}>
                        <FormControl
                            type="text"
                            onChange={handleChange}
                            value={customer.telephoneNumber}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="additionalContact">
                    <Col xs={12} sm={3}>
                        <ControlLabel style={{textAlign: "left"}}>Additional contact information</ControlLabel>
                    </Col>
                    <Col xs={12} sm={9}>
                        <FormControl
                            componentClass="textarea"
                            rows="3"
                            onChange={handleChange}
                            value={customer.additionalContact}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="address">
                    <Col xs={12} sm={3}>
                        <ControlLabel>Address</ControlLabel>
                    </Col>
                    <Col xs={12} sm={9}>
                        <FormControl
                            componentClass="textarea"
                            rows="5"
                            onChange={handleChange}
                            value={customer.address}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="formReceived">
                    <Col xs={12} sm={3}>
                        <ControlLabel style={{textAlign: "left"}}>Customer form received</ControlLabel>
                    </Col>
                    <Col xs={12} sm={9}>
                        <FormControl
                            componentClass="select"
                            onChange={handleChange}
                            value={customer.formReceived}
                        >
                            <option value={null} key={null}> - </option>
                            <option value={"true"} key={"true"}>Yes</option>
                            <option value={"false"} key={"false"}>No</option>
                        </FormControl>
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}

export default CustomerForm
