import React, { Component } from 'react'
import { addRequest, populatePeople, updateLogin, updateToken, updateReset } from './state/actions.js'
import { connect } from 'react-redux'
import { Alert, Button, Col, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap'
import ls from 'local-storage'

class Login extends Component {

    constructor(props) {
        super(props)

        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    login() {
        const {
            addRequest,
            login,
            populatePeople,
            updateLogin,
            updateToken,
        } = this.props

        updateLogin('error', null)

        addRequest(
            'login',
            {
                url: 'user/authenticate',
                method: 'POST',
                body: login,
            },
            (body, response) => {
                if (response && (response.status === 200 || (response.response && response.response.status === 200))) {
                    updateToken(body.token, body.userId)
                    ls.set('token', body.token)
                    ls.set('userId', body.userId)

                    addRequest(
                        'initialPeople',
                        {
                            url: 'user',
                            method: 'GET',
                        },
                        body => populatePeople(body)
                    )
                } else {
                    updateLogin('error', 'Failed to login')
                }
            }
        )
    }

    handleChange(e) {
        this.props.updateLogin(e.target.id, e.target.value);
    }

    render () {
        const {
            login,
            token,
            reset,
            updateReset,
        } = this.props

        if ((token && token !== '') || reset) {
            return null
        } else {
            let error = null

            if (login.error) {
                error = (
                    <Alert bsStyle="danger">
                        {login.error}
                    </Alert>
                )
            }

            return (
                <Modal show={true} enforceFocus={true} keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Log in</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form horizontal>
                            <FormGroup>
                                <Col xs={12} sm={3}>
                                    <ControlLabel>Username</ControlLabel>
                                </Col>
                                <Col xs={12} sm={9}>
                                    <FormControl type="text" id="userName" onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col xs={12} sm={3}>
                                    <ControlLabel>Password</ControlLabel>
                                </Col>
                                <Col xs={12} sm={9}>
                                    <FormControl
                                        type="password"
                                        id="password"
                                        onChange={this.handleChange}
                                        onKeyDown={e => {
                                            if (e.key === "Enter") {
                                                this.login()
                                            }
                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            {error}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => updateReset(true)} className={'pull-left'} bsStyle="warning" bsSize="large">Reset password</Button>
                        <Button onClick={this.login} className={'pull-right'} bsStyle="primary" bsSize="large">Log in</Button>
                    </Modal.Footer>
                </Modal>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        token: state.token,
        reset: state.reset,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        populatePeople: (people) => {dispatch(populatePeople(people))},
        updateToken: (token, userId) => {dispatch(updateToken(token, userId))},
        updateLogin: (key, value) => {dispatch(updateLogin(key, value))},
        updateReset: (reset) => {dispatch(updateReset(reset))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
