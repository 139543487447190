import React, { Component } from 'react'
import { Button, ButtonGroup, Col, ControlLabel,
    Form, FormControl, FormGroup, Glyphicon, Panel } from 'react-bootstrap'
import {connect} from "react-redux"
import { searchCustomers } from "../state/actions"
import {JOB_STATES} from "../shared/data"

class JobForm extends Component {
    render() {
        const {
            addNewCustomer,
            clearCustomer,
            copyAddressFromCustomer,
            existingJob,
            handleChange,
            job,
            searchCustomers,
        } = this.props

        const statusOptions = Object.values(JOB_STATES).map(s => <option key={s} value={s}>{s}</option>)

        return (
            <Form horizontal>
                <Panel bsStyle="default">
                    <Panel.Heading>Job</Panel.Heading>
                    <Panel.Body>
                        <FormGroup controlId="customer" className={existingJob ? 'hidden' : '' }>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Customer</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <ButtonGroup className={job && job.customer ? 'hidden' : '' }>
                                    <Button
                                        bsStyle={"primary"}
                                        onClick={addNewCustomer}
                                    >
                                        <Glyphicon glyph="plus" /> Add
                                    </Button>
                                    <Button
                                        bsStyle={"primary"}
                                        onClick={searchCustomers}
                                    >
                                        <Glyphicon glyph="search" /> Find
                                    </Button>
                                </ButtonGroup>
                                <FormControl.Static className={job && job.customer ? '' : 'hidden' }>
                                    {job && job.customer ? job.customer.name : ''}
                                    &nbsp;
                                    <Button
                                        bsStyle={"warning"}
                                        onClick={clearCustomer}
                                        bsSize="xsmall"
                                    >
                                        <Glyphicon glyph="remove" />
                                    </Button>
                                </FormControl.Static>
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="description" validationState={job && job.description ? null : 'error'}>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Description</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="textarea"
                                    placeholder="Job description"
                                    onChange={handleChange}
                                    value={(job && job.description) ? job.description : ''}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="comments">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Comments</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="textarea"
                                    placeholder="Any additional comments"
                                    onChange={handleChange}
                                    value={(job && job.comments) ? job.comments : ''}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="contactName">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Contact</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    type="text"
                                    placeholder="The primary contact"
                                    onChange={handleChange}
                                    value={(job && job.contactName) ? job.contactName : ''}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="status" className={existingJob ? '' : 'hidden' }>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Status</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="select"
                                    onChange={handleChange}
                                    defaultValue={(job && job.status) ? job.status : ''}
                                >
                                    {statusOptions}
                                </FormControl>
                            </Col>
                        </FormGroup>
                    </Panel.Body>
                </Panel>
                <Panel bsStyle="default">
                    <Panel.Heading>Site address</Panel.Heading>
                    <Panel.Body>
                        <Button
                            onClick={copyAddressFromCustomer}
                            className={job && job.customer && copyAddressFromCustomer ? '' : 'hidden'}
                            bsStyle="info"
                        >
                            <Glyphicon glyph={'arrow-down'}/> Copy from customer
                        </Button>
                        <FormGroup controlId="address">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Address</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="textarea"
                                    rows="5"
                                    onChange={handleChange}
                                    value={(job && job.address) ? job.address : ''}
                                />
                            </Col>
                        </FormGroup>
                    </Panel.Body>
                </Panel>
            </Form>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        searchCustomers: () => {dispatch(searchCustomers(''))},
    })
}

export default connect(null, mapDispatchToProps)(JobForm)
