import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Button, Glyphicon} from 'react-bootstrap'
import {updateAddBooking} from "../state/actions"

class SelectedPerson extends Component {
    render () {
        const { person, people, updatePeople } = this.props

        if (people.length === 1) {
            return <span className={'pull-left'}>{person.name}</span>
        }

        const peopleWithoutPerson = people.filter(p => p !== person.id)

        return (
                <Button
                    bsStyle="primary"
                    onClick={() => {
                        updatePeople(peopleWithoutPerson)
                    }}
                    style={{"margin": "2px"}}
                >
                    {person.name}
                    &nbsp;
                    <Glyphicon glyph={'remove'} />
                </Button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        people: state.calendar.addBooking.people,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updatePeople: (value) => {dispatch(updateAddBooking('people', value))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedPerson)
