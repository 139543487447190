import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { DayPersonEntry } from '.'
import { connect } from 'react-redux'
import { newAddBooking, updateHover } from '../state/actions.js'
import { getTimeAsNumber } from "../shared/functions"
import moment from "moment"

class DayPerson extends Component {
    constructor(props) {
        super(props)

        this.cellHover = this.cellHover.bind(this);
        this.book = this.book.bind(this);
    }

    book(hour) {
        const {
            calendarPeople,
            calendarTaskType,
            date,
            newBooking,
            personId,
            rebookTask
        } = this.props

        const taskType = rebookTask ? rebookTask.type : calendarTaskType
        const taskId = rebookTask ? rebookTask.taskId : null
        const jobId = rebookTask ? rebookTask.jobId : null
        const lengthInHours = rebookTask ? rebookTask.lengthInHours : 1

        const people = (taskType === 'Work') ? calendarPeople : [ personId ]

        newBooking(
            people,
            date,
            hour,
            lengthInHours,
            taskType,
            taskId,
            jobId,
        )
    }

    cellHover(hour) {
        const { updateHover, date } = this.props
        updateHover(date, hour)
    }

    render () {
        const {
            weeks,
            dayStartHour,
            dayEndHour,
            date,
            personId,
            hover,
            alwaysDisplay,
        } = this.props

        const fitsInColumn = (column, entry) => {
            return !column.some(existingEntry => {
               return !(entry.endAsNumber <= existingEntry.startAsNumber
                   || entry.startAsNumber >= existingEntry.endAsNumber)
            })
        }

        let entries = []

        const jsonDate = date.toJSON()
        const weekStartDate = moment(date).utc().startOf('isoWeek').toJSON()

        if (weeks[weekStartDate]
            && weeks[weekStartDate][jsonDate]
            && weeks[weekStartDate][jsonDate][personId]) {
            entries = weeks[weekStartDate][jsonDate][personId]
        }

        let columns = [[]]

        entries = entries
            .map(entry => {
                const startAsNumber = getTimeAsNumber(entry.start)
                const endAsNumber = getTimeAsNumber(entry.end)

                return {
                    ...entry,
                    startAsNumber,
                    endAsNumber,
                }
            })

        entries = entries.sort((a,b) => {
            if (a.startAsNumber < b.startAsNumber)
                return -1

            if (a.startAsNumber > b.startAsNumber)
                return 1

            if (a.endAsNumber < b.endAsNumber)
                return 1

            if (a.endAsNumber > b.endAsNumber)
                return -1

            return (a.bookingId < b.bookingId) ? -1 : 1
        })

        entries.forEach(entry => {
            const fitsInAColumn = columns.some(column => {
                if (fitsInColumn(column, entry)) {
                    column.push(entry)
                    return true
                }
                return false
            })

            if (!fitsInAColumn) {
                columns.push([entry])
            }
        })

        const renderEntries = columns
            .flatMap((column, index) => {
                return column.map(entry => {
                    let overlapWidth = 1;
                    const nextColumn = columns[index + 1]

                    if (nextColumn && fitsInColumn(nextColumn, entry)) {
                        overlapWidth = 2
                    }

                    return {
                        overlapPosition: index,
                        overlapNumber: columns.length,
                        overlapWidth,
                        entry
                    }
                })
            })
            .map(columnEntry => {
                return (
                    <DayPersonEntry
                        key={columnEntry.entry.bookingId + personId}
                        entry={columnEntry.entry}
                        dayStartHour={dayStartHour}
                        alwaysDisplay={alwaysDisplay}
                        overlapPosition={columnEntry.overlapPosition}
                        overlapNumber={columnEntry.overlapNumber}
                        overlapWidth={columnEntry.overlapWidth}
                    />
                )
            }
        )

        let matchHour = -1

        if (hover && hover.date === date) {
            matchHour = hover.hour
        }

        let hours = []

        for (let i=dayStartHour; i<dayEndHour; i++) {
            hours.push(
                <tr
                    key={i}
                    onClick={() => this.book(i)}
                    onMouseOver={() => this.cellHover(i) }
                    className={matchHour === i ? 'hour-table-hover' : ''}
                    onDragEnter={e =>{
                        this.cellHover(i)
                        e.preventDefault()
                    }}
                    onDragOver={e => e.preventDefault()}
                    onDrop={() => this.book(i)}
                ><td>&nbsp;</td></tr>)
        }

        return (
            <td className={alwaysDisplay ? '' : 'hidden-xs'}>
                <div className="hour-table-wrapper">
                    <Table
                        striped
                        condensed
                        className="hour-table"
                        hover
                    >
                        <tbody>
                            {hours}
                        </tbody>
                    </Table>
                    {renderEntries}
                </div>
            </td>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        weeks: state.calendar.weeks,
        dayStartHour: state.calendar.dayStartHour,
        dayEndHour: state.calendar.dayEndHour,
        hover: state.calendar.hover,
        rebookTask: state.calendar.rebookTask,
        calendarTaskType: state.calendar.taskType,
        calendarPeople: state.calendar.people,
    }
}

const mapDispatchToProps = (dispatch) => {
    return ({
        newBooking: (
            people,
            date,
            hour,
            lengthInHours,
            taskType,
            taskId,
            jobId,
        ) => {
            dispatch(newAddBooking(
                people,
                date,
                hour,
                lengthInHours,
                taskType,
                taskId,
                jobId,
            ))
        },
        updateHover: (date, hour) => {
            dispatch(updateHover(date, hour))
        },
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(DayPerson)
