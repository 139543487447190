import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

class JobList extends Component {
    render () {
        const { jobs, history, notUpdatedFor } = this.props

        if (!jobs || jobs.length === 0) {
            return null
        }

        const jobsList = jobs.map((job) => (
            <tr key={job.JobId} onClick={() => history.push('/job/' + job.JobId)}>
                <td className="hidden-xs">{'PL' + (5200000 + job.JobNumber)}</td>
                <td title={job.Name}>{job.Name}</td>
                <td title={job.Description} className="hidden-xs">{job.Description}</td>
                <td title={job.Address}>{job.Address}</td>
                <td className="hidden-xs">{job.Status}</td>
                {
                    notUpdatedFor
                        ?   <td className="hidden-xs" title={moment(job.LastModified).format('LL')}>
                                Updated {moment(job.LastModified).fromNow()}
                            </td>
                        : (job.NextTaskId
                            ? <td className="hidden-xs">&nbsp;</td>
                            : <td className="alert-warning hidden-xs">No active tasks</td>)
                }
            </tr>
        ))

        return (
            <Table responsive condensed striped hover bordered className="list-table job-list-table">
                <thead>
                    <tr>
                        <th className="hidden-xs">Number</th>
                        <th>Customer</th>
                        <th className="hidden-xs">Description</th>
                        <th>Address</th>
                        <th className="hidden-xs">Status</th>
                        <th className="hidden-xs">Other</th>
                    </tr>
                </thead>
                <tbody>
                    {jobsList}
                </tbody>
            </Table>
        )
    }
}

const JobListWithRouter = withRouter(JobList)

const mapStateToProps = (state) => {
    return {
        jobs: state.jobs,
        notUpdatedFor: state.job.notUpdatedFor,
    }
}

export default connect(mapStateToProps)(JobListWithRouter)
