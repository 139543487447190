import React, { Component } from 'react'
import { updateFormValue } from '../state/actions.js'
import { connect } from 'react-redux'
import {Button, ButtonGroup, Col, ControlLabel, Form, FormControl, FormGroup, Modal} from 'react-bootstrap'

import 'react-datepicker/dist/react-datepicker.css';
import SignaturePad from "react-signature-canvas"

class SignatureModal extends Component {

    constructor(props) {
        super(props)

        this.add = this.add.bind(this);

        this.state = {}
    }

    sigPad = {}

    add() {
        const {
            signatures,
            updateSignatures,
            close,
        } = this.props

        updateSignatures([
            ...signatures,
            {
                name: this.state.name,
                signature: this.sigPad.getTrimmedCanvas().toDataURL('image/png')
            }
        ])

        close()
    }

    render () {
        const {
            close,
            statement,
        } = this.props

        return (
            <Modal show={true} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormGroup controlId="name">
                            <ControlLabel>Name</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                onChange={e => this.setState({name: e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup controlId="signature">
                            <ControlLabel>{statement}</ControlLabel>
                            <SignaturePad
                                ref={(ref) => { this.sigPad = ref }}
                                canvasProps={{
                                    height: '250px',
                                    style: {
                                        border: '1px solid #ccc',
                                        width: '100%',
                                    }
                                }}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{textAlign: "center"}}>
                    <ButtonGroup bsSize="large">
                        <Button onClick={this.add} bsStyle="primary">Sign</Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        signatures: state.form.signatures ? state.form.signatures : [],
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateSignatures: (signatures) => {dispatch(updateFormValue([], 'signatures', signatures))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(SignatureModal)
