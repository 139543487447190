import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'
import moment from 'moment'
import { addRequest, populateWeek, updateWeek } from '../state/actions.js'
import DatePicker from 'react-datepicker'
import { populateAllWeeks } from "../shared/functions"
import 'react-datepicker/dist/react-datepicker.css'

class PickWeek extends Component {

    constructor(props) {
        super(props)
        this.previousWeek = this.previousWeek.bind(this);
        this.pickWeek = this.pickWeek.bind(this);
        this.nextWeek = this.nextWeek.bind(this);
    }

    updateWeek (weekStartDate) {
        const { addRequest, populateWeekData, updateWeekStartDate, displayWeeks } = this.props

        updateWeekStartDate(weekStartDate)
        populateAllWeeks(addRequest, populateWeekData, weekStartDate, displayWeeks)
    }

    componentDidMount () {
        this.updateWeek(this.props.weekStartDate)
    }

    previousWeek() {
        const { weekStartDate, displayWeeks } = this.props
        this.updateWeek(moment(weekStartDate).utc().subtract(7*displayWeeks, 'days').toJSON())
    }

    pickWeek(date) {
        this.updateWeek(moment(date).utc().startOf('isoWeek').toJSON())
    }

    nextWeek() {
        const { weekStartDate, displayWeeks } = this.props
        this.updateWeek(moment(weekStartDate).utc().add(7*displayWeeks, 'days').toJSON())
    }

    render () {
        return (
            <ButtonGroup>
                <Button
                    onClick={this.previousWeek}
                    bsStyle="primary"
                >
                    <i className="fa fa-chevron-left" />
                </Button>
                <DatePicker
                    selected={new Date(this.props.weekStartDate)}
                    onChange={this.pickWeek}
                    className={'btn btn-primary'}
                    dateFormat="dd/MM/yyyy"
                    todayButton={'Today'}
                    showPopperArrow={false}
                    filterDate={(date) => date.getDay() === 1}
                />
                <Button
                    onClick={this.nextWeek}
                    bsStyle="primary"
                >
                    <i className="fa fa-chevron-right" />
                </Button>
            </ButtonGroup>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        weekStartDate: state.calendar.weekStartDate,
        displayWeeks: state.calendar.displayWeeks,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateWeekStartDate: weekStartDate => {dispatch(updateWeek(weekStartDate))},
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        populateWeekData: (weekStart, bookings) => {dispatch(populateWeek(weekStart, bookings))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(PickWeek)
