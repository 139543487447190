import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { DropdownButton, Glyphicon, MenuItem } from 'react-bootstrap'
import { TASK_TYPES } from '../shared/data.js'
import { connect } from 'react-redux'
import { updateCalendarJob, updateCalendarTaskType } from '../state/actions.js'

class AddBooking extends Component {
    render() {
        const {
            job,
            updateCalendarJob,
            updateCalendarTaskType,
            history,
            page,
            calendarTaskType,
            calendarJob,
            rebookTask
        } = this.props

        if (rebookTask) {
            return null
        }

        if (page === 'calendar' && calendarTaskType) {
            return null
        }

        const bookingTypes = TASK_TYPES
            .filter((type) =>
                (
                    (page === 'job' && type.job) // On job page and a job task
                    || (page === 'calendar' && !!calendarJob === type.job) // On calendar, job task if job selected
                )
                && type.booking)
            .map((type) => type.name)
            .map((type) => (
                <MenuItem
                    key={type}
                    eventKey={type}
                    onClick={() => {
                        if (page === 'job') {
                            updateCalendarJob(job)
                        }

                        updateCalendarTaskType(type)

                        if (page !== 'calendar') {
                            history.push('/calendar')
                        }
                    }}
                >
                    {type}
                </MenuItem>
            ))

        return (
            <DropdownButton
                id={'job-add-booking'}
                type="button"
                bsStyle="info"
                title={<span><Glyphicon glyph={page !== 'calendar' ? "calendar" : "plus"} /> Booking</span>}
            >
                {bookingTypes}
            </DropdownButton>
        )
    }
}

const AddBookingWithRouter = withRouter(AddBooking)

const mapStateToProps = (state) => {
    return {
        job: state.job.job,
        calendarTaskType: state.calendar.taskType,
        calendarJob: state.calendar.job,
        rebookTask: state.calendar.rebookTask,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateCalendarJob: (job) => {dispatch(updateCalendarJob(job))},
        updateCalendarTaskType: (taskType) => {dispatch(updateCalendarTaskType(taskType))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBookingWithRouter)
