import React, { Component } from 'react'
import {Button, ButtonGroup, Glyphicon, Modal} from 'react-bootstrap'
import { connect } from 'react-redux'
import {addNewCustomer, addRequest, clearEditJob, searchCustomers, updateEditJob, populateJob } from "../state/actions"
import { JobForm } from './index'
import {cleanUpAddress} from "../shared/functions"
import {withRouter} from "react-router-dom"

class EditJobModal extends Component {
    constructor(props) {
        super(props)

        this.close = this.close.bind(this);
        this.update = this.update.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    close() {
        this.props.clearEditJob()
    }

    update() {

        const {
            addRequest,
            clearEditJob,
            job,
            populateJob,
            history,
        } = this.props

        const body = {
            ...job,
            customerId: job.customer.customerId,
            address: cleanUpAddress(job.address),
        }

        body.customer = null

        addRequest(
            'updateJob',
            {
                url: 'job/' + job.jobId,
                method: 'PATCH',
                body,
            },
            () => {
                clearEditJob()

                if (body.status === 'Deleted') {
                    history.push('/job')
                } else {
                    addRequest(
                        'job-' + job.jobId,
                        {
                            url: 'job/' + job.jobId,
                            method: 'GET',
                        },
                        body => populateJob(body)
                    )
                }
            }
        )
    }

    handleChange(e) {
        this.props.updateEditJob(e.target.id, e.target.value)
    }

    render() {
        const {
            addNewCustomer,
            job,
            searchCustomers,
        } = this.props

        if (!job)
            return null

        return (
            <Modal show={true} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit job</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <JobForm
                        job={job}
                        addNewCustomer={addNewCustomer}
                        searchCustomers={searchCustomers}
                        handleChange={this.handleChange}
                        existingJob={true}
                        />
                </Modal.Body>
                <Modal.Footer style={{textAlign: "center"}}>
                    <ButtonGroup bsSize="large">
                        <Button onClick={this.update} className={'pull-right'} bsStyle="primary"><Glyphicon glyph={"floppy-disk"} /> Update job</Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
    )
    }

    static addRow(rows, label, value, key) {
        if (value) {
            rows.push(
                <tr key={key ? key : label}>
                    <th>{label}</th>
                    <td>{value}</td>
                </tr>
            )
        }
    }
}

const EditJobModalWithRouter = withRouter(EditJobModal)

const mapStateToProps = (state) => {
    return {
        job: state.job.edit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        addNewCustomer: () => {dispatch(addNewCustomer())},
        updateEditJob: (key, value) => {dispatch(updateEditJob(key, value))},
        searchCustomers: () => {dispatch(searchCustomers(''))},
        clearEditJob: () => {dispatch(clearEditJob())},
        populateJob: (job) => {dispatch(populateJob(job))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(EditJobModalWithRouter)
