import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CALENDAR_LABELS } from '../shared/data.js'
import { updateCalendarValue } from '../state/actions.js'
import {Selector} from "../components"

class PickLabel extends Component {
    render () {
        const { label, updateCalendarValue } = this.props

        const labelOptions = Object.values(CALENDAR_LABELS)
            .map(l => ({
                id: l,
                value: l,
            }))

        return (
            <Selector
                id={'PickLabel'}
                items={labelOptions}
                selectedStyle={'primary'}
                selectedId={label}
                title={'View'}
                valueUpdated={updateCalendarValue}
                valueUpdatedId={'label'}
                multiSelect={true}
                hideIfNullValue={true}
                closeButton={false}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        label: state.calendar.label,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
    updateCalendarValue: (key, value) => {dispatch(updateCalendarValue(key, value))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(PickLabel)
