import { Component } from 'react'
import { connect } from 'react-redux'
import { listCustomers, addRequest } from '../../state/actions.js'

class SearchCustomersRequester extends Component {
    UNSAFE_componentWillMount() {
        this.sendRequest(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.sendRequest(nextProps)
    }

    render () {
        return null
    }

    sendRequest(props) {
        const {
            addARequest,
            searchString,
            listCustomers
        } = props

        if (searchString && searchString !== '') {
            addARequest(
                'customer-search-' + searchString,
                {
                    url: 'customer?searchString=' + searchString,
                    method: 'GET',
                },
                body => listCustomers(body)
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        searchString: state.customer.searchString,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addARequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        listCustomers: (customers) => {dispatch(listCustomers(customers))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchCustomersRequester)
