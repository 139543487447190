import React, { Component } from 'react'
import { updateLogin, updateToken } from './state/actions.js'
import { connect } from 'react-redux'
import {Button, Glyphicon} from 'react-bootstrap'
import ls from 'local-storage'

class Logout extends Component {

    constructor(props) {
        super(props)

        this.logout = this.logout.bind(this);
    }

    logout() {
        const {
            updateLogin,
            updateToken,
        } = this.props

        updateLogin('error', null)
        updateToken(null, null)

        ls.set('token', null)
        ls.set('userId', null)
    }

    render() {
        const {
            token,
        } = this.props

        if (!token) {
            return null
        } else {
            return <Button onClick={this.logout} bsSize="large" bsStyle="danger" title="Log out">
                <Glyphicon glyph="log-out" /><span className="hidden-xs">&nbsp;&nbsp;Log out</span>
                </Button>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateToken: (token, userId) => {dispatch(updateToken(token, userId))},
        updateLogin: (key, value) => {dispatch(updateLogin(key, value))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
