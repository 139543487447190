import React, { Component } from 'react'
import { removePerson } from '../state/actions.js'
import { getPersonById } from '../state/reducer.js'
import { connect } from 'react-redux'
import {Button, ButtonToolbar, Glyphicon} from "react-bootstrap"

class Header extends Component {

    constructor(props) {
        super(props)
        this.onlyThisPerson = this.onlyThisPerson.bind(this);
    }

    onlyThisPerson() {
        const { removePerson, personId, calendarPeople } = this.props

        calendarPeople.forEach(calendarPersonId => {
            if (calendarPersonId !== personId) {
                removePerson(calendarPersonId)
            }
        })
    }

    render () {
        const {
            removePerson,
            personId,
            people,
            alwaysDisplay
        } = this.props

        return (
            <th
                className={alwaysDisplay ? 'calendar-header' : 'calendar-header hidden-xs'}
                style={{position: 'relative'}}
            >
                <ButtonToolbar
                    style={{position: 'absolute', right: '3px'}}
                >
                    <Button
                        onClick={this.onlyThisPerson}
                        bsStyle={'info'}
                        bsSize={'small'}
                    >
                        <Glyphicon glyph={'eye-open'} />
                    </Button>
                    <Button
                        onClick={() => removePerson(personId)}
                        bsStyle={'danger'}
                        bsSize={'small'}
                    >
                        <Glyphicon glyph={'remove'} />
                    </Button>
                </ButtonToolbar>
                <h5>
                    {getPersonById(people, personId).name}
                </h5>
            </th>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return({
        removePerson: (personId) => {dispatch(removePerson(personId))}
    })
}

const mapStateToProps = (state) => {
    return {
        people: state.people,
        calendarPeople: state.calendar.people,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
