import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { populatePeople, addRequest, beginRequest, clearRequest, failRequest } from './state/actions.js'
import ls from "local-storage"
import {updateLogin, updateToken} from "./state/actions"
import {Alert, Modal} from "react-bootstrap"

class RequestLoader extends Component {
    constructor(props) {
        super(props)

        // For when the token was populated from local storage
        if (props.token) {
            props.addARequest(
                'initialPeople',
                {
                    url: 'user',
                    method: 'GET',
                },
                body => props.populateReturnedPeople(body)
            )
        }
    }

    UNSAFE_componentWillMount() {
        this.sendRequests(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.sendRequests(nextProps)
    }

    render () {
        const { token, requests } = this.props

        const newRequests = requests
            .filter((request) => token || request.requestKey === 'login')
            .length

        if (newRequests === 0) {
            return null
        }

        const failedRequests = requests
            .filter((request) => request.status === 'error')
            .length

        if (failedRequests > 0) {
            return (
                <Modal show={true} enforceFocus={true} keyboard={false}>
                    <Modal.Body style={{padding: 0}}>
                        <Alert bsStyle="danger" style={{marginBottom: 0, textAlign: "center"}}>
                            <strong>
                                <i className="fa fa-warning" style={ { 'fontSize': '5em' } } />
                                <p>There has been a failure on the page.</p>
                                <p>Please refresh and try again.</p>
                            </strong>
                        </Alert>
                    </Modal.Body>
                </Modal>
            )
        }

        return (
            <div className="loader-overlay">
                <div>
                    <i className="fa fa-cog fa-spin" style={ { 'fontSize': '10em' } } />
                </div>
            </div>
        )
    }

    sendRequests(props) {
        const {
            apiUrl,
            token,
            requests,
            beginRequest,
            clearRequest,
            failRequest,
            updateLogin,
            updateToken,
        } = props

        const newRequests = requests
            .filter((request) => request.status === 'new')
            .filter((request) => token || request.requestKey === 'login' || request.requestKey === 'reset')

        if (newRequests.length > 0) {
            const newRequest = newRequests[0]

            const options = newRequest.options

            const config = {
                baseURL: apiUrl,
                url: options.url,
                headers: {
                    ...(options.headers || {}),
                    Token: token,
                },
                data: options.body,
                method: options.method,
            }

            beginRequest(newRequest.requestKey)

            axios.request(config)
                .catch(response => {
                    this.handleResponse(response, updateLogin, updateToken, failRequest, newRequest, clearRequest)
                })
                .then(response => {
                    this.handleResponse(response, updateLogin, updateToken, failRequest, newRequest, clearRequest)
                })
        }
    }

    handleResponse(response, updateLogin, updateToken, failRequest, newRequest, clearRequest) {
        if (response && (response.status === 401 || (response.response && (response.response.status === 401)))) {
            updateLogin('error', null)
            updateToken('', '')

            ls.set('token', null)
            ls.set('userId', null)
            clearRequest(newRequest.requestKey)
        } else if (response && response.status >= 500 && newRequest.options.method === "GET") {
            failRequest(newRequest.requestKey)
        } else {
            newRequest.callback(response ? response.data : null, response)
            clearRequest(newRequest.requestKey)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        requests: state.requests,
        weekStartDate: state.calendar.weekStartDate,
        token: state.token,
        apiUrl: state.config.apiUrl,
        people: state.people,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        populateReturnedPeople: (people) => {dispatch(populatePeople(people))},
        addARequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        beginRequest: (requestKey) => {dispatch(beginRequest(requestKey))},
        clearRequest: (requestKey) => {dispatch(clearRequest(requestKey))},
        failRequest: (requestKey) => {dispatch(failRequest(requestKey))},
        updateToken: (token, userId) => {dispatch(updateToken(token, userId))},
        updateLogin: (key, value) => {dispatch(updateLogin(key, value))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestLoader)
