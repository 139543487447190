export const TASK_TYPES = [
    {
        name: 'Annual leave',
        job: false,
        booking: true,
        allPeople: true,
        restricted: false,
    },
    {
        name: 'Sickness',
        job: false,
        booking: true,
        allPeople: true,
        restricted: false,
    },
    {
        name: 'Training',
        job: false,
        booking: true,
        allPeople: true,
        restricted: false,
    },
    {
        name: 'Work',
        job: true,
        booking: true,
        complete: [
            { name: 'Materials', type: 'Other', typeId: 'other', input: 'textbox' },
            { name: 'Time worked', type: 'Time worked', typeId: 'timeWorked', input: 'textbox' },
        ],
        next: ['Invoice', 'Book work', 'Book survey', 'Book connection', 'Review for invoice'],
        restricted: false,
        formOnCreate: ['Dynamic Risk Assessment', 'Construction Phase Plan', 'Quality Check List'],
    },
    {
        name: 'Unpaid leave',
        job: false,
        booking: true,
        allPeople: true,
        restricted: false,
    },
    {
        name: 'Survey',
        job: true,
        booking: true,
        complete: [
            { name: 'Time and materials', type: 'Time and materials', typeId: 'timeAndMaterials', input: 'checkbox' },
            { name: 'Quote price', type: 'Price', typeId: 'price', input: 'money' },
            { name: 'Price per metre', type: 'Price per metre', typeId: 'pricePerMetre', input: 'money' },
        ],
        next: ['Send quote', 'Book work', 'Book survey', 'Reminder about Survey'],
        restricted: false,
    },
    {
        name: 'Appointment',
        job: true,
        booking: true,
        restricted: false,
    },
    {
        name: 'Appointment',
        job: false,
        booking: true,
        restricted: false,
    },
    {
        name: 'Call customer',
        job: true,
        booking: false,
        defaultDueDays: 1,
        allPeople: true,
        restricted: false,
    },
    {
        name: 'Follow up after Quote',
        job: true,
        booking: false,
        defaultDueDays: 1,
        allPeople: true,
        restricted: false,
    },
    {
        name: 'Reminder about Survey',
        job: true,
        booking: false,
        defaultDueDays: 1,
        allPeople: true,
        restricted: false,
    },
    {
        name: 'Check with Engineer',
        job: true,
        booking: false,
        defaultDueDays: 1,
        allPeople: true,
        restricted: false,
    },
    {
        name: 'Confirm Booking',
        job: true,
        booking: false,
        defaultDueDays: 1,
        allPeople: true,
        restricted: false,
    },
    {
        name: 'Book work',
        job: true,
        booking: false,
        next: ['Work'],
        nextIsMandatory: true,
        defaultDueDays: 1,
        restricted: false,
    },
    {
        name: 'Book survey',
        job: true,
        booking: false,
        next: ['Survey'],
        nextIsMandatory: true,
        defaultDueDays: 1,
        restricted: false,
    },
    {
        name: 'Invoice',
        job: true,
        booking: false,
        complete: [
            { name: 'Invoiced price', type: 'Price', typeId: 'price', input: 'money' },
        ],
        next: ['Book connection', 'Invoice'],
        defaultDueDays: 1,
        restricted: true,
    },
    {
        name: 'Book connection',
        job: true,
        booking: false,
        next: ['Book work', 'Invoice'],
        defaultDueDays: 1,
        restricted: false,
    },
    {
        name: 'Send quote',
        job: true,
        booking: false,
        next: ['Call customer', 'Book work', 'Book survey', 'Follow up after Quote'],
        defaultDueDays: 1,
        restricted: true,
    },
    {
        name: 'Review for invoice',
        job: true,
        booking: false,
        next: ['Invoice'],
        defaultDueDays: 1,
        restricted: true,
    },
    {
        name: 'Confirm Payment',
        job: false,
        booking: false,
        defaultDueDays: 1,
        restricted: true,
    },
]

export const FORMS = [
    {
        name: 'Dynamic Risk Assessment',
        job: false,
        task: 'Work',
        restricted: false,
        fields: {
            'Site name': 'string',
            'Date': 'date',
            'Site hazards': {
                'Access / egress onto and within site': 'riskBox',
                'Interaction with householder / public': 'riskBox',
                'Pets': 'riskBox',
                'Restricted / confined spaces': 'riskBox',
                'Vehicular / pedestrian traffic': 'riskBox',
                'Overhead power lines': 'riskBox',
                'Underground services': 'riskBox',
                'Ground conditions': 'riskBox',
                'Vermin / pests': 'riskBox',
                'Weather conditions': 'riskBox',
                'Working at height': 'riskBox',
                'Contaminated materials / sharps / needle stick injuries': 'riskBox',
                'Slips / trips / falls': 'riskBox',
            },
            'Job hazards': {
                'Noise': 'riskBox',
                'Vibration': 'riskBox',
                'Dust': 'riskBox',
                'Heat': 'riskBox',
                'Chemicals': 'riskBox',
                'Plant- compressor': 'riskBox',
                'Electrical tools': 'riskBox',
                'Manual handling': 'riskBox',
                'Asbestos': 'riskBox',
            },
            'Environmental hazards': {
                'Pollution/sewage': 'riskBox',
                'Contaminated soils / faeces': 'riskBox',
                'Noise': 'riskBox',
                'Dust': 'riskBox',
            },
        },
        signature: {
            number: 'multiple',
            statement: 'I acknowledge and fully understand the contents of this risk assessment. I shall ' +
                'report any risks not identified above to my supervisor and await a revised document prior ' +
                'to commencing work.'
        },
    },
    {
        name: 'Construction Phase Plan',
        job: true,
        task: 'Work',
        restricted: false,
        fields: {
            'Plan': {
                'Your name / company': 'string',
                'Name and address of client': 'text',
                'Contact details of architect or principal designer': 'string',
                'What is the job?': 'string',
                'Is there anything the client has made you aware of?': 'text',
                'Start date': 'date',
                'Finish date': 'date',
                'Other dates': 'text',
                'Where are your toilet, washing and rest facilities?': 'string',
            },
            'Working Together': {
                'Who else is on site - and their contact details?': 'text',
                'Who will be the principal contractor?': 'string',
                'How will you keep everyone on site updated during the job?': 'string',
            },
            'Organise: What are the main dangers on site?': {
                'Falls from height': {
                    expandable: true,
                    helpText: 'Make sure ladders are in good condition, at the correct angle and secured. Prevent people and materials falling from roofs, gable ends, working platforms and other open edges using guardrails, midrails, and toeboards.',
                    'What controls do you have?': 'text'
                },
                'Collapse of excavations': {
                    expandable: true,
                    helpText: 'Shore excavations: Either cover or barrier excavations to stop people and plant falling in.',
                    'What controls do you have?': 'text'
                },
                'Collapse of structures': {
                    expandable: true,
                    helpText: 'Support structures (such as walls, beams, chimney breasts and roofs) with props: Ensure props are installed by a competent person.',
                    'What controls do you have?': 'text'
                },
                'Exposure to dusts': {
                    expandable: true,
                    helpText: 'Prevent dust by using wet cutting and vacuum extraction on tools; use a vacuum cleaner rather than sweeping; use a suitable, well-fitting mask.',
                    'What controls do you have?': 'text'
                },
                'Exposure to asbestos': {
                    expandable: true,
                    helpText: 'If you suspect that asbestos might be present, don’t start work until a demolition / refurbishment survey has been carried out.  Make sure everyone on the site is aware of the results.',
                    'What controls do you have?': 'text'
                },
                'Activities or workers requiring supervision': {
                    expandable: true,
                    'Who will be supervising?': 'text'
                },
                'Electricity & Services': {
                    expandable: true,
                    helpText: 'Turn electricity supply and other services off before drilling into walls. Do not use excavators or power tools near suspected buried services.',
                    'What controls do you have?': 'text'
                },
                'Risks to members of the public, the client and others': {
                    expandable: true,
                    helpText: 'Keep the site secure to prevent unauthorised access. Net scaffolds. Use rubbish chutes.',
                    'What controls do you have?': 'text'
                },
                'Other dangers on site': {
                    expandable: true,
                    helpText: 'Plant machinery, vibration, traffic, livestock, pets, client activities, etc.',
                    'Description': 'text',
                    'What controls do you have?': 'text'
                }
            }
        },
        signature: {
            number: 'single',
            statement: 'Signed',
        },
    },
    {
        name: 'Quality Check List',
        job: true,
        task: 'Work',
        restricted: false,
        fields: {
            'Actions': {
                'Has the daily risk assessment form been completed and any actions completed?': 'boolean',
                'Has the construction phase plan been completed & any actions completed?': 'boolean',
                'Was all plant, machinery & equipment checked prior to the work being carried out that it was in good condition and had necessary test certificates?': 'boolean',
                'Has everything listed in the quotation been carried out?': 'boolean',
                'If no, state why (e.g. need to return after water board has been out)': 'text',
                'Have any unforeseen circumstances occurred?': 'text',
                'Have the correct pipe and materials been used (e.g WRAS approved)?': 'boolean',
                'Have all joints been checked?': 'boolean',
                'Have we checked that the water is back on (where applicable)?': 'boolean',
                'Where applicable, does the reinstatement match existing surface?': 'boolean',
                'Where applicable, has every reinstatement been carried out to a high standard?': 'boolean',
                'Where applicable, have we checked we have followed out all requirements on any sketches associated with quotes from the water authority?': 'boolean',
                'Have we removed all equipment including signs & barriers, any statutory notices if displayed?': 'boolean',
                'If no, explain why (e.g. need to return at later date to collect barriers)': 'text',
                'Has the site been left clean & tidy and (if applicable) any waste removed?': 'boolean',
                'Have we taken any photos, if required?': 'boolean',
                'Has the job sheet been updated with any notes or a handover if we need to return to site?': 'boolean',
                'Any feedback from customer if on site? If yes, please state:': 'text',
            },
        },
        signature: {
            number: 'single',
            statement: 'Signed',
        },
    },
]

export const FORM_FIELD_TYPES = {
    'riskBox': { expandable: true, 'Has the risk been controlled?': 'boolean', 'Additional measures required to control the risk': 'text'},
}

export const CALENDAR_LABELS = {
    CUSTOMER: 'Customer',
    DESCRIPTION: 'Description',
    ADDRESS: 'Full address',
    CONTACT: 'Contact',
    COMPLETE: 'Complete',
}

export const TASK_STATES = {
    OPEN: 'Open',
    COMPLETE: 'Complete',
    CANCELLED: 'Cancelled',
}

export const JOB_STATES = {
    NOT_CONFIRMED: 'Not confirmed',
    CONFIRMED: 'Confirmed',
    COMPLETE: 'Complete',
    CANCELLED: 'Cancelled',
    ACTIVE: 'Active',
    INVOICED: 'Invoiced',
    ARCHIVED: 'Archived',
    DELETED: 'Deleted',
}

export const MISC_ROLES = [
    { value: "DefaultOnCalendar", description: "Displayed on Calendar by default" },
    { value: "Administrator", description: "User administrator" },
    { value: "Senior", description: "Can view prices (Senior)" },
]

export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif']
