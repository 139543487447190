import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, ButtonGroup, Glyphicon } from 'react-bootstrap'
import {rebook} from "../state/actions"

class RebookTask extends Component {
    render () {
        const { rebookTask, rebook } = this.props

        if (!rebookTask) {
            return null
        }

        return (
            <ButtonGroup>
                <Button
                    bsStyle="warning"
                    onClick={() => {
                        rebook()
                    }}
                >
                    Rebooking: {rebookTask.type} &nbsp;
                    <Glyphicon glyph={'remove'} />
                </Button>
            </ButtonGroup>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rebookTask: state.calendar.rebookTask,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        rebook: () => {dispatch(rebook())},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(RebookTask)
