import React, { Component } from 'react'
import { Button, Col, Glyphicon, Grid, Table, Row, Panel, ButtonToolbar, ButtonGroup, DropdownButton, MenuItem } from 'react-bootstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import { TaskList } from '../../Task/index'
import { AddBooking } from "../../components/index"
import { AddNote, AddTask } from '.'
import {imageExtensions, TASK_STATES} from "../../shared/data"
import { addEditCustomer, addEditJob, addRequest, addViewTask, populateJobAttachments, searchCustomers } from "../../state/actions"
import { getPersonById } from "../../state/reducer"
import FormList from "../../Form/FormListSection"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import AttachmentButton from "../../components/AttachmentButton"
import TakePhoto from "./TakePhoto"

class Job extends Component {
    state = {
        showCancelled: false,
        showExpanded: false,
        attachmentOrder: 'name'
    }

    constructor(props) {
        super(props)

        this.deleteAttachment = this.deleteAttachment.bind(this)
        this.imageGallery = React.createRef();
    }

    deleteAttachment(name, key) {
        const {
            jobSection,
            addRequest,
            populateJobAttachments,
        } = this.props

        const {
            job,
        } = jobSection

        if (confirm("Delete attachment " + name + "?")) {
            addRequest(
                'deleteAttachment' + name,
                {
                    url: 'attachment/' + key,
                    method: 'DELETE',
                },
                () => {
                    addRequest(
                        'job-attachments-' + job.jobId,
                        {
                            url: 'attachment?prefix=' + job.jobId,
                            method: 'GET',
                        },
                        body => populateJobAttachments(body)
                    )
                }
            )
        }
    }

    render() {

        const {
            jobSection,
            people,
            addEditJob,
            searchCustomers,
            addEditCustomer,
            addViewTask,
        } = this.props

        const {
            job,
            tasks,
            attachments,
        } = jobSection

        if (!job) {
            return null
        }

        const jobRows = []

        const jobNumber = 'PL' + (5200000 + job.jobNumber)

        Job.addRow(jobRows, 'Job Number', jobNumber)
        Job.addRow(jobRows, 'Description', job.description)
        Job.addRow(jobRows, 'Contact', job.contactName)
        Job.addRow(jobRows, 'Site address', job.address)
        Job.addRow(jobRows, 'Comments', job.comments)
        Job.addRow(jobRows, 'Status', job.status)

        const modified = moment(job.modified).format('L LTS')
        const modifiedByPerson = people.find((p) => (p.id === job.modifiedBy))

        if (modifiedByPerson) {
            Job.addRow(jobRows, 'Last updated', `${modified} (${modifiedByPerson.name})`)
        } else {
            Job.addRow(jobRows, 'Last updated', modified)
        }

        const attachmentsWithTask = attachments ?
            attachments.map(a => ({
                ...a,
                task: tasks ? tasks.find(t => (a.key.indexOf(t.taskId) > -1)) : null
            }))
            : []

        const attachmentRows = attachmentsWithTask
                .filter(a => !imageExtensions.find(i => a.name.toLowerCase().endsWith(i)))
                .sort((a, b) => {
                    let aValue, bValue

                    switch (this.state.attachmentOrder) {
                        case "name":
                            aValue = a.name
                            bValue = b.name
                            break;
                        case "taskType":
                            aValue = a.task.type
                            bValue = b.task.type
                            break;
                        case "updated":
                            aValue = a.task.modified
                            bValue = b.task.modified
                            break;
                    }

                    return aValue === bValue ? 0 : (aValue < bValue ? -1 : 1)
                })
                .map(a => {
                    return <tr key={a.key}>
                        <td>
                        <a
                            href={a.url}
                            target="_blank"
                        >
                            {decodeURI(a.name)}
                        </a>
                        </td>
                        <td style={{cursor: 'pointer'}} onClick={() => addViewTask(a.task)}>
                            {a.task ? a.task.type : " "}
                        </td>
                        <td>
                            {a.task ? moment(a.task.modified).format("L") : " "}
                        </td>
                        <td>
                        <Button
                            bsStyle={'danger'}
                            bsSize="xsmall"
                            onClick={() => this.deleteAttachment(a.name, a.key)}
                            title="Delete"
                        >
                            Delete
                        </Button>
                        </td>
                    </tr>
                })

        const customerRows = []

        Job.addRow(customerRows, 'Name', job.customer.name)
        Job.addRow(customerRows, 'Telephone number', job.customer.telephoneNumber)
        Job.addRow(customerRows, 'Email', job.customer.email)
        Job.addRow(customerRows, 'Additional contact info', job.customer.additionalContact)
        Job.addRow(customerRows, 'Address', job.customer.address)

        if (job.customer.formReceived != null) {
            Job.addRow(customerRows, 'Customer form received', job.customer.formReceived ? 'Yes' : 'No')
        }

        let orderedTasks = []
        let notes = []

        let filteredTasks = []

        if (tasks) {
            filteredTasks = tasks.filter(t => t.type !== 'Note')
            orderedTasks = orderedTasks.concat(filteredTasks.filter(t => t.status === TASK_STATES.OPEN))
            orderedTasks = orderedTasks.concat(filteredTasks.filter(t => t.status === TASK_STATES.COMPLETE))

            if (this.state.showCancelled) {
                orderedTasks = orderedTasks.concat(filteredTasks.filter(t => t.status === TASK_STATES.CANCELLED))
            }

            const datedNotes = []

            tasks
                .filter(t => t.status !== TASK_STATES.CANCELLED)
                .filter(t => t.notes[0])
                .forEach(t => {
                    t.notes.forEach(n => {
                        let name
                        let date
                        let infoLabel

                        if (t.type === 'Note') {
                            const createdBy = getPersonById(people, t.createdBy)
                            const modifiedBy = getPersonById(people, t.modifiedBy)
                            infoLabel = t.created === t.modified ? null :
                                <i>Edited {moment(t.modified).format("L LTS").concat(" by ").concat(modifiedBy ? modifiedBy.name : <i>Unknown</i>)}</i>

                            date = t.created
                            name = createdBy ? createdBy.name : <i>Unknown</i>
                        } else {
                            const person = getPersonById(people, n.status === 'Open' ? t.createdBy : t.modifiedBy)
                            date = n.status === 'Open' ? t.created : t.modified

                            name = <i>Unknown</i>
                            if (person) {
                                name = person.name
                            }

                            infoLabel = t.type + " (" + n.status.replace(/^Open$/, "New") + ")"
                        }

                        const noteAttachments =  attachmentsWithTask
                            .filter(a => a.task && a.task.taskId === t.taskId)
                            .map(a => <AttachmentButton
                                key={a.key}
                                attachmentKey={a.key}
                                name={a.name}
                                url={a.url}
                            />)

                        datedNotes.push({
                            date: date,
                            note: <tr
                                    key={t.taskId + n.status}
                                    style={{cursor: 'pointer', whiteSpace: 'pre-line'}}
                                    onClick={() => addViewTask(t)}>
                                    <td>
                                        <p><b>{moment(date).format("L LTS")}</b> {name}<br/><i>{infoLabel}</i></p>
                                        <p>{n.note}</p>
                                        {noteAttachments.length > 0 ? noteAttachments : null}
                                    </td>
                                </tr>
                        })
                    })
                })

            notes = datedNotes
                    .sort((left, right) => moment.utc(left.date).diff(moment.utc(right.date)))
                    .map(t => t.note)

        }

        const images = attachmentsWithTask
            .filter(a => imageExtensions.find(i => a.name.toLowerCase().endsWith(i)))
            .map(a => {

                let note = this.getNote(a.task, 300)

                return {
                original: a.url,
                thumbnail: a.url,
                description: note,
                originalTitle: decodeURI(a.name),
                originalAlt: decodeURI(a.name),
                key: a.key,
                name: a.name,
            }})

        return (
            <Grid>
                <Row>
                    <Col xs={12} sm={6}>
                        <Panel bsStyle="default">
                            <Panel.Heading>
                                <Button
                                    bsSize="xsmall"
                                    bsStyle="info"
                                    onClick={addEditJob}
                                    className="pull-right"
                                >Edit</Button>
                                Job
                            </Panel.Heading>
                            <Panel.Body>
                                <Table bordered condensed>
                                    <tbody>
                                    {jobRows}
                                    </tbody>
                                </Table>
                            </Panel.Body>
                        </Panel>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Panel bsStyle="default">
                            <Panel.Heading>Add</Panel.Heading>
                            <Panel.Body>
                                <ButtonGroup>
                                    <AddBooking page={'job'} />
                                    <AddTask />
                                    <AddNote />
                                    <TakePhoto />
                                </ButtonGroup>
                            </Panel.Body>
                        </Panel>
                        <Panel bsStyle="default">
                            <Panel.Heading>
                                <div className="pull-right">
                                    <DropdownButton
                                        id="customerAction"
                                        type="button"
                                        bsSize="xsmall"
                                        bsStyle="info"
                                        title="Change"
                                    >
                                        <MenuItem
                                            onClick={addEditCustomer}
                                        >
                                            Edit
                                        </MenuItem>
                                        <MenuItem
                                            onClick={searchCustomers}
                                        >
                                            Find existing
                                        </MenuItem>
                                    </DropdownButton>
                                </div>
                                Customer
                            </Panel.Heading>
                            <Panel.Body>
                                <Table bordered condensed>
                                    <tbody>
                                    {customerRows}
                                    </tbody>
                                </Table>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
                {attachmentRows.length > 0 ? <Row>
                    <Col xs={12} sm={12}>
                        <Panel bsStyle="default" defaultExpanded={false}>
                            <Panel.Heading>
                                <Panel.Title toggle>
                                    Attachments (click to view)
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    <Table bordered condensed striped>
                                        <thead>
                                            <tr>
                                                <th style={{cursor: 'pointer'}} onClick={() => this.setState({'attachmentOrder': 'name'})}>
                                                    Name
                                                    {this.state.attachmentOrder === 'name' ? <Glyphicon className={'pull-right'} glyph={'chevron-down'} /> : null}
                                                </th>
                                                <th style={{cursor: 'pointer'}} onClick={() => this.setState({'attachmentOrder': 'taskType'})}>
                                                    Task
                                                    {this.state.attachmentOrder === 'taskType' ? <Glyphicon className={'pull-right'} glyph={'chevron-down'} /> : null}
                                                </th>
                                                <th style={{cursor: 'pointer'}} onClick={() => this.setState({'attachmentOrder': 'updated'})}>
                                                    Updated
                                                    {this.state.attachmentOrder === 'updated' ? <Glyphicon className={'pull-right'} glyph={'chevron-down'} /> : null}
                                                </th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {attachmentRows}
                                        </tbody>
                                    </Table>
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel>
                    </Col>
                </Row>: null}
                {images.length > 0 ? <Row>
                    <Col xs={12} sm={12}>
                        <Panel bsStyle="default" defaultExpanded={false}>
                            <Panel.Heading>
                                <Panel.Title toggle>
                                    Images (click to view)
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    <ImageGallery
                                        ref={this.imageGallery}
                                        items={images}
                                        thumbnailPosition={"top"}
                                        showPlayButton={false}
                                        renderCustomControls={() => <Button
                                            bsStyle={'danger'}
                                            className="image-gallery-custom-action"
                                            bsSize="small"
                                            onClick={() => {
                                                const a = images[this.imageGallery.current.getCurrentIndex()]
                                                this.deleteAttachment(a.name, a.key)
                                            }}
                                            title="Delete"
                                            style={{
                                                position: "absolute",
                                                zIndex: 1,
                                                right: "5px",
                                                top: "5px",
                                            }}
                                        >
                                            <span className="glyphicon glyphicon-remove"></span>
                                        </Button>}
                                    />
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel>
                    </Col>
                </Row>: null}
                <Row className={notes.length === 0 ? 'hidden' : '' }>
                    <Col xs={12} sm={12}>
                        <Panel bsStyle="default">
                            <Panel.Heading>Notes</Panel.Heading>
                            <Panel.Body>
                                <Table bordered condensed hover striped>
                                    <tbody>
                                    {notes}
                                    </tbody>
                                </Table>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <FormList jobId={job.jobId} />
                    </Col>
                </Row>
                <Row className={filteredTasks.length === 0 ? 'hidden' : '' }>
                    <Col xs={12} sm={12}>
                        <Panel bsStyle="default">
                            <Panel.Heading>
                                <ButtonToolbar className="pull-right">
                                    <Button
                                        id="showExpanded"
                                        type="button"
                                        bsSize="xsmall"
                                        bsStyle="info"
                                        onClick={() => this.setState({showExpanded: !this.state.showExpanded})}
                                    >
                                        <Glyphicon glyph={this.state.showExpanded ? 'check' : 'unchecked'} />
                                        &nbsp;Expand
                                    </Button>
                                    <Button
                                        id="showCancelledButton"
                                        type="button"
                                        bsSize="xsmall"
                                        bsStyle="info"
                                        onClick={() => this.setState({showCancelled: !this.state.showCancelled})}
                                    >
                                        <Glyphicon glyph={this.state.showCancelled ? 'check' : 'unchecked'} />
                                        &nbsp;Show cancelled
                                    </Button>
                                </ButtonToolbar>
                                Tasks
                            </Panel.Heading>
                            <Panel.Body>
                                <TaskList
                                    tasks={orderedTasks}
                                    showJobInfo={false}
                                    showExpanded={this.state.showExpanded}
                                />
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        )
    }

    getNote(task, maxLength) {
        const notes = task ? task.notes
                .map(n => n.note)
                .filter(n => n && n.length > 0)
            : []

        let note = notes.length > 0 ? notes[0] : null

        if (note && note.length > maxLength) {
            note = note.substring(0, maxLength - 4) + ' ...'
        }
        return note
    }

    static addRow(rows, label, value, key) {
        if (value) {
            rows.push(
                <tr key={key ? key : label}>
                    <th>{label}</th>
                    <td style={{whiteSpace: 'pre-line'}}>{value}</td>
                </tr>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        jobSection: state.job,
        people: state.people,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        addEditJob: () => {dispatch(addEditJob())},
        addEditCustomer: () => {dispatch(addEditCustomer())},
        searchCustomers: () => {dispatch(searchCustomers(''))},
        addViewTask: (task) => {dispatch(addViewTask(task))},
        populateJobAttachments: (task) => {dispatch(populateJobAttachments(task))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Job)

