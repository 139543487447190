import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from "moment"
import { addViewTask } from "../state/actions"
import { Glyphicon, Table, Well} from "react-bootstrap"
import { Link } from "react-router-dom"
import { getHour } from "../shared/functions"

class Task extends Component {
    render() {
        const {
            task,
            people,
            addViewTask,
            showJobInfo,
            showExpanded,
            dayStartHour,
            dayEndHour
        } = this.props
        const {
            type,
            status,
            assignees,
            description,
            customer,
            address,
            jobId,
            taskId,
        } = task

        const dayStart = getHour(dayStartHour)
        const dayEnd = getHour(dayEndHour)

        const assignedTo = assignees
            .map(personId => people.find(p => (p.id === personId)))
            .filter(p => (p))
            .map(p => p.name)
            .sort()
            .join(', ')

        let dueDateColumn = <td className="hidden-xs">&nbsp;</td>

        if (task.dueDate) {
            const dueDate = moment(task.dueDate)

            dueDateColumn = <td
                title={dueDate.format("LL")}
                className={dueDate.isBefore(moment()) && status === 'Open' ? 'alert-danger hidden-xs' : 'hidden-xs'}
            >
                {dueDate.format("L")}
            </td>
        }

        let displayStatus
        let statusColumnClass

        switch (status) {
            case 'Complete':
                displayStatus = <span>
                    <Glyphicon glyph="ok" /> Complete
                </span>
                statusColumnClass = 'alert-warning'
                break;

            case 'Open':
                displayStatus = <span>
                    <Glyphicon glyph="asterisk" /> Open
                </span>
                statusColumnClass = 'alert-success'
                break;

            case 'Cancelled':
            default:
                displayStatus = <span>
                    <Glyphicon glyph="remove" /> Cancelled
                </span>
                statusColumnClass = 'alert-info'
                break;
        }

        if (showJobInfo) {
            statusColumnClass += '  hidden-xs'
        }

        let booking = 'N/A'

        if (task.bookings && task.bookings.length > 0) {

            const bookingDays = {}
            task.bookings.forEach(b => {
                const day = moment(b.date).format("L")
                if (!bookingDays[day]) {
                    bookingDays[day] = []
                }
                if (b.start === dayStart && b.end === dayEnd) {
                    bookingDays[day].push('All day')
                } else {
                    bookingDays[day].push(b.start + ' - ' + b.end)
                }
            })

            booking = Object.keys(bookingDays)
                .map(date => date + ' (' + bookingDays[date].sort().join(', ') + ')')
                .join(', ')
        }

        let detailRow

        if (showExpanded) {
            const details = []

            if (task.confirmTask) {
                Task.addSection(details, 'Provisional', <i>To be confirmed</i>)
            }

            task.aspects.forEach(a => {
                if (a.type.toLowerCase().indexOf('price') !== -1) {
                    Task.addSection(details, a.type, '£' + a.value + ' (ex. VAT)')
                } else {
                    let value = a.value

                    if (value === 'true' || value === 'false') {
                        value = value ? 'Yes' : 'No'
                    }

                    Task.addSection(details, a.type, value)
                }
            })

            if (task.notes) {
                task.notes.forEach(note => details.push(
                    <tr key={note.status}>
                        <td width="25%">Note</td>
                        <td style={{whiteSpace: 'pre-line'}}>{note.note}</td>
                    </tr>
                    )
                )
            }

            if (details.length === 0) {
                detailRow = <tr>
                    <td colSpan={showJobInfo ? '8' : '5'}>
                        <Well bsSize="small" style={{backgroundColor: 'white'}}><i>No further details</i></Well>
                    </td>
                </tr>
            } else {
                detailRow = <tr>
                    <td colSpan={showJobInfo ? '8' : '5'}>
                        <Table bordered condensed>
                            <tbody>
                            {details}
                            </tbody>
                        </Table>
                    </td>
                </tr>
            }
        }

        return (
            <tbody>
                <tr
                    style={{cursor: 'pointer'}}
                    onClick={() => addViewTask(task)}
                    key={taskId}
                >
                    <td className={showJobInfo ? 'hidden-xs' : 'hide'}><Link to={'/job/' + jobId}>{description}</Link></td>
                    <td className={showJobInfo ? '' : 'hide'}>{customer}</td>
                    <td className={showJobInfo ? 'hidden-xs' : 'hide'}>{address}</td>
                    <td>{type}</td>
                    <td className={showJobInfo ? 'hidden-xs' : ''}>{assignedTo}</td>
                    <td className={statusColumnClass}>{displayStatus}</td>
                    {dueDateColumn}
                    <td className="hidden-xs">{booking}</td>
                </tr>
                {detailRow}
            </tbody>
        )
    }

    static addSection(sections, description, value) {
        if (value)
            sections.push(
                <tr key={description}>
                    <td width="25%">{description}</td>
                    <td style={{whiteSpace: 'pre-line'}}>{value}</td>
                </tr>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        people: state.people,
        dayStartHour: state.calendar.dayStartHour,
        dayEndHour: state.calendar.dayEndHour,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addViewTask: (task) => {dispatch(addViewTask(task))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Task)

