import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Selector } from "../components"
import {updateJobsFilter} from "../state/actions"
import {Glyphicon} from "react-bootstrap"

class AddFilter extends Component {
    render () {
        const {
            status,
            noActiveTasks,
            notUpdatedFor,
            updateJobsFilter,
        } = this.props

        const options = []

        if (!status) {
            options.push({ id: 'status', value: 'Status'})
        }

        if (!noActiveTasks && noActiveTasks !== false) {
            options.push({ id: 'noActiveTasks', value: 'No active tasks'})
        }

        if (!notUpdatedFor) {
            options.push({ id: 'notUpdatedFor', value: 'Not updated for'})
        }

        const addFilter = (key, value) => {
            switch(value) {
                case 'status':
                    updateJobsFilter('status', ['Active'])
                    break
                case 'noActiveTasks':
                    updateJobsFilter('noActiveTasks', true)
                    break
                case 'notUpdatedFor':
                    updateJobsFilter('notUpdatedFor', '1')
                    break
                default:
                    updateJobsFilter(value, '')
            }
        }

        return (
            <Selector
                id={'jobs-filter-selector'}
                items={options}
                bsStyle={'primary'}
                title={<Glyphicon glyph={"plus"} />}
                valueUpdated={addFilter}
                noCaret={true}
                pullRight={false}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.job.status,
        noActiveTasks: state.job.noActiveTasks,
        notUpdatedFor: state.job.notUpdatedFor,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateJobsFilter: (key, value) => {dispatch(updateJobsFilter(key, value))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFilter)
