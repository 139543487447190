import React, {Component, useCallback} from 'react'
import { addRequest, clearAddNote, updateAddNote, populateJobTasks, populateJobAttachments } from '../../state/actions.js'
import { connect } from 'react-redux'
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup, Glyphicon, Label, ListGroup, ListGroupItem,
    Modal,
    OverlayTrigger, Popover
} from 'react-bootstrap'

import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment"
import {put} from "axios/index"
import Dropzone from './Dropzone'
import {imageExtensions} from "../../shared/data"

class AddNoteModal extends Component {

    constructor(props) {
        super(props)

        this.add = this.add.bind(this);
        this.close = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.removeAttachmentFromList = this.removeAttachmentFromList.bind(this);
    }

    add() {

        const {
            addRequest,
            clearAddNote,
            addNote,
            job,
            populateJobTasks,
            populateJobAttachments,
        } = this.props

        const body = {
            date: moment().format(),
            jobId: job.jobId,
            note: addNote.note,
            type: 'Note'
        }

        let attachments = []

        if (addNote.attachments) {
            attachments = Array.from(addNote.attachments)

            if (attachments.find(a => a.type === 'application/pdf')
                && !confirm('You are attaching a PDF that can be viewed by non-senior users. Continue?')) {
                return
            }
        }

        addRequest(
            'addNote',
            {
                url: 'task',
                method: 'POST',
                body,
            },
            (data) => {
                if(addNote.attachments) {
                    attachments.forEach(a => {
                        const url = 'attachment/' + job.jobId + '/' + data.taskId + '/' + a.name

                        addRequest(
                            url,
                            {
                                url,
                                method: 'POST',
                            },
                            body => {
                                put(
                                    body.url,
                                    a,
                                    {
                                        headers: {
                                            'content-type': a.type,
                                        }
                                    }
                                )
                                    .then(() => {
                                        addRequest(
                                            'job-attachments-' + job.jobId,
                                            {
                                                url: 'attachment?prefix=' + job.jobId,
                                                method: 'GET',
                                            },
                                            body => populateJobAttachments(body)
                                        )
                                    })
                            })
                    })
                }

                addRequest(
                    'job-tasks-' + job.jobId,
                    {
                        url: 'task?jobId=' + job.jobId,
                        method: 'GET',
                    },
                    body => populateJobTasks(body, job.jobId)
                )

                clearAddNote()
            }
        )
    }

    close() {
        this.props.clearAddNote()
    }

    handleChange(e) {
        this.props.updateAddNote('note', e.target.value);
    }

    handleFileChange(files) {
        console.log(files)
        if (this.props.addNote.attachments) {
            this.props.updateAddNote('attachments', this.props.addNote.attachments.concat(files))
        } else {
            this.props.updateAddNote('attachments', files);
        }
    }

    removeAttachmentFromList(path) {
        this.props.updateAddNote('attachments', this.props.addNote.attachments.filter(a => a.path !== path))
    }

    render () {
        const {
            addNote,
        } = this.props

        if (!addNote)
            return null

        const {
            attachments
        } = addNote

        const selectedAttachments = attachments ? attachments.map(a => (
            <ListGroupItem key={a.path}>
                {a.path}
                &nbsp;
                <Button bsStyle="danger" bsSize="xsmall" onClick={e => this.removeAttachmentFromList(a.path)}>
                    <Glyphicon glyph="remove"/>
                </Button>
            </ListGroupItem>
        )) : null

        return (
            <Modal show={true} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Add note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal>
                        <FormGroup controlId="note">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Note</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="textarea"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="attachments">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Attachments</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <ListGroup>
                                    {selectedAttachments}
                                </ListGroup>
                               <Dropzone onDrop={this.handleFileChange} />
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{textAlign: "center"}}>
                    <ButtonGroup bsSize="large">
                        <Button onClick={this.close}>Close</Button>
                        <Button onClick={this.add} bsStyle="primary">Add</Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addNote: state.job.addNote,
        job: state.job.job,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        clearAddNote: () => {dispatch(clearAddNote())},
        updateAddNote: (key, value) => {dispatch(updateAddNote(key, value))},
        populateJobTasks: (tasks, jobId) => {dispatch(populateJobTasks(tasks, jobId))},
        populateJobAttachments: (attachments) => {dispatch(populateJobAttachments(attachments))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNoteModal)
