import { actionTypes } from './actions.js'
import { getHour, getTaskType } from '../shared/functions.js'
import { TASK_TYPES } from '../shared/data.js'
import moment from "moment"
import {hasRole} from "../shared/functions"

export const getPersonById = (people, personId) => {
    const matchingpeople = people.filter((person) => person.id === personId)

    return matchingpeople.length === 0 ? null : matchingpeople[0]
}

function reducer(state, action) {
    switch (action.type) {
        case actionTypes.REMOVE_PERSON:
            return Object.assign({}, state,
                {
                    calendar: Object.assign({}, state.calendar,
                        {
                            people: state.calendar.people.filter((personId) => ( personId !== action.personId ))
                        }
                    )
                }
            )

        case actionTypes.ADD_PERSON:
            return Object.assign({}, state,
                {
                    calendar: Object.assign({}, state.calendar,
                        {
                            people: [ ...state.calendar.people, action.personId ]
                        }
                    )
                }
            )

        case actionTypes.CHOOSE_PERSON:
            return Object.assign({}, state,
                {
                    calendar: Object.assign({}, state.calendar,
                        {
                            people: [ action.personId ]
                        }
                    )
                }
            )

        case actionTypes.POPULATE_PEOPLE:
            if (!action.people) {
                return state
            }

            let people = action.people.sort((a, b) => {

                if (a.id === state.userId && a.roles.find(role => role === 'Survey' || role === 'Work')) return -1
                if (b.id === state.userId && b.roles.find(role => role === 'Survey' || role === 'Work')) return 1

                const aIsDefault = hasRole(a, 'DefaultOnCalendar')
                const bIsDefault = hasRole(b, 'DefaultOnCalendar')

                if (aIsDefault === bIsDefault) return a.name <= b.name ? -1 : 1

                return aIsDefault ? -1 : 1;
            })

            const assignablePeople =
                people
                    .filter((person) => ( person.assignable ))

            const assignableCalendarPeople = assignablePeople.filter(person => person.roles.some(role => {
                    const taskType = getTaskType(role)

                    return taskType && taskType.booking
                }))

            return Object.assign({}, state,
                {
                    people,
                    assignablePeople,
                    assignableCalendarPeople,
                    calendar: Object.assign({}, state.calendar,
                        {
                            people: state.calendar.people.length === 0
                                ? assignableCalendarPeople.slice(0, 7)
                                    .map((person) => ( person.id ))
                                : state.calendar.people,
                        }
                    )
                }
            )

        case actionTypes.ADD_REQUEST:
            return Object.assign({}, state,
                {
                    requests: [
                        ...state.requests,
                        {
                            ...action,
                            status: 'new'
                        }
                    ]
                }
            )

        case actionTypes.BEGIN_REQUEST:
            const request = state.requests.filter((request) => ( request.requestKey === action.requestKey ))[0]
            return Object.assign({}, state,
                {
                    requests: [
                        ...state.requests.filter((request) => ( request.requestKey !== action.requestKey )),
                        Object.assign({}, request,
                            {
                                status: 'working'
                            }
                        )
                    ]
                }
            )

        case actionTypes.CLEAR_REQUEST:
            return Object.assign({}, state,
                {
                    requests: state.requests.filter((request) => ( request.requestKey !== action.requestKey ))
                }
            )

        case actionTypes.FAIL_REQUEST:
            const failRequest = state.requests.filter((request) => ( request.requestKey === action.requestKey ))[0]
            return Object.assign({}, state,
                {
                    requests: [
                        ...state.requests.filter((request) => ( request.requestKey !== action.requestKey )),
                        Object.assign({}, failRequest,
                            {
                                status: 'error'
                            }
                        )
                    ]
                }
            )

        case actionTypes.POPULATE_WEEK:
            const bookingsByDayPerson = {}

            if (!action.bookings) {
                return state
            }

            action.bookings.forEach((booking) => {
                bookingsByDayPerson[booking.Date] = bookingsByDayPerson[booking.Date] || {}

                bookingsByDayPerson[booking.Date][booking.PersonId] = bookingsByDayPerson[booking.Date][booking.PersonId] || []
                bookingsByDayPerson[booking.Date][booking.PersonId] = bookingsByDayPerson[booking.Date][booking.PersonId] || []
                bookingsByDayPerson[booking.Date][booking.PersonId].push({
                    bookingId: booking.TaskBookingId,
                    taskId: booking.TaskId,
                    jobId: booking.JobId,
                    date: booking.Date,
                    start: booking.Start,
                    end: booking.End,
                    type: booking.Type,
                    status: booking.Status,
                    title: booking.JobId ? booking.Description : booking.Type,
                    contact: booking.ContactName,
                    town: booking.Town,
                    county: booking.County,
                    postcode: booking.Postcode,
                    comments: booking.Comments,
                    confirmTask: booking.ConfirmTaskId,
                    address: booking.Address,
                    customer: booking.CustomerName,
                })
            })

            const week = {}
            week[action.weekStart] = bookingsByDayPerson

            return Object.assign({}, state,
                {
                    calendar: Object.assign({}, state.calendar,
                        {
                            weeks: Object.assign({}, state.calendar.weeks, week)
                        }
                    )
                }
            )

        case actionTypes.NEW_ADD_BOOKING:
            if (state.viewTask) {
                const booking = state.viewTask.bookings[0]
                const noteRow =  state.viewTask.notes.find(n => n.status === state.viewTask.status)
                const note = noteRow ? noteRow.note : null

                return Object.assign({}, state,
                    {
                        calendar: Object.assign({}, state.calendar,
                            {
                                addBooking:  {
                                    people: state.viewTask.assignees,
                                    date: booking.date,
                                    start: booking.start,
                                    end: booking.end,
                                    type: state.viewTask.type,
                                    taskId: state.viewTask.taskId,
                                    jobId: state.viewTask.jobId,
                                    note: note,
                                }
                            }
                        )
                    }
                )
            }

            return Object.assign({}, state,
                {
                    calendar: Object.assign({}, state.calendar,
                        {
                            addBooking:  {
                                people: action.people,
                                date: action.date,
                                start: getHour(action.hour),
                                end: getHour(action.hour+(action.lengthInHours || 1)),
                                type: action.taskType || TASK_TYPES[0].name,
                                taskId: action.taskId,
                                jobId: action.jobId,
                            },
                            hover: null,
                        }
                    )
                }
            )

        case actionTypes.UPDATE_HOVER:
            if (state.calendar.taskType === 'Work' || (state.calendar.rebookTask && state.calendar.rebookTask.type === 'Work')) {
                return Object.assign({}, state,
                    {
                        calendar: Object.assign({}, state.calendar,
                            {
                                hover: {
                                    date: action.date,
                                    hour: action.hour,
                                },
                            }
                        )
                    }
                )
            }
            return state

        case actionTypes.NEW_ADD_TASK:
            if (state.viewTask) {
                const currentNote = state.viewTask.notes.find(n => n.status === state.viewTask.status)

                return Object.assign({}, state,
                    {
                        job: Object.assign({}, state.job,
                            {
                                addTask: {
                                    type: state.viewTask.type,
                                    personId: state.viewTask.assignees[0],
                                    date: state.viewTask.dueDate,
                                    taskId: state.viewTask.taskId,
                                    jobId: state.viewTask.jobId,
                                    note: currentNote ? currentNote.note : null
                                },
                            }
                        )
                    }
                )
            }

            const {
                defaultDueDays = 1
            } = getTaskType(action.taskType)

            return Object.assign({}, state,
                {
                    job: Object.assign({}, state.job,
                        {
                            addTask: {
                                type: action.taskType,
                                date: moment().add(defaultDueDays, 'd')
                            },
                        }
                    )
                }
            )

        case actionTypes.UPDATE_ADD_BOOKING:
            let newAddBooking

            if (action.key === 'allDay' && action.value) {
                newAddBooking = Object.assign({}, state.calendar.addBooking,
                    {
                        allDay: true,
                        start: getHour(state.calendar.dayStartHour),
                        end: getHour(state.calendar.dayEndHour),
                    }
                )
            } else {
                newAddBooking = Object.assign({}, state.calendar.addBooking,
                    {
                        [action.key]: action.value,
                    }
                )
            }

            return Object.assign({}, state,
                {
                    calendar: Object.assign({}, state.calendar,
                        {
                            addBooking: newAddBooking,
                        }
                    )
                }
            )

        case actionTypes.UPDATE_TOKEN:
            return Object.assign({}, state,
                {
                    login: {},
                    token: action.token,
                    userId: action.userId,
                    tasks: Object.assign({}, state.tasks,
                        {
                            personId: state.tasks.personId ? state.tasks.personId : action.userId,
                        }
                    ),
                }
            )

        case actionTypes.POPULATE_JOB_ATTACHMENTS:
            const currentUser = getPersonById(state.people, state.userId)

            let attachments = action.attachments

            if (!hasRole(currentUser, 'Senior')) {
                const hideTaskIds = state.job.tasks
                    .filter(task => task.type !== 'Note')
                    .filter(task => getTaskType(task.type).restricted)
                    .map(task => task.taskId)

                attachments = attachments
                    .filter(a => !hideTaskIds.find(taskId => a.url.includes(taskId)))
            }

            return {
                ...state,
                job: {
                    ...state.job,
                    attachments: attachments
                }
            }

        case actionTypes.UPDATE_CALENDAR_TASK_TYPE:
            const taskPeople =
                state.people
                    .filter((person) => (
                        person.assignable
                        && person.roles.some(role => {
                            const taskType = getTaskType(role)

                            return taskType && taskType.booking
                        })
                        && (
                            !action.taskType
                            || TASK_TYPES.find(t => t.name === action.taskType).allPeople
                            || hasRole(person, action.taskType))
                        )
                    )

            return Object.assign({}, state,
                {
                    assignableCalendarPeople: taskPeople,
                    calendar: Object.assign({}, state.calendar,
                        {
                            people: taskPeople.slice(0, 6)
                                    .map((person) => ( person.id )),
                            taskType: action.taskType
                        }
                    )
                }
            )

        case actionTypes.UPDATE_TASKS:
            if (action.tasks.message) {
                return state
            } else {
                return Object.assign({}, state,
                    {
                        tasks: Object.assign({}, state.tasks,
                            {
                                tasks: action.tasks,
                            }
                        )
                    }
                )
            }

        case actionTypes.NEW_COMPLETE_TASK:

            let type = getTaskType(state.viewTask.type)

            if (!type) {
                type = { name: 'Note'}
            }

            return Object.assign({}, state,
                {
                    completeTask: {
                        nextTaskType: type.nextIsMandatory ? type.next[0] : null
                    },
                }
            )

        case actionTypes.EDIT_EXISTING_COMPLETE_TASK:
            let taskType = getTaskType(state.viewTask.type)

            if (!taskType) {
                taskType = { name: 'Note'}
            }

            const aspects = taskType.complete
                ? state.viewTask.aspects.reduce((a, v) => {
                    a[taskType.complete.find(t => t.type === v.type).typeId] = v.value
                    return a
                }, {})
                : null

            return {
                ...state,
                completeTask: {
                    ...aspects,
                    note: state.viewTask.notes
                        .filter(n => n.status === 'Complete' || taskType.name === 'Note')
                        .map(n => n.note)
                        .find(() => true)
                }
            }

        case actionTypes.ADD_EDIT_JOB:
            return Object.assign({}, state,
                {
                    job: Object.assign({}, state.job,
                        {
                            edit: state.job.job
                        }
                    )
                }
            )

        case actionTypes.COPY_NEW_JOB_ADDRESS_FROM_CUSTOMER:
            return Object.assign({}, state,
                {
                    job: Object.assign({}, state.job,
                        {
                            new: Object.assign({}, state.job.new,
                                {
                                    address: state.job.new.customer.address,
                                }
                            )
                        }
                    )
                }
            )

        case actionTypes.ADD_REQUEST_FOR_TASKS:
            let url = 'task?a=0'

            if (action.personId) {
                url += '&personId=' + action.personId
            }

            if (action.status) {
                url += '&status=' + action.status
            }

            if (action.taskType) {
                url += '&type=' + action.taskType
            }

            return Object.assign({}, state,
                {
                    requests: [
                        ...state.requests,
                        {
                            requestKey: 'tasks-' + action.personId,
                            options: {
                                    url,
                                    method: 'GET',
                                },
                            callback: action.callback,
                            status: 'new'
                        }
                    ]
                }
            )

        case actionTypes.ADD_EDIT_CUSTOMER:
            return Object.assign({}, state,
                {
                    customer: Object.assign({}, state.customer,
                        {
                            edit: state.job.job.customer
                        }
                    )
                }
            )

        case actionTypes.UPDATE_KEY_VALUE:
            return {
                ...state,
                [action.key]: action.value,
            }

        case actionTypes.UPDATE_SECTION_KEY_VALUE:
            return {
                ...state,
                [action.section]: {
                    ...state[action.section],
                    [action.key]: action.value,
                }
            }

        case actionTypes.UPDATE_SUBSECTION_KEY_VALUE:
            return {
                ...state,
                [action.section]: {
                    ...state[action.section],
                    [action.subsection]: {
                        ...state[action.section][action.subsection],
                        [action.key]: action.value,
                    }
                }
            }

        case actionTypes.POPULATE_JOB_TASKS:
            if (state.job.job && action.jobId !== state.job.job.jobId) {
                return state
            }

            return {
                ...state,
                job: {
                    ...state.job,
                    tasks: action.tasks,
                }
            }

        case actionTypes.REBOOK:
            if (action.taskId) {
                return Object.assign({}, state,
                    {
                        calendar: Object.assign({}, state.calendar,
                            {
                                rebookTask: {
                                    type: action.taskType,
                                    taskId: action.taskId,
                                    lengthInHours: action.lengthInHours,
                                    isDragging: action.isDragging,
                                    jobId: action.jobId,
                                },
                                hover: null,
                                people: (action.taskType === 'Work' && state.viewTask) ? state.viewTask.assignees : state.calendar.people,
                                weekStartDate: moment(action.date).utc().startOf('isoWeek').toJSON(),
                                job: {
                                    jobId: action.jobId,
                                }
                            }
                        ),
                        viewTask: null,
                    }
                )
            } else {
                return Object.assign({}, state,
                    {
                        calendar: Object.assign({}, state.calendar,
                            {
                                rebookTask: null,
                                hover: null,
                                job: null,
                                taskType: null,
                            }
                        ),
                    }
                )
            }

        case actionTypes.UPDATE_FORM_KEY_VALUE:
            let form = {
                ...state.form
            }
            let lastParent = form
            let first = true

            action.parents.forEach(parent => {
                const child = lastParent[parent] ? {...lastParent[parent]} : {}
                lastParent[parent] = child

                if (first) {
                    form = lastParent
                    first = false
                }

                lastParent = child
            })

            lastParent[action.key] = action.value

            return {
                ...state,
                form
            }

        default:
            return state
    }
}

export default reducer
