import React, { Component } from 'react'
import { updateTasksFilter } from '../state/actions.js'
import { connect } from 'react-redux'
import { Selector } from "../components"

class Person extends Component {
    render () {
        const { people, personId, updateTasksFilter } = this.props

        const options = people
            .filter(person => person.assignable)
            .sort((a, b) => (a.name <= b.name ? -1 : 1))
            .map(person => ({
                    id: person.id,
                    value: person.name,
                }))

        return (
            <Selector
                closeButton={true}
                id={'tasks-person-selector'}
                items={options}
                selectedId={personId}
                title={'Person'}
                valueUpdated={updateTasksFilter}
                valueUpdatedId={'personId'}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        people: state.people,
        personId: state.tasks.personId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateTasksFilter: (key, value) => {dispatch(updateTasksFilter(key, value))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Person)
