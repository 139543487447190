import React, { Component } from 'react'
import {FormControl} from 'react-bootstrap'
import { connect } from 'react-redux'
import {updateJobsFilter} from "../state/actions"

class SearchBox extends Component {
    render () {
        return <FormControl
                type="text"
                placeholder="Job name, postcode, etc."
                onKeyUp={e => {
                    this.props.updateJobsFilter(e.target.value)

                    if (e.key === "Enter") {
                        this.props.onChange(e.target.value)
                    }
                }}
            />
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateJobsFilter: (searchString) => {dispatch(updateJobsFilter('searchString', searchString))},
    })
}

export default connect(null, mapDispatchToProps)(SearchBox)
