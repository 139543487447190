import ls from "local-storage"
import {CALENDAR_LABELS, TASK_STATES} from "../shared/data"
import moment from "moment/moment"

export const getStartingState = () => {
    const token = ls.get('token')
    const userId = ls.get('userId')

    const calendar = {
        weekStartDate: moment().utc().startOf('isoWeek').toJSON(),
        people: [],
        weeks: {},
        display: 'title',
        displayWeeks: 1,
        dayStartHour: 7,
        dayEndHour: 20,
        label: [
            CALENDAR_LABELS.CUSTOMER,
            CALENDAR_LABELS.DESCRIPTION,
            CALENDAR_LABELS.ADDRESS,
            CALENDAR_LABELS.COMPLETE,
        ],
    }

    return {
        calendar,
        requests: [],
        people: [],
        assignablePeople: [],
        customer: {},
        job: {
            status: ['Not confirmed', 'Confirmed', 'Active', 'Invoiced'],
        },
        local: false,
        login: {},
        token,
        userId,
        tasks: {
            personId: userId,
            status: TASK_STATES.OPEN,
            tasks: [],
        },
        form: {}
    }
}
