import React, { Component } from 'react'
import { addRequest, clearAddBooking, populateWeek, rebook, updateAddBooking, updateKeyValue } from '../state/actions.js'
import { getHour, populateAllWeeks, getFormType, getHourFromNumber, getTaskType, getTimeAsNumber, hasRole } from '../shared/functions.js'
import { TASK_TYPES } from '../shared/data.js'
import { connect } from 'react-redux'
import moment from 'moment'
import {
    Button,
    ButtonGroup, ButtonToolbar,
    Col,
    ControlLabel,
    DropdownButton,
    Form,
    FormControl,
    FormGroup, Glyphicon,
    MenuItem,
    Modal
} from 'react-bootstrap'
import {AddBookingPerson, SelectedJob, SelectedPerson} from '.'
import DatePicker from "react-datepicker"
import FormControlStatic from "react-bootstrap/lib/FormControlStatic"
import { getPersonById } from "../state/reducer"


const getHourOption = (hour, half) => {
    const hourText = getHour(hour, half)
    return <option key={hourText} value={hourText}>{hourText}</option>
}

class AddBookingModal extends Component {

    constructor(props) {
        super(props)

        this.book = this.book.bind(this);
        this.close = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getFormDetails = this.getFormDetails.bind(this);

        this.state = {}
    }

    componentDidMount() {
        this.getFormDetails(this.props)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.getFormDetails(this.props)
        }
    }

    getFormDetails(props) {
        const {
            addRequest,
            addBooking,
            job,
        } = props

        if (addBooking) {
            const taskType = getTaskType(addBooking.type)

            if (taskType && taskType.formOnCreate) {

                taskType.formOnCreate.forEach(formTypeName => {
                    const formType = getFormType(formTypeName)
                    const jobId = job ? job.jobId : addBooking.jobId
                    const id = formType.job ? jobId : addBooking.taskId

                    const formUrl = 'form?'
                        + 'formType=' + formTypeName
                        + '&id=' + id

                    addRequest(
                        'getForms-' + id + formTypeName,
                        {
                            url: formUrl,
                            method: 'GET',
                        },
                        body => {
                            if (body.length === 0) {
                                this.setState({[formTypeName]: null})
                            } else {
                                this.setState({[formTypeName]: body[0]})
                            }
                        }
                    )
                })
            }
        }
    }

    book() {

        const {
            addRequest,
            clearAddBooking,
            populateWeekData,
            weekStartDate,
            addBooking,
            job,
            rebook,
            displayWeeks,
            updateKeyValue,
        } = this.props

        const formStartDate = moment(addBooking.date)
        const formEndDate = moment(addBooking.endDate ? addBooking.endDate : addBooking.date)
        const jobId = job ? job.jobId : addBooking.jobId

        const startDate = formStartDate.isBefore(formEndDate) ? formStartDate : formEndDate
        const endDate = formStartDate.isBefore(formEndDate) ? formEndDate : formStartDate

        const daysDiff = endDate.diff(startDate, 'days')
        const requests = []

        for (let dayOffset = 0; dayOffset <= daysDiff; dayOffset++) {
            const date = startDate.clone().add(dayOffset, 'days')

            const immutableAddBooking = {
                ...addBooking
            }

            requests.push({
                addBooking: immutableAddBooking,
                date,
                body: {
                    date: date.format(),
                    taskId: startDate.isSame(date) ? immutableAddBooking.taskId : null,
                    jobId: jobId,
                    end: immutableAddBooking.end,
                    start: immutableAddBooking.start,
                    type: immutableAddBooking.type,
                    people: immutableAddBooking.people,
                    note: immutableAddBooking.note,
                    confirmBookingPerson: immutableAddBooking.confirmBookingPerson,
                }
            })
        }

        requests.forEach(request => {
            addRequest(
                'addBooking-' + request.body.date,
                {
                    url: 'booking',
                    method: 'POST',
                    body: request.body,
                },
                addBookingResponse => {
                    populateAllWeeks(addRequest, populateWeekData, weekStartDate, displayWeeks)
                    clearAddBooking()
                    rebook()

                    const taskType = getTaskType(request.body.type)

                    if (taskType && taskType.formOnCreate) {
                        taskType.formOnCreate.forEach(formTypeName => {
                            let formPerson = request.addBooking["formPerson" + formTypeName]
                            formPerson = formPerson === "Not required" ? null : formPerson
                            const existingForm = this.state[formTypeName]

                            if (formPerson || existingForm) {
                                const formType = getFormType(formTypeName)

                                if (startDate.isSame(request.date) || !formType.job) {
                                    addRequest(
                                        'upsert-form-' + addBookingResponse.taskId + '-' + formTypeName,
                                        {
                                            url: 'form',
                                            method: 'POST',
                                            body: {
                                                jobOrTaskId: formType.job ? request.body.jobId : addBookingResponse.taskId,
                                                jobId: request.body.jobId,
                                                formType: formTypeName,
                                                personId: formPerson ?? existingForm.personId,
                                                dueDate: request.body.date,
                                            }
                                        },
                                        () => {
                                            updateKeyValue('formsLastRefreshed', new Date().toDateString())
                                        }
                                    )
                                }
                            }
                        })
                    }
                }
            )
        })
    }

    close() {
        this.props.clearAddBooking()
    }

    handleChange(e) {
        this.props.updateAddBooking(e.target.id, e.target.value);
    }

    UNSAFE_componentWillMount() {
        this.setDefaultTaskType(this.props)

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setDefaultTaskType(nextProps)
    }

    setDefaultTaskType(props) {
        if (props.addBooking && !props.addBooking.type) {
            const taskTypeNames = TASK_TYPES
                .filter((type) => type.job === !!(props.job) && type.booking)
                .map((type) => type.name)

            props.updateAddBooking('type', taskTypeNames[0])
        }
    }

    render () {
        const {
            addBooking,
            people,
            allPeople,
            dayStartHour,
            dayEndHour,
            updateAddBooking,
            job,
        } = this.props

        if (!addBooking)
            return null

        let peopleSelector

        if (addBooking.people.length === 1) {
            const name = people
                .find(person => addBooking.people[0] === person.id)
                .name
            peopleSelector = <span>
                {name} &nbsp;
                <AddBookingPerson bsSize={'small'} taskType={addBooking.type}/>
            </span>
        } else {
            const selectedPeople = people
                .filter(person => addBooking.people.indexOf(person.id) !== -1)
                .map(person => <SelectedPerson person={person} key={person.id} />)

            peopleSelector = <ButtonToolbar>
                {selectedPeople}
                <AddBookingPerson taskType={addBooking.type}/>
            </ButtonToolbar>
        }

        const hours = []

        for (let i=dayStartHour; i<dayEndHour; i++) {
            hours.push(getHourOption(i))
            hours.push(getHourOption(i, true))
        }

        const endHours = []
        const beginEndHours = getTimeAsNumber(addBooking.start) + 0.5

        for (let i=beginEndHours; i<dayEndHour; i += 0.5) {
            endHours.push(getHourOption(Math.trunc(i), i !== Math.round(i)))
        }

        const lastHourOption = getHourOption(dayEndHour)

        let taskTypeOptions

        if (addBooking.type && addBooking.taskId) {
            taskTypeOptions = [ <option key={addBooking.type} value={addBooking.type}>{addBooking.type}</option> ]
        } else {
            taskTypeOptions = TASK_TYPES
                .filter((type) => type.job === !!(job) && type.booking)
                .map((type) => type.name)
                .map((type) => <option key={type} value={type}>{type}</option>)
        }

        const confirmBookingPeople = allPeople
            .filter(person =>
                person.assignable
                && hasRole(person, 'Call customer')
            )
            .sort((a, b) => (a.name <= b.name ? -1 : 1))
            .map(person => (
                    <option
                        key={person.id}
                        value={person.id}
                    >
                        {person.name}
                    </option>
                )
            )

        const taskType = getTaskType(addBooking.type)

        return (
            <Modal show={true} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>{addBooking.taskId ? 'Update' : 'Add'} booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SelectedJob />
                    <Form horizontal>
                        <FormGroup controlId="date">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Date</ControlLabel>
                            </Col>
                            <Col xs={12} sm={4}>
                                <DatePicker
                                    onChange={(v) => this.handleChange({
                                        target: {
                                            id: 'date',
                                            value: v
                                        }
                                    })}
                                    selected={new Date(addBooking.date)}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </Col>
                            {addBooking.endDate ?
                            <Col xs={2} sm={1}>
                                <FormControlStatic>
                                    to
                                </FormControlStatic>
                            </Col>
                                : null}
                            <Col xs={10} sm={4}>
                            {addBooking.endDate ?
                                <DatePicker
                                    onChange={(v) => this.handleChange({
                                        target: {
                                            id: 'endDate',
                                            value: v
                                        }
                                    })}
                                    selected={new Date(addBooking.endDate)}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                />
                                : <Button
                                    onClick={() => this.handleChange({
                                    target: {
                                        id: 'endDate',
                                        value: moment(addBooking.date).add(1, "day")
                                    }
                                })}>
                                    <Glyphicon glyph={"plus"} />
                                </Button>}
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="people">
                            <Col xs={12} sm={3}>
                                <ControlLabel>{addBooking.people.length === 1 ? 'Person' : 'People'}</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                {peopleSelector}
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Type</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="select"
                                    id="type"
                                    onChange={this.handleChange}
                                    value={addBooking.type}
                                    disabled={addBooking.taskId}
                                >
                                    {taskTypeOptions}
                                </FormControl>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col xs={12} sm={3}>
                                <DropdownButton bsStyle={'default'} title={addBooking.allDay ? 'All day' : 'Time'} id={'allDay'}>
                                    <MenuItem
                                        onSelect={() => updateAddBooking('allDay', true)}
                                    >
                                        All day <i className={addBooking.allDay ? 'fa fa-check' : 'hidden'} />
                                    </MenuItem>
                                    <MenuItem
                                        onSelect={() => updateAddBooking('allDay', false)}
                                    >
                                        Time  <i className={addBooking.allDay ? 'hidden' : 'fa fa-check'} />
                                    </MenuItem>
                                </DropdownButton>
                            </Col>
                            <Col xs={12} sm={4}>
                                <FormControl
                                    componentClass="select"
                                    disabled={addBooking.allDay}
                                    id="start"
                                    onChange={e => {
                                        this.handleChange(e)
                                        this.props.updateAddBooking(e.target.id, e.target.value);

                                        const start = getTimeAsNumber(e.target.value)
                                        if (getTimeAsNumber(e.target.value) >= getTimeAsNumber(addBooking.end)) {
                                            this.handleChange({
                                                target: {
                                                    id: 'end',
                                                    value: getHourFromNumber(start + 0.5),
                                                }
                                            })
                                        }
                                    }}
                                    value={addBooking.start}
                                >
                                    {hours}
                                </FormControl>
                            </Col>
                            <Col xs={12} sm={1}>
                                <FormControl.Static>to</FormControl.Static>
                            </Col>
                            <Col xs={12} sm={4}>
                                <FormControl
                                    componentClass="select"
                                    disabled={addBooking.allDay}
                                    id="end"
                                    onChange={this.handleChange}
                                    value={addBooking.end}
                                >
                                    {endHours}
                                    {lastHourOption}
                                </FormControl>
                            </Col>
                        </FormGroup>
                        <FormGroup className={job ? '' : 'hidden'}>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Provisional?</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="select"
                                    id="confirmBookingPerson"
                                    onChange={this.handleChange}
                                    value={addBooking.confirmBookingPerson}
                                >
                                    <option value={null} key={null}>No</option>
                                    <optgroup label="Yes, add task for:">
                                        {confirmBookingPeople}
                                    </optgroup>
                                </FormControl>
                            </Col>
                        </FormGroup>
                        {taskType && taskType.formOnCreate ? taskType.formOnCreate.map(formTypeName => (
                        <FormGroup key={formTypeName}>
                            <Col xs={12} sm={3}>
                                <ControlLabel style={{"textAlign": "left"}}>{formTypeName}</ControlLabel>
                            </Col>
                            {this.state[formTypeName] ?
                                <Col xs={12} sm={9}>
                                    Assigned to {getPersonById(allPeople, this.state[formTypeName].personId).name}
                                </Col>
                                : <Col xs={12} sm={9}>
                                    <FormControl
                                        componentClass="select"
                                        id={"formPerson" + formTypeName}
                                        onChange={this.handleChange}
                                        value={addBooking["formPerson" + formTypeName]}
                                    >
                                        <option value={null} key={null}>Not required</option>
                                        <optgroup label="To be completed by:">
                                            {allPeople
                                                .filter(person =>
                                                    person.assignable
                                                    && hasRole(person, 'form: ' + formTypeName)
                                                    && addBooking.people.find(p => p === person.id)
                                                )
                                                .sort((a, b) => (a.name <= b.name ? -1 : 1))
                                                .map(person => (
                                                        <option
                                                            key={person.id}
                                                            value={person.id}
                                                        >
                                                            {person.name}
                                                        </option>
                                                    )
                                                )}
                                        </optgroup>
                                    </FormControl>
                                </Col>
                            }
                        </FormGroup>
                        )) : null}
                        <FormGroup controlId="note">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Note</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="textarea"
                                    value={addBooking.note ? addBooking.note : ''}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{textAlign: "center"}}>
                    <ButtonGroup bsSize="large">
                        <Button onClick={this.close}>Close</Button>
                        <Button onClick={this.book} bsStyle="primary">{addBooking.taskId ? 'Update' : 'Book'}</Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addBooking: state.calendar.addBooking,
        people: state.assignablePeople,
        allPeople: state.people,
        dayStartHour: state.calendar.dayStartHour,
        dayEndHour: state.calendar.dayEndHour,
        weekStartDate: state.calendar.weekStartDate,
        displayWeeks: state.calendar.displayWeeks,
        job: state.calendar.job,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        clearAddBooking: () => {dispatch(clearAddBooking())},
        populateWeekData: (weekStart, bookings) => {dispatch(populateWeek(weekStart, bookings))},
        updateAddBooking: (key, value) => {dispatch(updateAddBooking(key, value))},
        rebook: () => {dispatch(rebook())},
        updateKeyValue: (key, value) => {dispatch(updateKeyValue(key, value))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBookingModal)
