import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CustomerList, SearchBox } from '.'
import { Modal } from 'react-bootstrap'

import 'react-datepicker/dist/react-datepicker.css';
import {searchCustomers} from "../../state/actions"

class FindCustomerModal extends Component {
    constructor(props) {
        super(props)

        this.close = this.close.bind(this);
    }

    close() {
        this.props.clearSearch()
    }

    render () {
        const {
            searchString,
        } = this.props

        if (!searchString && searchString !== '')
            return null

        return (
            <Modal show={true} onHide={this.close} bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title>Find Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SearchBox />
                    <CustomerList />
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        searchString: state.customer.searchString,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        clearSearch: () => {dispatch(searchCustomers(null))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(FindCustomerModal)
