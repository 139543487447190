import React, { Component } from 'react'
import { connect } from 'react-redux'

class ConditionalRole extends Component {
    render () {
        const {
            userId,
            people,
            role,
            children
        } = this.props

        if (people) {
            const person = people.filter(person => person.id === userId)[0]

            if (person && person.roles.indexOf(role) !== -1) {
                return children
            }
        }

        return null
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.userId,
        people: state.people,
    }
}

export default connect(mapStateToProps)(ConditionalRole)
