import React, { Component } from 'react'
import { DropdownButton, Glyphicon, MenuItem } from 'react-bootstrap'
import { TASK_TYPES } from '../../shared/data.js'
import { connect } from 'react-redux'
import { newAddTask } from '../../state/actions.js'

class AddTask extends Component {
    render() {
        const {
            newAddTask
        } = this.props

        const bookingTypes = TASK_TYPES
            .filter((type) => type.job && !type.booking)
            .map((type) => type.name)
            .map((type) => (
                <MenuItem
                    key={type}
                    eventKey={type}
                    onClick={() => {
                        newAddTask(type)
                    }}
                >
                    {type}
                </MenuItem>
            ))

        return (
            <DropdownButton
                id={'job-add-task'}
                type="button"
                bsStyle="info"
                title={<span><Glyphicon glyph="check" /> Task</span>}
            >
                {bookingTypes}
            </DropdownButton>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        newAddTask: (taskType) => {dispatch(newAddTask(taskType))},
    })
}

export default connect(null, mapDispatchToProps)(AddTask)
