import React, { Component } from 'react'
import { addRequest, clearAddTask, updateAddTask, populateJobTasks } from '../../state/actions.js'
import { TASK_TYPES } from '../../shared/data.js'
import { connect } from 'react-redux'
import {Button, ButtonGroup, Col, ControlLabel, Form, FormControl, FormGroup, Modal} from 'react-bootstrap'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import moment from "moment"
import {addRequestForTasks, updateTasks} from "../../state/actions"
import {getTaskType, hasRole} from "../../shared/functions"

class AddTaskModal extends Component {

    constructor(props) {
        super(props)

        this.add = this.add.bind(this);
        this.close = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    add() {

        const {
            addRequest,
            clearAddTask,
            addTask,
            job,
            populateJobTasks,
            filterPersonId,
            filterStatus,
            filterTaskType,
            updateTasks,
        } = this.props

        const body = addTask

        if (job)
            body.jobId = job.jobId

        body.date = moment(body.date).format()
        addRequest(
            'addTask',
            {
                url: 'task',
                method: 'POST',
                body,
            },
            () => {
                if (job) {
                    addRequest(
                        'job-tasks-' + job.jobId,
                        {
                            url: 'task?jobId=' + job.jobId,
                            method: 'GET',
                        },
                        body => populateJobTasks(body, job.jobId)
                    )
                } else {
                    addRequestForTasks(
                        filterPersonId,
                        filterStatus,
                        filterTaskType,
                        body => updateTasks(body),
                    )
                }

                clearAddTask()
            }
        )
    }

    close() {
        this.props.clearAddTask()
    }

    handleChange(e) {
        this.props.updateAddTask(e.target.id, e.target.value);
    }

    UNSAFE_componentWillMount() {
        this.setDefaultPerson(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setDefaultPerson(nextProps)
    }

    setDefaultPerson(props) {
        const {
            addTask,
            people
        } = props

        if (addTask) {
            const taskType = getTaskType(addTask.type)

            const peopleIds = people
                .filter((person) => hasRole(person, addTask.type) || taskType.allPeople )
                .map((person) => person.id)

            if (!addTask.personId || peopleIds.indexOf(addTask.personId) === -1) {
                props.updateAddTask('personId', peopleIds[0])
            }
        }
    }

    validateDate() {
        const date = this.props.addTask.date

        if (!date) {
            return 'error'
        }

        if (date < moment()) {
            return 'warning'
        }

        return null
    }

    render () {
        const {
            addTask,
            people,
            job,
        } = this.props

        if (!addTask)
            return null

        const taskType = getTaskType(addTask.type)

        const peopleOptions = people
            .filter((person) => hasRole(person, addTask.type) || taskType.allPeople)
            .map((person) => <option key={person.id} value={person.id}>{person.name}</option>)

        const taskTypeOptions = TASK_TYPES
            .filter((type) => type.job === !!(job) && !type.booking)
            .map((type) => type.name)
            .map((type) => <option key={type} value={type}>{type}</option>)

        return (
            <Modal show={true} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Add task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal>
                        <FormGroup>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Type</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                {addTask.taskId ?
                                    <FormControl
                                        componentClass="select"
                                        id="type"
                                        value={addTask.type}
                                        disabled={true}
                                    >
                                        <option key={addTask.type} value={addTask.type}>{addTask.type}</option>
                                    </FormControl>
                                    :
                                <FormControl
                                    componentClass="select"
                                    id="type"
                                    onChange={this.handleChange}
                                    value={addTask.type}>
                                    {taskTypeOptions}
                                </FormControl>}
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="personId">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Person</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="select"
                                    onChange={this.handleChange}
                                    value={addTask.personId}
                                >
                                    {peopleOptions}
                                </FormControl>
                            </Col>
                        </FormGroup>
                        <FormGroup validationState={this.validateDate()}>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Date</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <DatePicker
                                    onChange={(v) => this.handleChange({
                                        target: {
                                            id: 'date',
                                            value: v
                                        }
                                    })}
                                    selected={addTask.date.toDate ? addTask.date.toDate() : ((typeof addTask.date) === 'string' ? new Date(addTask.date) : addTask.date)}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="note">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Note</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="textarea"
                                    value={addTask.note ? addTask.note : ''}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{textAlign: "center"}}>
                    <ButtonGroup bsSize="large">
                        <Button onClick={this.close}>Close</Button>
                        <Button onClick={this.add} bsStyle="primary">{addTask.taskId ? "Update" : "Add"}</Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addTask: state.job.addTask,
        people: state.assignablePeople,
        job: state.job.job,
        filterPersonId: state.tasks.personId,
        filterStatus: state.tasks.status,
        filterTaskType: state.tasks.type,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        clearAddTask: () => {dispatch(clearAddTask())},
        updateAddTask: (key, value) => {dispatch(updateAddTask(key, value))},
        populateJobTasks: (tasks, jobId) => {dispatch(populateJobTasks(tasks, jobId))},
        addRequestForTasks: (personId, status, taskType, callback) => {dispatch(addRequestForTasks(personId, status, taskType, callback))},
        updateTasks: tasks => {dispatch(updateTasks(tasks))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskModal)
