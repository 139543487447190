import React, { Component } from 'react'
import { ButtonToolbar, Panel } from 'react-bootstrap'
import { Person, Status, Tasks, Type } from '.'
import {connect} from "react-redux"
import { addRequestForTasks, updateTasks } from '../state/actions.js'

class ToDoPage extends Component {
    UNSAFE_componentWillMount() {
        this.requestTasks(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.requestTasks(nextProps)
    }

    requestTasks(props) {
        const {
            personId,
            status,
            taskType,
            addRequestForTasks,
            updateTasks,
        } = props

        addRequestForTasks(
            personId,
            status,
            taskType,
            body => updateTasks(body),
        )
    }

    render () {
        return (
            <Panel>
                <Panel.Body>
                    <ButtonToolbar>
                        <Person />
                        <Status />
                        <Type />
                    </ButtonToolbar>
                    <Tasks />
                </Panel.Body>
            </Panel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        personId: state.tasks.personId,
        status: state.tasks.status,
        taskType: state.tasks.type,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateTasks: tasks => {dispatch(updateTasks(tasks))},
        addRequestForTasks: (personId, status, taskType, callback) => {dispatch(addRequestForTasks(personId, status, taskType, callback))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ToDoPage)
