export const actionTypes = {
    REMOVE_PERSON: 'remove-person',
    ADD_PERSON: 'add-person',
    CHOOSE_PERSON: 'choose-person',
    POPULATE_PEOPLE: 'populate-people',
    ADD_REQUEST: 'add-request',
    CLEAR_REQUEST: 'clear-request',
    BEGIN_REQUEST: 'begin-request',
    FAIL_REQUEST: 'fail-request',
    POPULATE_WEEK: 'populate-week',
    NEW_ADD_BOOKING: 'new-add-booking',
    UPDATE_HOVER: 'update-hover',
    NEW_ADD_TASK: 'new-add-task',
    UPDATE_ADD_BOOKING: 'update-add-booking',
    UPDATE_TOKEN: 'update-token',
    POPULATE_JOB_ATTACHMENTS: 'populate-job-attachments',
    POPULATE_JOB_TASKS: 'populate-job-tasks',
    UPDATE_CALENDAR_TASK_TYPE: 'update-calendar-task-type',
    UPDATE_TASKS: 'update-tasks',
    NEW_COMPLETE_TASK: 'new-complete-task',
    EDIT_EXISTING_COMPLETE_TASK: 'edit-existing-complete-task',
    COPY_NEW_JOB_ADDRESS_FROM_CUSTOMER: 'copy-new-job-address-from-customer',
    ADD_REQUEST_FOR_TASKS: 'add-request-for-tasks',
    ADD_EDIT_JOB: 'add-edit-job',
    ADD_EDIT_CUSTOMER: 'add-edit-customer',
    UPDATE_KEY_VALUE: 'update-key-value',
    UPDATE_SECTION_KEY_VALUE: 'update-section-key-value',
    UPDATE_SUBSECTION_KEY_VALUE: 'update-subsection-key-value',
    REBOOK: 'rebook',
    UPDATE_FORM_KEY_VALUE: 'update-form-key-value'
}

export const removePerson = (personId) => {
    return {
        type: actionTypes.REMOVE_PERSON,
        personId,
    }
}

export const addPerson = (personId) => {
    return {
        type: actionTypes.ADD_PERSON,
        personId,
    }
}

export const choosePerson = (personId) => {
    return {
        type: actionTypes.CHOOSE_PERSON,
        personId,
    }
}

export const updateWeek = (weekStartDate) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'calendar',
        key: 'weekStartDate',
        value: weekStartDate,
    }
}

export const populatePeople = (people, requestKey) => {
    return {
        type: actionTypes.POPULATE_PEOPLE,
        people,
        requestKey,
    }
}

export const addRequest = (requestKey, options, callback) => {
    return {
        type: actionTypes.ADD_REQUEST,
        requestKey,
        options,
        callback,
    }
}

export const beginRequest = (requestKey) => {
    return {
        type: actionTypes.BEGIN_REQUEST,
        requestKey,
    }
}

export const clearRequest = (requestKey) => {
    return {
        type: actionTypes.CLEAR_REQUEST,
        requestKey,
    }
}

export const failRequest = (requestKey) => {
    return {
        type: actionTypes.FAIL_REQUEST,
        requestKey,
    }
}

export const populateWeek = (weekStart, bookings) => {
    return {
        type: actionTypes.POPULATE_WEEK,
        weekStart,
        bookings,
    }
}

export const newAddBooking = (
    people,
    date,
    hour,
    lengthInHours,
    taskType,
    taskId,
    jobId,
) => {
    return {
        type: actionTypes.NEW_ADD_BOOKING,
        people,
        date,
        hour,
        lengthInHours,
        taskType,
        taskId,
        jobId,
    }
}

export const updateHover = (date, hour) => {
    return {
        type: actionTypes.UPDATE_HOVER,
        date,
        hour,
    }
}

export const clearAddBooking = () => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'calendar',
        key: 'addBooking',
        value: null,
    }
}

export const newAddTask = (taskType) => {
    return {
        type: actionTypes.NEW_ADD_TASK,
        taskType,
    }
}

export const clearAddTask = () => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'job',
        key: 'addTask',
        value: null,
    }
}

export const setConfig = (config) => {
    return {
        type: actionTypes.UPDATE_KEY_VALUE,
        key: 'config',
        value: config,
    }
}

export const updateAddBooking = (key, value) => {
    return {
        type: actionTypes.UPDATE_ADD_BOOKING,
        key,
        value,
    }
}

export const updateAddTask = (key, value) => {
    return {
        type: actionTypes.UPDATE_SUBSECTION_KEY_VALUE,
        section: 'job',
        subsection: 'addTask',
        key,
        value,
    }
}

export const updateToken = (token, userId) => {
    return {
        type: actionTypes.UPDATE_TOKEN,
        token,
        userId,
    }
}

export const updateLogin = (key, value) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'login',
        key,
        value,
    }
}

export const addViewTask = (task) => {
    return {
        type: actionTypes.UPDATE_KEY_VALUE,
        key: 'viewTask',
        value: task,
    }
}

export const clearViewTask = () => {
    return {
        type: actionTypes.UPDATE_KEY_VALUE,
        key: 'viewTask',
        value: null,
    }
}

export const populateJob = (job) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'job',
        key: 'job',
        value: job,
    }
}

export const populateJobTasks = (tasks, jobId) => {
    return {
        type: actionTypes.POPULATE_JOB_TASKS,
        jobId,
        tasks,
    }
}

export const populateJobAttachments = (attachments) => {
    return {
        type: actionTypes.POPULATE_JOB_ATTACHMENTS,
        attachments,
    }
}

export const populateJobs = (jobs) => {
    return {
        type: actionTypes.UPDATE_KEY_VALUE,
        key: 'jobs',
        value: jobs,
    }
}

export const updateJobsFilter = (key, value) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'job',
        key,
        value,
    }
}

export const updateCalendarJob = (job) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'calendar',
        key: 'job',
        value: job,
    }
}

export const updateCalendarTaskType = (taskType) => {
    return {
        type: actionTypes.UPDATE_CALENDAR_TASK_TYPE,
        taskType,
    }
}

export const updateCalendarValue = (key, value) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'calendar',
        key,
        value,
    }
}

export const updateTasksFilter = (key, value) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'tasks',
        key,
        value,
    }
}

export const updateTasks = (tasks) => {
    return {
        type: actionTypes.UPDATE_TASKS,
        tasks,
    }
}

export const newAddNote = () => {
    return {
        type: actionTypes.UPDATE_SUBSECTION_KEY_VALUE,
        section: 'job',
        subsection: 'addNote',
        key: 'note',
        value: '',
    }
}

export const updateAddNote = (key, value) => {
    return {
        type: actionTypes.UPDATE_SUBSECTION_KEY_VALUE,
        section: 'job',
        subsection: 'addNote',
        key: key,
        value: value,
    }
}

export const clearAddNote = () => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'job',
        key: 'addNote',
        value: null,
    }
}

export const newCompleteTask = () => {
    return {
        type: actionTypes.NEW_COMPLETE_TASK,
    }
}

export const clearCompleteTask = () => {
    return {
        type: actionTypes.UPDATE_KEY_VALUE,
        key: 'completeTask',
        value: null,
    }
}

export const updateCompleteTask = (key, value) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'completeTask',
        key,
        value,
    }
}

export const editExistingCompleteTask = () => {
    return {
        type: actionTypes.EDIT_EXISTING_COMPLETE_TASK,
    }
}

export const searchCustomers = (searchString) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'customer',
        key: 'searchString',
        value: searchString,
    }
}

export const listCustomers = (customers) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'customer',
        key: 'list',
        value: customers,
    }
}

export const updateNewJob = (key, value) => {
    return {
        type: actionTypes.UPDATE_SUBSECTION_KEY_VALUE,
        section: 'job',
        subsection: 'new',
        key: key,
        value: value,
    }
}

export const clearNewJob = () => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'job',
        key: 'new',
        value: null,
    }
}

export const addNewCustomer = () => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'customer',
        key: 'new',
        value: {},
    }
}

export const updateNewCustomer = (key, value) => {
    return {
        type: actionTypes.UPDATE_SUBSECTION_KEY_VALUE,
        section: 'customer',
        subsection: 'new',
        key: key,
        value: value,
    }
}

export const clearNewCustomer = () => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'customer',
        key: 'new',
        value: null,
    }
}

export const copyNewJobAddressFromCustomer = () => {
    return {
        type: actionTypes.COPY_NEW_JOB_ADDRESS_FROM_CUSTOMER,
    }
}

export const addRequestForTasks = (personId, status, taskType, callback) => {
    return {
        type: actionTypes.ADD_REQUEST_FOR_TASKS,
        personId,
        status,
        taskType,
        callback,
    }
}

export const addEditJob = () => {
    return {
        type: actionTypes.ADD_EDIT_JOB,
    }
}

export const updateEditJob = (key, value) => {
    return {
        type: actionTypes.UPDATE_SUBSECTION_KEY_VALUE,
        section: 'job',
        subsection: 'edit',
        key: key,
        value: value,
    }
}

export const clearEditJob = () => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'job',
        key: 'edit',
        value: null,
    }
}

export const addEditCustomer = () => {
    return {
        type: actionTypes.ADD_EDIT_CUSTOMER,
    }
}

export const updateEditCustomer = (key, value) => {
    return {
        type: actionTypes.UPDATE_SUBSECTION_KEY_VALUE,
        section: 'customer',
        subsection: 'edit',
        key: key,
        value: value,
    }
}

export const clearEditCustomer = () => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section: 'customer',
        key: 'edit',
        value: null,
    }
}

export const updateReset = (value) => {
    return {
        type: actionTypes.UPDATE_KEY_VALUE,
        key: 'reset',
        value: value,
    }
}

export const rebook = (taskType, taskId, date, jobId, lengthInHours, isDragging = false) => {
    return {
        type: actionTypes.REBOOK,
        taskType,
        taskId,
        date,
        jobId,
        lengthInHours,
        isDragging,
    }
}

export const updateFormValue = (parents, key, value) => {
    return {
        type: actionTypes.UPDATE_FORM_KEY_VALUE,
        parents,
        key,
        value,
    }
}

export const updateKeyValue = (key, value) => {
    return {
        type: actionTypes.UPDATE_KEY_VALUE,
        key,
        value,
    }
}

export const updateSectionKeyValue = (section, key, value) => {
    return {
        type: actionTypes.UPDATE_SECTION_KEY_VALUE,
        section,
        key,
        value,
    }
}

export const updateSubsectionKeyValue = (section, subsection, key, value) => {
    return {
        type: actionTypes.UPDATE_SUBSECTION_KEY_VALUE,
        section,
        subsection,
        key,
        value,
    }
}
