import React, { Component } from 'react'
import { connect } from 'react-redux'
import {DropdownButton, Glyphicon, MenuItem} from 'react-bootstrap'
import {updateAddBooking, updateSectionKeyValue} from "../state/actions"
import {hasRole} from "../shared/functions"
import {FORMS, MISC_ROLES, TASK_TYPES} from "../shared/data"

class AddRole extends Component {
    constructor(props) {
        super(props)
    }

    render () {
        const {
            roles,
            updateRoles,
        } = this.props

        const taskRoles = TASK_TYPES
            .filter(t => !t.allPeople)
            .map(t => t.name)
            .filter(function (item, pos, self) {
                return self.indexOf(item) === pos;
            })
            .sort((a, b) => (a <= b ? -1 : 1))
            .map(t => ({
                value: t,
                description: t,
            }))

        const formRoles = FORMS.map(f => ({
            value: 'form: ' + f.name,
            description: f.name + ' Form'
        }))

        return (
            <DropdownButton
                id={'role-selector'}
                bsStyle={'default'}
                title={<Glyphicon glyph="plus"/>}
                onSelect={value => {
                    updateRoles([
                        ...roles,
                        value
                    ])
                }}
                noCaret={true}
                style={{"margin": "2px"}}
            >
                {getGroup('Tasks', taskRoles, roles)}
                {getGroup('Forms', formRoles, roles)}
                {getGroup('Misc', MISC_ROLES, roles)}
            </DropdownButton>
        )
    }
}

const getGroup = (title, groupRoles, roles) => {
    const filteredGroupRoles = groupRoles.filter(r => roles.indexOf(r.value) === -1)

    if (filteredGroupRoles.length === 0) {
        return null
    }

    return [
        <MenuItem header={true} key={title}>{title}</MenuItem>,
        ...filteredGroupRoles.map(r => <MenuItem
            key={r.value}
            eventKey={r.value}
        >
            {r.description}
        </MenuItem>)
    ]
}

const mapStateToProps = (state) => {
    return {
        roles: state.addPerson.roles,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateRoles: (value) => {dispatch(updateSectionKeyValue('addPerson', 'roles', value))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRole)
