import React, { Component } from 'react'
import {Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { newAddBooking, updateHover } from '../state/actions.js'
import {
    addRequest,
    populateWeek, rebook,
} from "../state/actions"

class Rubbish extends Component {
    constructor(props) {
        super(props)

        this.delete = this.delete.bind(this);

        this.state = {draggedOver: 0}
    }

    delete() {
        if (!confirm('Are you sure you want to remove this?')) {
            return
        }

        const {
            addRequest,
            populateWeekData,
            weekStartDate,
            rebookTask,
            rebook,
        } = this.props

        addRequest(
            'deleteBooking',
            {
                url: 'booking?taskId=' + rebookTask.taskId,
                method: 'DELETE',
            },
            () => {
                addRequest(
                    'week-' + weekStartDate,
                    {
                        url: 'booking?weekStart='+weekStartDate,
                        method: 'GET',
                    },
                    body => {
                        populateWeekData(weekStartDate, body)
                    }
                )
                rebook()
            }
        )
    }

    render () {
        const { rebookTask } = this.props

        if (!rebookTask || !rebookTask.isDragging) return null

        return (
            <Button
                bsSize={'large'}
                bsStyle={'danger'}
                active={this.state.draggedOver > 0}
                onDragEnter={e =>{
                    this.setState({draggedOver: this.state.draggedOver + 1})
                    e.preventDefault()
                }}
                onDragLeave={e =>{
                    this.setState({draggedOver: this.state.draggedOver - 1})
                    e.preventDefault()
                }}
                onDragOver={e => e.preventDefault()}
                onDrop={this.delete}
                >
                <i
                    className={'fa fa-trash'}
                    style={{fontSize: "48px"}}

                />
            </Button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        weekStartDate: state.calendar.weekStartDate,
        rebookTask: state.calendar.rebookTask,
    }
}

const mapDispatchToProps = (dispatch) => {
    return ({
        updateHover: (date, hour) => {
            dispatch(updateHover(date, hour))
        },
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        populateWeekData: (weekStart, bookings) => {dispatch(populateWeek(weekStart, bookings))},
        rebook: (taskType, taskId, date, jobId, lengthInHours) => dispatch(rebook(taskType, taskId, date, jobId, lengthInHours)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Rubbish)
