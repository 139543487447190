import React, {Component} from 'react'
import {CompleteTask, ViewTask} from "./Task"
import { FindCustomerModal, EditCustomerModal, NewCustomerModal } from "./Customer"
import { AddTaskModal, AddNoteModal } from "./Job/View"
import { EditJobModal } from "./Job"
import { AddBookingModal } from "./Calendar"
import TakePhotoModal from "./Job/View/TakePhotoModal"

class Modals extends Component {
    render() {

        return (
            <div>
                <CompleteTask />
                <FindCustomerModal />
                <NewCustomerModal />
                <EditCustomerModal />
                <AddTaskModal />
                <AddNoteModal />
                <AddBookingModal />
                <EditJobModal />
                <ViewTask />
                <TakePhotoModal />
            </div>
        );
    }
}

export default Modals;
