import React, { Component } from 'react'
import { choosePerson } from '../state/actions.js'
import { connect } from 'react-redux'
import {Button, DropdownButton, MenuItem} from 'react-bootstrap'
import {addRequest} from "../state/actions"

class DeleteForm extends Component {
    constructor(props) {
        super(props)
        this.delete = this.delete.bind(this);
    }

    delete() {
        const {
            id,
            formType,
            callback,
            addRequest
        } = this.props

        if (confirm('Delete this form?')) {
            addRequest(
                'delete-form-' + id,
                {
                    url: 'form?id=' + id + '&formType=' + formType,
                    method: 'DELETE',
                },
                callback
            )
        }
    }

    render () {
        const {
            size
        } = this.props

        return (
            <Button bsStyle={'danger'} bsSize={size} onClick={this.delete}>Delete</Button>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
    })
}

export default connect(null, mapDispatchToProps)(DeleteForm)
