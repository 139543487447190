import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, ButtonGroup, Glyphicon } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { updateCalendarJob, updateCalendarTaskType } from '../state/actions.js'

class SelectedJob extends Component {
    render () {
        const { job, updateCalendarJob, updateCalendarTaskType, rebookTask } = this.props

        if (!job || rebookTask) {
            return null
        }

        const jobNumber = 'PL' + (5200000 + job.jobNumber)

        return (
            <ButtonGroup style={{"marginBottom": "5px"}}>
                <Link
                    className="btn btn-success"
                    to={'/job/' + job.jobId}
                    target="_blank"
                >
                    Job: {jobNumber}
                </Link>
                <Button
                    bsStyle="success"
                    onClick={() => {
                        updateCalendarJob(null)
                        updateCalendarTaskType(null)
                    }}
                >
                    <Glyphicon glyph={'remove'} />
                </Button>
            </ButtonGroup>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        job: state.calendar.job,
        rebookTask: state.calendar.rebookTask,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateCalendarJob: (job) => {dispatch(updateCalendarJob(job))},
        updateCalendarTaskType: (taskType) => {dispatch(updateCalendarTaskType(taskType))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedJob)
