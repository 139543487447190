import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateJobsFilter } from "../state/actions"
import { Button, Glyphicon } from "react-bootstrap"

class NoActiveTasks extends Component {
    render () {
        const { noActiveTasks, updateJobsFilter } = this.props

        if (!noActiveTasks && noActiveTasks !== false) {
            return null
        }

        return (
            <Button
                bsStyle={'primary'}
                id={'jobs-noActiveTasks-selector'}
                onClick={() => {
                    updateJobsFilter(null)
                }}
                >
                No Active Tasks
                &nbsp;
                <Glyphicon glyph={'remove'} />
            </Button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        noActiveTasks: state.job.noActiveTasks,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateJobsFilter: (noActiveTasks) => {dispatch(updateJobsFilter('noActiveTasks', noActiveTasks))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(NoActiveTasks)
