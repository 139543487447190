import React, { Component } from 'react'
import { connect } from 'react-redux'
import {addRequest, clearEditCustomer, populateJob, updateEditCustomer} from "../../state/actions"
import { CustomerForm } from ".."
import {Button, ButtonGroup, Modal} from "react-bootstrap"
import {cleanUpAddress} from "../../shared/functions"

class EditCustomerModal extends Component {
    constructor(props) {
        super(props)

        this.edit = this.edit.bind(this);
        this.close = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    edit() {

        const {
            addRequest,
            clearEditCustomer,
            customer,
            populateJob,
            job,
        } = this.props

        const body = {
            ...customer,
            address: cleanUpAddress(customer.address)
        }

        addRequest(
            'editCustomer',
            {
                url: 'customer/' + customer.customerId,
                method: 'PATCH',
                body: body,
            },
            () => {
                addRequest(
                    'job-' + job.jobId,
                    {
                        url: 'job/' + job.jobId,
                        method: 'GET',
                    },
                    body => populateJob(body)
                )

                clearEditCustomer()
            }
        )
    }

    close() {
        this.props.clearEditCustomer()
    }

    handleChange(e) {
        this.props.updateEditCustomer(e.target.id, e.target.value);
    }

    render () {
        const {
            customer,
        } = this.props

        if (!customer)
            return null

        return <Modal show={true} onHide={this.close}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomerForm
                    handleChange={this.handleChange}
                    customer={customer}
                />
            </Modal.Body>
            <Modal.Footer style={{textAlign: "center"}}>
                <ButtonGroup bsSize="large">
                    <Button onClick={this.close}>Close</Button>
                    <Button onClick={this.edit} bsStyle="primary">Update</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    }
}

const mapStateToProps = (state) => {
    return {
        customer: state.customer.edit,
        job: state.job.job,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        clearEditCustomer: () => {dispatch(clearEditCustomer())},
        updateEditCustomer: (key, value) => {dispatch(updateEditCustomer(key, value))},
        populateJob: (job) => {dispatch(populateJob(job))},
    })
}


export default connect(mapStateToProps, mapDispatchToProps)(EditCustomerModal)
