import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup, Glyphicon,
    Modal
} from 'react-bootstrap'
import {addRequest, populatePeople, updateKeyValue, updateSectionKeyValue} from "../state/actions"
import AddRole from "./AddRole"
import {MISC_ROLES} from "../shared/data"

class AddPersonModal extends Component {

    constructor(props) {
        super(props)

        this.close = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.update = this.update.bind(this);
    }

    close() {
        this.props.clearModal()
    }

    update() {
        const {
            addRequest,
            addPerson,
            populatePeople,
            clearModal,
        } = this.props

        addRequest(
            'updateUser' + addPerson.id,
            {
                url: 'user/' + addPerson.userName.toLowerCase(),
                method: 'POST',
                body: {
                    ...addPerson,
                    id: addPerson.id.toUpperCase(),
                    userName: addPerson.userName.toLowerCase()
                },
            },
            () => {
                addRequest(
                    'updatePeople',
                    {
                        url: 'user',
                        method: 'GET',
                    },
                    body => {
                        populatePeople(body)
                        clearModal()
                    }
                )
            }
        )
    }

    handleChange(e) {
        this.props.updateContent(e.target.id, e.target.value);
    }

    render () {
        const {
            addPerson,
            updateContent,
        } = this.props

        if (!addPerson)
            return null

        return (
            <Modal show={true} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>{addPerson.id ? 'Update' : 'Add'} person</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal>
                        <FormGroup>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Name</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="input"
                                    id="name"
                                    onChange={this.handleChange}
                                    value={addPerson.name}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Username</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="input"
                                    id="userName"
                                    onChange={this.handleChange}
                                    value={addPerson.userName}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Enabled</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="select"
                                    id="assignable"
                                    onChange={this.handleChange}
                                    value={addPerson.assignable}
                                >
                                    <option value={true} key={true}>Yes</option>
                                    <option value={false} key={false}>No</option>
                                </FormControl>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Email address</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="input"
                                    id="email"
                                    onChange={this.handleChange}
                                    value={addPerson.email}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col xs={12} sm={3}>
                                <ControlLabel>Roles</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                {addPerson.roles.map(role => {
                                    let description = role
                                    let colour = "success"
                                    let sort = 1

                                    const miscRole = MISC_ROLES.find(r => r.value === role)

                                    if (role.startsWith("form")) {
                                        description = role.replace(/form: (.+)/, "$1 Form")
                                        colour = "primary"
                                        sort = 2
                                    } else if (miscRole) {
                                        description = miscRole.description
                                        colour = "warning"
                                        sort = 3
                                    }

                                    return {
                                        value: role,
                                        colour,
                                        description,
                                        sort,
                                    }
                                })
                                    .sort((a, b) => ((a.sort + a.description) <= (b.sort + b.description) ? -1 : 1))
                                    .map(role =>  <Button
                                        bsStyle={role.colour}
                                        onClick={() => {
                                            updateContent('roles', addPerson.roles.filter(r => r !== role.value))
                                        }}
                                        style={{"margin": "2px"}}
                                        key={role.value}
                                    >
                                        {role.description}
                                        &nbsp;
                                        <Glyphicon glyph={'remove'} />
                                    </Button>
                                )}
                                <AddRole />
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{textAlign: "center"}}>
                    <ButtonGroup bsSize="large">
                        <Button onClick={this.close}>Close</Button>
                        <Button onClick={this.update} bsStyle="primary">{addPerson.id ? 'Update' : 'Add'}</Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addPerson: state.addPerson,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        clearModal: () => {dispatch(updateKeyValue('addPerson', null))},
        updateContent: (key, value) => {dispatch(updateSectionKeyValue('addPerson', key, value))},
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        populatePeople: (people) => {dispatch(populatePeople(people))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPersonModal)
