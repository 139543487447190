import React, { Component } from 'react'
import {Button, ButtonGroup, Glyphicon, OverlayTrigger, Popover} from 'react-bootstrap'
import {imageExtensions} from "../shared/data"
import {
    addRequest,
} from "../state/actions"
import {connect} from "react-redux"

class AttachmentButton extends Component {

    constructor(props) {
        super(props)

        this.deleteAttachment = this.deleteAttachment.bind(this)

        this.state = {deleted: false}
    }

    deleteAttachment() {
        const {
            name,
            attachmentKey,
            addRequest,
        } = this.props

        if (confirm("Delete attachment " + name + "?")) {
            addRequest(
                'deleteAttachment' + name,
                {
                    url: 'attachment/' + attachmentKey,
                    method: 'DELETE',
                },
                () => {
                    this.setState({deleted: true})
                }
            )
        }
    }

    render () {
        if (this.state.deleted) {
            return null
        }

        const {
            name,
            url,
            attachmentKey,
        } = this.props

        return (
                <ButtonGroup style={{marginRight: "5px"}}>
                    {imageExtensions.find(i => name.toLowerCase().endsWith(i)) ?
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={<Popover id={attachmentKey}><img src={url} alt={name} style={{maxWidth: "250px"}} /></Popover>}
                        placement={"bottom"}>
                        <Button href={url} target="_blank" onClick={e => e.stopPropagation()}>
                            <Glyphicon glyph={"picture"} />
                            &nbsp;
                            {decodeURI(name)}
                        </Button>
                    </OverlayTrigger>
                    : <Button href={url} target="_blank" onClick={e => e.stopPropagation()}>
                            <Glyphicon glyph={"file"} />
                            &nbsp;
                            {decodeURI(name)}
                        </Button> }
                    <Button bsStyle="danger" onClick={e => {e.stopPropagation(); this.deleteAttachment()}}>
                        <Glyphicon glyph="remove" />
                    </Button>
                </ButtonGroup>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
    })
}

export default connect(null, mapDispatchToProps)(AttachmentButton)
