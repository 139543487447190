import React, { Component } from 'react'
import {FORM_FIELD_TYPES} from "../shared/data"
import {Document, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer"

class PdfForm extends Component {
    constructor(props) {
        super(props)

        this.styles = StyleSheet.create({
            mainTable: {
            },
            table: {
                display: "table",
                width: "auto",
                borderColor: '#aaa',
                borderWidth: 1,
                borderRightWidth: 0,
                borderBottomWidth: 0
            },
            tr: {
                margin: 0,
                flexDirection: "row",
                borderBottomWidth: 1,
                borderColor: '#aaa',
            },
            thead: {
                borderWidth: '2px'
            },
            th: {
                color: '#333',
                backgroundColor: '#f5f5f5',
                borderColor: '#aaa',
                borderRightWidth: 1,
                width: '50%',
            },
            td: {
                color: '#333',
                borderColor: '#aaa',
                borderRightWidth: 1,
                width: '50%',
            },
            topTd: {
                color: '#333',
                borderColor: '#aaa',
                width: '50%',
            },
            text: {
                margin: 2,
                fontSize: 10,
                textAlign: 'left',
            },
            panel: {
                paddingTop: '5px',
            },
            panelHeading: {
            },
            panelHeadingText: {
                fontSize: 12,
                fontWeight: 'bold',
                textAlign: 'left',
            },
            panelBody: {

            },
        });
    }

    getFormValue(parents, fieldName) {
        let currentParent = this.props.form

        parents.forEach(parent => {
            if (!currentParent) {
                return ''
            }

            currentParent = currentParent[parent]
        })

        if (!currentParent) {
            return ''
        }

        return currentParent[fieldName] !== undefined ? currentParent[fieldName] : ''
    }

    mapField(parents, fieldName, fieldValue) {
        const id = [...parents, fieldName].join('-').replace(/[^a-zA-Z-]/g, '')
        const value = this.getFormValue(parents, fieldName)


        switch (typeof fieldValue) {
            case 'string':
                const tdStyle = parents.length === 0 ? this.styles.topTd : this.styles.td

                switch (fieldValue) {
                    case 'boolean' :
                        return <View style={this.styles.tr} key={id}>
                            <View style={tdStyle}><Text style={this.styles.text}>{fieldName}</Text></View>
                            <View style={tdStyle}><Text style={this.styles.text}>{value ? 'Yes' : 'No'}</Text></View>
                        </View>
                    case 'string' :
                    case 'text':
                        return <View style={this.styles.tr} key={id}>
                            <View style={tdStyle}><Text style={this.styles.text}>{fieldName}</Text></View>
                            <View style={tdStyle}><Text style={this.styles.text}>{value}</Text></View>
                        </View>
                    case 'date' :
                        return <View style={this.styles.tr} key={id}>
                            <View style={tdStyle}><Text style={this.styles.text}>{fieldName}</Text></View>
                            <View style={tdStyle}><Text style={this.styles.text}>
                                {(value ? new Date(value) : new Date()).toLocaleDateString()}
                            </Text></View>
                        </View>
                    default:
                        if (FORM_FIELD_TYPES[fieldValue]) {
                            return this.mapField(parents, fieldName, FORM_FIELD_TYPES[fieldValue])
                        } else {
                            return null
                        }
                }

            case 'object':
                const childParents = [...parents, fieldName]
                const expanded = this.getFormValue(childParents, 'value')

                if (fieldValue.expandable) {
                    if (expanded) {
                        return <tbody style={this.styles.thead} key={id}>
                        <View style={this.styles.tr}>
                            <View style={this.styles.th}><Text style={this.styles.text}>{fieldName}</Text></View>
                            <View style={this.styles.th}><Text style={this.styles.text}>Yes</Text></View>
                        </View>
                        {this.mapObject(childParents, fieldValue)}
                        </tbody>
                    } else {
                        return <tbody key={fieldName}>
                        <View style={this.styles.tr}>
                            <View width={'50%'} style={this.styles.th}><Text style={this.styles.text}>{fieldName}</Text></View>
                            <View style={this.styles.td}><Text style={this.styles.text}>No</Text></View>
                        </View>
                        </tbody>
                    }
                } else {
                    return <View style={this.styles.panel} key={id}>
                        <View style={this.styles.panelHeading}>
                            <Text style={this.styles.panelHeadingText}>{fieldName}</Text>
                        </View>
                        <View style={this.styles.panelBody}>
                            <View width={'100%'} border={'1px'} style={this.styles.table}>
                                {this.mapObject(childParents, fieldValue)}
                            </View>
                        </View>
                    </View>
                }

            default:
                return null
        }
    }

    mapObject(parents, object) {
        return Object.keys(object)
            .filter(fieldName => fieldName !== 'expandable')
            .map(fieldName => this.mapField(parents, fieldName, object[fieldName]))
    }

    render () {
        const {
            formType,
            form,
        } = this.props

        const rows = <View style={this.styles.mainTable}>{this.mapObject([], formType.fields)}</View>

        const signatures = form.signatures ? form.signatures.map(s => <View style={this.styles.tr} key={s.name}>
            <View style={this.styles.td}>
                <Text style={this.styles.text}>{s.name}</Text>
            </View>
            <View style={{
                borderColor: '#aaa',
                borderRightWidth: 1,
                width: '50%',
            }}>
                <Image style={{width: '50%', padding: '2'}} src={s.signature} />
            </View>
        </View>) : null

        return (
            <Document>
                <Page size="A4" style={{padding:20}}>
                    <View>
                        <Text>{formType.name}</Text>
                    </View>
                    {rows}
                    {formType.signature ? <View style={this.styles.panel} key={'signature'}>
                        <View style={this.styles.panelBody} wrap={false}>
                            <Text style={this.styles.text}>{formType.signature.statement}</Text>
                            <View width={'100%'} border={'1px'} style={this.styles.table}>
                                <View style={this.styles.tr}>
                                    <View style={this.styles.th}><Text style={this.styles.text}>Name</Text></View>
                                    <View style={this.styles.th}><Text style={this.styles.text}>Signed</Text></View>
                                </View>
                                {signatures}
                            </View>
                        </View>
                    </View> : null}
                </Page>
            </Document>
        )
    }
}

export default PdfForm
