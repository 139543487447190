import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import { Bookings, Tasks } from '.'
import {connect} from "react-redux"
import { addRequestForTasks, updateTasks } from '../state/actions.js'
import FormList from "../Form/FormListSection"

class HomePage extends Component {
    UNSAFE_componentWillMount() {
        this.requestTasks(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.requestTasks(nextProps)
    }

    requestTasks(props) {
        const {
            userId,
            addRequestForTasks,
            updateTasks,
        } = props

        addRequestForTasks(
            userId,
            'Open',
            null,
            body => updateTasks(body),
        )
    }

    render () {
        const {
            userId,
        } = this.props

        return (
            <div>
                <Panel style={{maxWidth: '500px'}}>
                    <Panel.Heading>
                        Today
                    </Panel.Heading>
                    <Panel.Body>
                        <Bookings />
                    </Panel.Body>
                </Panel>
                <Panel>
                    <Panel.Heading>
                        To do
                    </Panel.Heading>
                    <Panel.Body>
                        <Tasks />
                    </Panel.Body>
                </Panel>
                <FormList personId={userId} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.userId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateTasks: tasks => {dispatch(updateTasks(tasks))},
        addRequestForTasks: (personId, status, taskType, callback) => {dispatch(addRequestForTasks(personId, status, taskType, callback))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
