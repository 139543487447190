import { Component } from 'react'
import { setConfig } from '../state/actions.js'
import { connect } from 'react-redux'
import { localConfig } from './localConfig.js'

class ConfigLoader extends Component {
    render() {
        const { setStateConfig, local } = this.props

        if (local) {
            setStateConfig(localConfig)    
        } else {
            setStateConfig(window.config)
        }

        return null
    }
}

const mapStateToProps = (state) => {
    return {
        local: state.local,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        setStateConfig: (config) => {dispatch(setConfig(config))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigLoader)
