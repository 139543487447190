import React, { Component } from 'react'
import {Day, DayPerson, Header} from '.'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import {updateHover} from "../state/actions"
import {getPersonById} from "../state/reducer"

class Calendar extends Component {

    render () {
        const {
            weekStartDate,
            people,
            updateHover,
            assignablePeople,
            dayStartHour,
            dayEndHour,
            displayWeeks,
        } = this.props

        if (!people || people.length === 0) {
            return null
        }
        const alwaysDisplayPersonId = people[0];

        if (people.length === 1) {
            const personId = people[0]

            let hours = []

            for (let i=dayStartHour; i<dayEndHour; i++) {
                hours.push(<tr
                    key={i}
                >
                    <td>{moment([1, 1, 1, i]).format('HH:mm')}</td>
                </tr>)
            }

            const weeks = []

            for (let week=0; week<displayWeeks; week++) {
                const dates = []
                const thisWeekStart =  moment(weekStartDate).utc().add(week, 'weeks').toJSON()

                for (let i = 0; i<7; i++) {
                    dates.push(moment(thisWeekStart).add(i, 'days'))
                }

                const days = dates.map(date => {
                    return (
                        <DayPerson
                            key={personId + date}
                            date={date}
                            personId={personId}
                            alwaysDisplay={true}
                        />
                    )
                })

                weeks.push(<tr key={thisWeekStart}>
                    <td style={{minWidth: '126px'}}>
                        <div className="col-xs-6">
                            Week starting
                            <br/>
                            {moment(weekStartDate).add(week, 'weeks').format("MMM Do")}
                        </div>
                        <Table striped condensed className="pull-right hour-table hour-table-times col-xs-6">
                            <tbody>
                            {hours}
                            </tbody>
                        </Table>
                    </td>
                    {days}
                </tr>)
            }

            return (
                <Table bordered condensed className="calendar-main-grid" onMouseLeave={updateHover}>
                    <thead>
                        <tr>
                            <th><h4>{getPersonById(assignablePeople, personId).name}</h4></th>
                            <th><h5>Monday</h5></th>
                            <th><h5>Tuesday</h5></th>
                            <th><h5>Wednesday</h5></th>
                            <th><h5>Thursday</h5></th>
                            <th><h5>Friday</h5></th>
                            <th><h5>Saturday</h5></th>
                            <th><h5>Sunday</h5></th>
                        </tr>
                    </thead>
                    <tbody>
                        {weeks}
                    </tbody>
                </Table>
            )
        } else {
            const dates = []

            for (let i = 0; i<(7*displayWeeks); i++) {
                dates.push(moment(weekStartDate).add(i, 'days'))
            }

            const days = dates.map(date => {
                return (
                    <Day
                        key={date.format('X')}
                        date={date}
                        people={people}
                        alwaysDisplayPersonId={alwaysDisplayPersonId}
                    />
                )
            })

            const renderPeople = people.map(personId => (
                    <Header
                        key={personId}
                        personId={personId}
                        alwaysDisplay={alwaysDisplayPersonId === personId}
                    />
                )
            )

            return (
                <Table bordered condensed className="calendar-main-grid" onMouseLeave={updateHover}>
                    <thead>
                    <tr>
                        <th>&nbsp;</th>
                        {renderPeople}
                    </tr>
                    </thead>
                    <tbody>
                        {days}
                    </tbody>
                </Table>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        people: state.calendar.people,
        weekStartDate: state.calendar.weekStartDate,
        assignablePeople: state.assignablePeople,
        dayStartHour: state.calendar.dayStartHour,
        dayEndHour: state.calendar.dayEndHour,
        displayWeeks: state.calendar.displayWeeks,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateHover: () => {dispatch(updateHover(null, null))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
