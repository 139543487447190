import React, { Component } from 'react'
import { Button, ButtonGroup, DropdownButton, Glyphicon, MenuItem } from 'react-bootstrap'

class Selector extends Component {
    render () {
        const {
            id,
            items,
            selectedStyle = 'primary',
            selectedId,
            bsStyle = 'info',
            title,
            valueUpdated,
            valueUpdatedId = id,
            prefixTitle = true,
            closeButton = true,
            multiSelect = false,
            hideIfNullValue = false,
            noCaret = false,
            pullRight = false,
        } = this.props

        if (items === null || items.length === 0
            || (hideIfNullValue && !selectedId && selectedId !== false)) {
            return null;
        }
        
        let selectedIds = multiSelect ? selectedId : [ selectedId ]

        const selectedItems = items
            .filter(i => selectedIds.indexOf(i.id) !== -1)

        let displayTitle = title

        if (selectedItems.length > 0) {
            const value = selectedItems.map(i => i.value).join(', ')
            displayTitle = prefixTitle ? title + ': ' + value : value
        }

        const useStyle = selectedItems.length > 0 ? selectedStyle : bsStyle
        
        const checkmark = <Glyphicon glyph={'ok'} />
        
        const options = items
            .map((i) => {
                const selected = selectedIds.indexOf(i.id) !== -1;
                return <MenuItem
                    key={i.id}
                    onClick={() => {
                        if (multiSelect) {
                            let newValue
                            if (selected) {
                                newValue = selectedIds.filter(s => s !== i.id)
                            } else {
                                newValue = [...selectedIds, i.id]
                            }
                            valueUpdated(valueUpdatedId, newValue)
                        } else {
                            valueUpdated(valueUpdatedId, i.id)
                        }
                    }}
                    active={selected}
                >
                    {i.value} {selected ? checkmark : null }
                </MenuItem>
    })

        let clearButton = null

        if (selectedItems.length > 0 && closeButton) {
            clearButton = (
                <Button
                    bsStyle={useStyle}
                    onClick={() => {
                        valueUpdated(valueUpdatedId, null)
                    }}
                >
                    <Glyphicon glyph={'remove'} />
                </Button>
            )
        }

        return (
            <ButtonGroup>
                <DropdownButton
                    type={'button'}
                    bsStyle={useStyle}
                    title={displayTitle}
                    id={id}
                    noCaret={noCaret}
                    pullRight={pullRight}
                >
                    {options}
                </DropdownButton>
                {clearButton}
            </ButtonGroup>
        )
    }
}

export default Selector
