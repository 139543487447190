import React, { Component } from 'react'
import { updateTasksFilter } from '../state/actions.js'
import { connect } from 'react-redux'
import { Selector } from "../components"
import {TASK_STATES} from "../shared/data"

class Status extends Component {
    render () {
        const { status, updateTasksFilter } = this.props

        const options = Object.values(TASK_STATES)
            .map(state => ({
                    id: state,
                    value: state,
                }))

        return (
            <Selector
                id={'tasks-status-selector'}
                items={options}
                selectedId={status}
                title={'Status'}
                valueUpdated={updateTasksFilter}
                valueUpdatedId={'status'}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.tasks.status,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateTasksFilter: (key, value) => {dispatch(updateTasksFilter(key, value))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Status)
