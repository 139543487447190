import React, { Component } from 'react'
import { connect } from 'react-redux'
import {addRequest, clearNewCustomer, updateNewCustomer, updateNewJob} from "../../state/actions"
import { CustomerForm } from ".."
import {Button, ButtonGroup, Modal} from "react-bootstrap"
import {cleanUpAddress} from "../../shared/functions"

class NewCustomerModal extends Component {
    constructor(props) {
        super(props)

        this.add = this.add.bind(this);
        this.close = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    add() {

        const {
            addRequest,
            clearNewCustomer,
            customer,
            updateNewJob,
        } = this.props

        const body = {
            ...customer,
            address: cleanUpAddress(customer.address)
        }

        addRequest(
            'addCustomer',
            {
                url: 'customer',
                method: 'POST',
                body: body,
            },
            body => {
                updateNewJob({
                    customerId: body.customerId,
                    ...customer,
                })

                clearNewCustomer()
            }
        )
    }

    close() {
        this.props.clearNewCustomer()
    }

    handleChange(e) {
        this.props.updateNewCustomer(e.target.id, e.target.value);
    }

    render () {
        const {
            customer,
        } = this.props

        if (!customer)
            return null

        return <Modal show={true} onHide={this.close}>
            <Modal.Header closeButton>
                <Modal.Title>Add Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomerForm
                    handleChange={this.handleChange}
                    customer={customer}
                />
            </Modal.Body>
            <Modal.Footer style={{textAlign: "center"}}>
                <ButtonGroup bsSize="large">
                    <Button onClick={this.close}>Close</Button>
                    <Button onClick={this.add} bsStyle="primary">Add</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    }
}

const mapStateToProps = (state) => {
    return {
        customer: state.customer.new,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        clearNewCustomer: () => {dispatch(clearNewCustomer())},
        updateNewCustomer: (key, value) => {dispatch(updateNewCustomer(key, value))},
        updateNewJob: (customer) => {dispatch(updateNewJob('customer', customer))},
    })
}


export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerModal)
