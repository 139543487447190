import React, { Component } from 'react'
import {
    Col,
    Row,
    Grid,
    Panel,
    FormControl,
    ButtonGroup,
    Button,
    Glyphicon,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap'
import {connect} from "react-redux"
import { addRequest, updateFormValue } from '../state/actions.js'
import DatePicker from "react-datepicker"
import {FORM_FIELD_TYPES} from "../shared/data"
import PanelBody from "react-bootstrap/lib/PanelBody"
import PanelHeading from "react-bootstrap/lib/PanelHeading"
import SignatureModal from "./SignatureModal"
import PdfForm from "./PdfForm"
import {pdf} from "@react-pdf/renderer"
import {put} from "axios"
import {withRouter} from "react-router-dom"


class Form extends Component {
    constructor(props) {
        super(props)

        this.complete = this.complete.bind(this);
        this.mapField = this.mapField.bind(this);
        this.mapObject = this.mapObject.bind(this);
        this.getFormValue = this.getFormValue.bind(this);
        this.closeSignatureModal = this.closeSignatureModal.bind(this);

        this.state = {
            signatureModalOpen: false,
            job: null,
            task: null
        }
    }

    componentDidMount() {
        const {
            jobId,
            taskId,
            addRequest,
            updateFormValue,
        } = this.props

        if (taskId) {
            addRequest(
                'task-' + taskId,
                {
                    url: 'task?taskId=' + taskId,
                    method: 'GET',
                },
                body => {
                    this.setState({ task: body[0]})
                    updateFormValue([], 'Date', new Date(body[0].bookings[0].date))
                }
            )
        } else if (jobId) {
            addRequest(
                'job-' + jobId,
                {
                    url: 'job/' + jobId,
                    method: 'GET',
                },
                body => {
                    this.setState({ job: body})
                    updateFormValue([], 'Date', new Date())
                }
            )
        } else {
            updateFormValue([], 'Date', new Date())
        }
    }

    closeSignatureModal() {
        this.setState({signatureModalOpen: false})
    }

    complete() {
        const {
            jobId,
            taskId,
            formType,
            form,
            history,
            addRequest,
        } = this.props

        const formDate = this.getFormValue([], 'Date').toISOString().slice(0, 10);
        const url = 'attachment/' + jobId + (taskId ? '/' + taskId : '') + '/' + formType.name + '-' + formDate + '.pdf'

        const doc = (<PdfForm jobId={jobId} taskId={taskId} formType={formType} form={form} />)

        pdf(doc).toBlob().then(blob => {
            addRequest(
                url,
                {
                    url,
                    method: 'POST',
                },
                body => {
                    put(
                        body.url,
                        blob,
                        {
                            headers: {
                                'content-type': 'application/pdf',
                            }
                        }
                    )
                        .then(() => {
                            addRequest(
                                'delete-form-' + jobId + '-' + taskId,
                                {
                                    url: 'form?id=' + (taskId ? taskId : jobId) + '&formType=' + formType.name,
                                    method: 'DELETE',
                                },
                                () => {
                                    history.push('/job/' + jobId)
                                }
                            )
                        })
                })
        })
    }

    getFormValue(parents, fieldName) {
        let currentParent = this.props.form

        parents.forEach(parent => {
            if (!currentParent) {
                return ''
            }

            currentParent = currentParent[parent]
        })

        if (!currentParent) {
            return ''
        }

        return currentParent[fieldName] !== undefined ? currentParent[fieldName] : ''
    }

    mapField(parents, fieldName, fieldValue) {
        const id = [...parents, fieldName].join('-').replace(/[^a-zA-Z-]/g, '')
        const value = this.getFormValue(parents, fieldName)

        const {
            updateFormValue,
        } = this.props

        switch (typeof fieldValue) {
            case 'string':
                switch (fieldValue) {
                    case 'boolean' :
                        return createRow(id, fieldName,
                                <ButtonGroup>
                                    <Button id={id} bsSize={'small'} bsStyle={value === true ? 'success' : 'default'} onClick={() => updateFormValue(parents, fieldName, true)}>Yes</Button>
                                    <Button id={id} bsSize={'small'} bsStyle={value === false ? 'danger' : 'default'} onClick={() => updateFormValue(parents, fieldName, false)}>No</Button>
                                </ButtonGroup>);
                    case 'string' :
                        return createRow(id, fieldName,
                            <FormControl
                                id={id}
                                type="text"
                                onChange={e => updateFormValue(parents, fieldName, e.target.value)}
                                value={value}
                            />);
                    case 'date' :
                        return createRow(id, fieldName,
                            <DatePicker
                                id={id}
                                selected={value ? new Date(value) : new Date()}
                                onChange={v => updateFormValue(parents, fieldName, v)}
                                dateFormat="dd/MM/yyyy"
                                todayButton={'Today'}
                                showPopperArrow={false}
                            />);
                    case 'text':
                        return createRow(id, fieldName,
                            <FormControl
                                id={id}
                                componentClass="textarea"
                                onChange={e => updateFormValue(parents, fieldName, e.target.value)}
                                value={value}
                            />);
                    default:
                        if (FORM_FIELD_TYPES[fieldValue]) {
                            return this.mapField(parents, fieldName, FORM_FIELD_TYPES[fieldValue])
                        } else {
                            return null
                        }
                }

            case 'object':
                const childParents = [...parents, fieldName]
                const expanded = this.getFormValue(childParents, 'value')

                return <Panel key={id} className={expanded === true ? 'panel-success' : (expanded === false ? 'panel-danger' : 'panel-default')}>
                    <Panel.Heading style={{height: '50px'}}>

                        {fieldValue.helpText ?
                            <OverlayTrigger placement="right" overlay={
                                <Tooltip id={"tooltip-" + id}>
                                {fieldValue.helpText}
                                </Tooltip>}>
                                <span>
                                    {fieldName}
                                    &nbsp;
                                    <Glyphicon glyph={'question-sign'} />
                                </span>
                            </OverlayTrigger>
                            : fieldName}
                        { fieldValue.expandable
                            ? <ButtonGroup className="pull-right">
                                <Button id={id} bsSize={'small'} bsStyle={expanded === true ? 'success' : 'default'} onClick={() => updateFormValue(childParents, 'value', true)}>Yes</Button>
                                <Button id={id} bsSize={'small'} bsStyle={expanded === false ? 'danger' : 'default'} onClick={() => updateFormValue(childParents, 'value', false)}>No</Button>
                            </ButtonGroup>
                            : null }
                    </Panel.Heading>
                    {!fieldValue.expandable || expanded ?
                    <Panel.Body>
                        <Grid fluid>
                            {this.mapObject(childParents, fieldValue)}
                        </Grid>
                    </Panel.Body>
                        : null}
                </Panel>

            default:
                return null
        }
    }

    mapObject(parents, object) {
        return Object.keys(object)
            .filter(fieldName => fieldName !== 'expandable')
            .map(fieldName => this.mapField(parents, fieldName, object[fieldName]))
    }

    render () {
        const {
            formType,
            savedForm,
            form,
            updateFormValue,
            people,
            jobId
        } = this.props

        const {
            job,
            task,
        } = this.state

        const summary = []

        if (task) {
            summary.push(createRow('customerName', <b>Customer name</b>, task.customer));
            summary.push(createRow('description', <b>Job description</b>, task.description));
            summary.push(createRow('address', <b>Job address</b>, task.address));
            summary.push(createRow('type', <b>Task type</b>, task.type));
            summary.push(createRow('assignees', <b>People</b>, task.assignees
                .map(personId => people.find(p => (p.id === personId)))
                .filter(p => (p))
                .map(p => p.name)
                .sort()
                .join(', ')));
        } else if (job) {
            summary.push(createRow('customerName', <b>Customer name</b>, job.customer.name));
            summary.push(createRow('description', <b>Job description</b>, job.description));
            summary.push(createRow('address', <b>Job address</b>, job.address));
        }

        const rows = this.mapObject([], formType.fields)

        const signatures = (form && form.signatures) ? form.signatures.map(s => <Panel key={s.name}>
                <PanelHeading style={{height: '50px'}}>
                    {s.name}
                    <Button
                        className={'pull-right'}
                        bsStyle={'danger'}
                        bsSize={'sm'}
                        onClick={() => {
                            updateFormValue([], 'signatures', form.signatures.filter(sig => sig !== s))
                        }}
                        >
                        <Glyphicon glyph={'remove'} />
                    </Button>
                </PanelHeading>
                <PanelBody>
                    <img src={s.signature} alt={s.name + "'s signature"} title={s.name + "'s signature"} />
                </PanelBody>
            </Panel>)
            : null

        const signature = formType.signature ? <Panel>
                <PanelHeading>Signature{formType.signature.number === 'multiple' ? 's' : ''}</PanelHeading>
                <PanelBody>
                        {signatures}
                        {formType.signature.number === 'multiple' || !signatures
                            ? <Button bsSize={'large'} bsStyle={'info'} onClick={() => this.setState({signatureModalOpen: true})}>Sign</Button>
                            : null}
                </PanelBody>
            </Panel>
            : null

        return (
            <div>
                <Panel>
                    <Panel.Heading style={{"height": "50px"}}>
                        {task ? 'Task' : 'Job'} Summary
                        <Button
                            href={"/#/job/" + jobId}
                            bsStyle={'info'}
                            bsSize={'small'}
                            className={'pull-right'}
                            target={'_blank'}>View job <Glyphicon glyph={'new-window'} /></Button>
                    </Panel.Heading>
                    <Panel.Body>
                        <Grid fluid>
                            {summary}
                        </Grid>
                    </Panel.Body>
                </Panel>
                <Panel>
                    <Panel.Heading>
                        {formType.name}
                    </Panel.Heading>
                    <Panel.Body>
                        <Grid fluid>
                            {rows}
                            {signature}
                        </Grid>
                    </Panel.Body>
                    {this.state.signatureModalOpen && <SignatureModal close={this.closeSignatureModal} statement={formType.signature.statement} />}
                </Panel>
                <Button bsStyle={'info'} bsSize={'large'} onClick={this.complete}>Complete form</Button>
            </div>
        )
    }
}

const createRow = (id, name, value) => <Row key={id} style={{paddingBottom: '4px'}}>
    <Col md={3} sm={12}>{name}</Col>
    <Col md={9} sm={12}>{value}</Col>
</Row>

const FormWithRouter = withRouter(Form)

const mapStateToProps = (state) => {
    return {
        form: state.form,
        people: state.people,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        updateFormValue: (parents, key, value) => {dispatch(updateFormValue(parents, key, value))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(FormWithRouter)
