import React, { Component } from 'react'
import { addRequest, clearViewTask, populateWeek, newCompleteTask, editExistingCompleteTask, rebook, newAddBooking, newAddTask } from '../state/actions.js'
import { connect } from 'react-redux'
import {Button, ButtonGroup, Modal, Table} from 'react-bootstrap'
import {Link, withRouter, useLocation} from 'react-router-dom'
import {TASK_STATES} from "../shared/data"
import moment from "moment"
import {getFormType, getHour, getTaskType, getTimeAsNumber} from "../shared/functions"
import {getPersonById} from "../state/reducer"
import DeleteForm from "../Form/DeleteForm"
import AttachmentButton from "../components/AttachmentButton"

class ViewTask extends Component {

    constructor(props) {
        super(props)

        this.close = this.close.bind(this);
        this.deleteTask = this.deleteTask.bind(this);
        this.newCompleteTask = this.newCompleteTask.bind(this);
        this.rebook = this.rebook.bind(this);
        this.getFormDetails = this.getFormDetails.bind(this);
        this.getAttachments = this.getAttachments.bind(this);

        this.state = {}
    }

    close() {
        this.props.clearViewTask()
    }

    componentDidMount() {
        this.getFormDetails(this.props)
        this.getAttachments(this.props)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.getFormDetails(this.props)
            this.getAttachments(this.props)
        }
    }

    getFormDetails(props) {
        const {
            addRequest,
            viewTask,
            completeTask,
        } = props

        if (viewTask && !completeTask) {
            const taskType = getTaskType(viewTask.type)

            if (taskType && taskType.formOnCreate) {

                taskType.formOnCreate.forEach(formTypeName => {
                    const formType = getFormType(formTypeName)
                    const id = formType.job ? viewTask.jobId : viewTask.taskId

                    const formUrl = 'form?'
                        + 'formType=' + formTypeName
                        + '&id=' + id

                    addRequest(
                        'getForms-' + id + formTypeName,
                        {
                            url: formUrl,
                            method: 'GET',
                        },
                        body => {
                            if (body.length === 0) {
                                this.setState({[formTypeName]: null})
                            } else {
                                this.setState({[formTypeName]: body[0]})
                            }
                        }
                    )
                })
            }
        }
    }

    getAttachments(props) {
        const {
            addRequest,
            viewTask,
            completeTask,
        } = props

        if (viewTask && !completeTask) {
            addRequest(
                'task-attachments-' + viewTask.taskId,
                {
                    url: 'attachment?prefix=' + viewTask.jobId + '/' + viewTask.taskId,
                    method: 'GET',
                },
                body => {
                    this.setState({attachments: body})
                }
            )
        }
    }

    newCompleteTask() {
        const {
            newCompleteTask,
            editExistingCompleteTask,
            history,
            location,
            viewTask,
        } = this.props

        if (location.pathname.indexOf('job') === -1) {
            history.push('/job/' + viewTask.jobId)
        }

        if (viewTask.status === TASK_STATES.OPEN && viewTask.type !== 'Note') {
            newCompleteTask()
        } else {
            editExistingCompleteTask()
        }
    }

    deleteTask() {
        if (!confirm('Are you sure you want to remove this?')) {
            return
        }

        const {
            addRequest,
            clearViewTask,
            populateWeekData,
            weekStartDate,
            viewTask,
            location,
        } = this.props

        const taskType = getTaskType(viewTask.type)

        if (taskType && taskType.formOnCreate) {
            taskType.formOnCreate.forEach(formTypeName => {
                const formType = getFormType(formTypeName)

                if (!formType.job) {
                    addRequest(
                        'delete-form-' + viewTask.taskId,
                        {
                            url: 'form?id=' + viewTask.taskId + '&formType=' + formTypeName,
                            method: 'DELETE',
                        },
                        () => {
                            this.deleteBooking(addRequest, viewTask, location, weekStartDate, populateWeekData, clearViewTask)
                        }
                    )
                }
            })
        } else {
            this.deleteBooking(addRequest, viewTask, location, weekStartDate, populateWeekData, clearViewTask)
        }
    }

    deleteBooking(addRequest, viewTask, location, weekStartDate, populateWeekData, clearViewTask) {
        addRequest(
            'deleteBooking',
            {
                url: 'booking?taskId=' + viewTask.taskId
                    + (viewTask.bookingId ? '&taskBookingId=' + viewTask.bookingId : ''),
                method: 'DELETE',
            },
            () => {
                if (location.pathname.indexOf('job') !== -1) {
                    window.location.reload()
                } else {
                    addRequest(
                        'week-' + weekStartDate,
                        {
                            url: 'booking?weekStart=' + weekStartDate,
                            method: 'GET',
                        },
                        body => {
                            populateWeekData(weekStartDate, body)
                        }
                    )
                    clearViewTask()
                }
            }
        )
    }

    rebook () {
        const {
            rebook,
            history,
            location,
            viewTask,
        } = this.props

        const booking = viewTask.bookings[0]

        rebook(
            viewTask.type,
            viewTask.taskId,
            booking.date,
            viewTask.jobId,
            getTimeAsNumber(booking.end) - getTimeAsNumber(booking.start)
        )

        if (location.pathname.indexOf('calendar') === -1) {
            history.push('/calendar')
        }
    }

    static addSection(sections, description, value) {
        if (value)
            sections.push(
                <tr key={description}>
                    <th>{description}</th>
                    <td style={{whiteSpace: 'pre-line'}}>{value}</td>
                </tr>
            )
    }

    render () {
        const addPersonSection = (sections, people, viewTask) => {
            if (viewTask.createdBy) {
                ViewTask.addSection(sections, 'Created by', getPersonById(people, viewTask.createdBy).name + ' (' + moment(viewTask.created).format("L LTS") + ')')
            }

            if (viewTask.created !== viewTask.modified && viewTask.modifiedBy) {
                ViewTask.addSection(sections, 'Modified by', getPersonById(people, viewTask.modifiedBy).name + ' (' + moment(viewTask.modified).format("L LTS") + ')')
            }
        }

        const {
            viewTask,
            clearViewTask,
            completeTask,
            dayStartHour,
            dayEndHour,
            people,
            newBooking,
            newAddTask,
        } = this.props

        if (!viewTask || completeTask) {
            return null
        }

        const sections = []
        const taskType = getTaskType(viewTask.type)

        const noteAttachments =  this.state.attachments ? this.state.attachments
                .map(a => <AttachmentButton
                    key={a.key}
                    attachmentKey={a.key}
                    name={a.name}
                    url={a.url}
                />)
            : []

        let modalBody
        if (viewTask.type === 'Note') {
            addPersonSection(sections, people, viewTask)

            modalBody = <Modal.Body>
                <Table condensed bordered>
                    <tbody>
                        <tr><td colSpan="2">{viewTask.notes[0].note}</td></tr>
                        {sections}
                    </tbody>
                </Table>
                {noteAttachments}
            </Modal.Body>
        } else {
            if (viewTask.confirmTask) {
                ViewTask.addSection(sections, 'Provisional', <i>To be confirmed</i>)
            }

            if (viewTask.jobId) {
                ViewTask.addSection(sections, 'Job',
                    <Link
                        to={'/job/' + viewTask.jobId}
                        onClick={() => clearViewTask()}
                    >{viewTask.title || viewTask.description}</Link>)
            }
            ViewTask.addSection(sections, 'Status', viewTask.status)

            if (viewTask.dueDate) {
                const dueDate = moment(viewTask.dueDate)

                ViewTask.addSection(sections, 'Due date', <span
                    className={dueDate.isBefore(moment()) && viewTask.status === 'Open' ? 'alert-danger' : ''}
                >
                    {dueDate.format("L")}
                </span>)
            }

            ViewTask.addSection(sections, 'Customer', viewTask.customer)
            ViewTask.addSection(sections, 'Contact', viewTask.contact)
            ViewTask.addSection(sections, 'Address', viewTask.address)

            viewTask.aspects.forEach(a => {
                if (a.type.toLowerCase().indexOf('price') !== -1) {
                    ViewTask.addSection(sections, a.type, '£' + a.value + ' (ex. VAT)')
                } else {
                    let value = a.value

                    if (value === 'true' || value === 'false') {
                        value = value ? 'Yes' : 'No'
                    }

                    ViewTask.addSection(sections, a.type, value)
                }
            })

            const assignedTo = viewTask.assignees
                .map(personId => people.find(p => (p.id === personId)))
                .filter(p => (p))
                .map(p => p.name)
                .sort()
                .join('\n')

            ViewTask.addSection(sections, 'Assigned to', assignedTo)

            if (viewTask.secure) {
                ViewTask.addSection(sections, 'Restricted task', <i>This task contains some information which has not been displayed.</i>)
            }

            const dayStart = getHour(dayStartHour)
            const dayEnd = getHour(dayEndHour)

            if (viewTask.bookings && viewTask.bookings.length > 0) {

                const bookingDays = {}

                viewTask.bookings
                    .forEach(b => {
                        const day = moment(b.date).format("L")
                        if (!bookingDays[day]) {
                            bookingDays[day] = []
                        }
                        if (b.start === dayStart && b.end === dayEnd) {
                            bookingDays[day].push('All day')
                        } else {
                            bookingDays[day].push(b.start + ' - ' + b.end)
                        }
                    })

                const booking = Object.keys(bookingDays)
                    .sort()
                    .map(date => date + ' (' + bookingDays[date].sort().join(', ') + ')')
                    .join('\n')

                ViewTask.addSection(sections, viewTask.bookings.length === 1 ? 'Booking' : 'Bookings', booking)
            }

            addPersonSection(sections, people, viewTask)

            if (taskType && taskType.formOnCreate) {

                taskType.formOnCreate.forEach(formTypeName => {
                    const form = this.state[formTypeName]

                    if (form) {
                        ViewTask.addSection(
                            sections,
                            form.formType,
                            <div>
                                Assigned to {getPersonById(people, form.personId).name}
                                <ButtonGroup className="pull-right">
                                    <Button
                                        href={"/#/form/job/" + form.jobId + (form.jobId === form.jobOrTaskId ? "" : "/task/" + form.jobOrTaskId) + "/" + form.formType.replace(/ /g, '')}
                                        bsStyle={'info'}
                                        bsSize={'xs'}>Complete</Button>
                                    <DeleteForm
                                        id={form.jobOrTaskId}
                                        formType={form.formType}
                                        callback={() => this.getFormDetails(this.props)}
                                        size="xs"
                                    />
                                </ButtonGroup>
                            </div>
                        )
                    }
                })
            }

            const notes = []

            if (viewTask.notes) {
                viewTask.notes.forEach(note => notes.push(
                    <tr key={note.status}>
                        <td>{note.note}</td>
                    </tr>
                    )
                )
            }

            modalBody = <Modal.Body>
                <Table striped condensed bordered>
                    <tbody>
                    {sections}
                    </tbody>
                </Table>
                <Table className={notes.length === 0 ? 'hidden' : '' } striped condensed bordered>
                    <thead>
                    <tr>
                        <td>Notes</td>
                    </tr>
                    </thead>
                    <tbody>
                    {notes}
                    </tbody>
                </Table>
                {noteAttachments}
            </Modal.Body>
        }

        const cancelButton = (!viewTask.status || viewTask.status !== TASK_STATES.CANCELLED || viewTask.type === 'Note') ?
            <Button
                onClick={this.deleteTask}
                bsStyle={'danger'}
            >Remove</Button>
            : null

        const rebookButton = taskType && (taskType.booking && (viewTask.status === TASK_STATES.OPEN || !taskType.job)) ? <Button
                onClick={this.rebook}
                bsStyle={'info'}
            >Rebook</Button>
            : null

        const editButton = (!viewTask.status || (viewTask.status === TASK_STATES.OPEN && viewTask.type !== 'Note')) ?
            <Button
                onClick={() => {
                    if (taskType && taskType.booking) {
                        newBooking()
                    } else {
                        newAddTask()
                    }
                    clearViewTask()
                }}
                bsStyle={'warning'}
            >Edit</Button>
            : null

        const editCompleteButton = (viewTask.status === TASK_STATES.COMPLETE || viewTask.type === 'Note') ?
            <Button
                onClick={this.newCompleteTask}
                bsStyle={'info'}
            >Edit</Button>
            : null

        const completeButton = (viewTask.status === TASK_STATES.OPEN && viewTask.type !== 'Note') ?
            <Button
                onClick={this.newCompleteTask}
                bsStyle={'success'}
            >Complete</Button>
            : null

        const modalFooter = viewTask.secure ? null : <Modal.Footer style={{textAlign: "center"}}>
            <ButtonGroup bsSize="large">
                {cancelButton}
                {editButton}
                {rebookButton}
                {editCompleteButton}
                {completeButton}
            </ButtonGroup>
        </Modal.Footer>

        return (
            <Modal show={true} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>{viewTask.type}</Modal.Title>
                </Modal.Header>
                {modalBody}
                {modalFooter}
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        people: state.people,
        viewTask: state.viewTask,
        completeTask: state.completeTask,
        weekStartDate: state.calendar.weekStartDate,
        dayStartHour: state.calendar.dayStartHour,
        dayEndHour: state.calendar.dayEndHour,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        clearViewTask: () => {dispatch(clearViewTask())},
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        populateWeekData: (weekStart, bookings) => {dispatch(populateWeek(weekStart, bookings))},
        newCompleteTask: () => dispatch(newCompleteTask()),
        editExistingCompleteTask: () => dispatch(editExistingCompleteTask()),
        rebook: (taskType, taskId, date, jobId, lengthInHours) => dispatch(rebook(taskType, taskId, date, jobId, lengthInHours)),
        newBooking: () => dispatch(newAddBooking()),
        newAddTask: () => dispatch(newAddTask()),
    })
}

const ViewTaskWithRouter = withRouter(ViewTask)

export default connect(mapStateToProps, mapDispatchToProps)(ViewTaskWithRouter)
