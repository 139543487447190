import React, { Component } from 'react'
import {connect} from "react-redux"
import { TaskList } from "../Task"
import moment from "moment"

class Tasks extends Component {
    
    
    render () {
        const {
            tasks,
            userId,
        } = this.props
        
        const personTasks = tasks
            .filter(task => task.assignees.find(personId => personId === userId))
            .filter(task => task.status === 'Open')
            .filter(task => task.dueDate && moment(task.dueDate).isBefore(moment()))
        
        return (
            <div>
                <TaskList
                    tasks={personTasks}
                    showJobInfo={true}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tasks: state.tasks.tasks,
        userId: state.userId,
    }
}

export default connect(mapStateToProps)(Tasks)
