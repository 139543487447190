import React, { Component } from 'react'
import { Day } from "../Calendar"
import moment from "moment"
import {Table} from "react-bootstrap"
import {addRequest, populateWeek, updateHover} from "../state/actions"
import { connect } from "react-redux"

class Bookings extends Component {
    UNSAFE_componentWillMount() {
        this.requestBookings(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.requestBookings(nextProps)
    }

    requestBookings(props) {
        const {
            addARequest,
            populateWeekData,
            weekStartDate,
        } = props

        addARequest(
            'week-' + weekStartDate,
            {
                url: 'booking?weekStart='+weekStartDate,
                method: 'GET',
            },
            body => {
                populateWeekData(weekStartDate, body)
            }
        )
    }

    render () {
        const {
            userId,
            updateHover,
        } = this.props

        return (
            <Table bordered condensed className="calendar-main-grid" onMouseLeave={updateHover}>
                <tbody>
                    <Day
                        date={moment().utc().startOf('d')}
                        people={[userId]}
                        alwaysDisplayPersonId={userId}
                    />
                </tbody>
            </Table>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.userId,
        weekStartDate: state.calendar.weekStartDate,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateHover: () => {dispatch(updateHover(null, null))},
        addARequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        populateWeekData: (weekStart, bookings) => {dispatch(populateWeek(weekStart, bookings))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookings)
