import React, { Component } from 'react'
import { Button, Glyphicon } from 'react-bootstrap'
import { connect } from 'react-redux'
import { newAddNote } from '../../state/actions.js'

class AddNote extends Component {
    render() {
        const {
            newAddNote
        } = this.props

        return (
            <Button
                id={'job-add-note'}
                type="button"
                bsStyle="info"
                onClick={newAddNote}
            >
                <span><Glyphicon glyph="pencil" /> Note</span>
            </Button>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        newAddNote: () => {dispatch(newAddNote())},
    })
}

export default connect(null, mapDispatchToProps)(AddNote)
