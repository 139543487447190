import React, { Component } from 'react'
import {Button, Glyphicon, Panel, Table} from 'react-bootstrap'
import {connect} from "react-redux"
import {updateKeyValue} from "../state/actions"
import AddPersonModal from "./AddPersonModal"

class PeoplePage extends Component {

    constructor(props) {
        super(props)

        this.addPerson = this.addPerson.bind(this);
    }

    addPerson() {
        this.props.amendPerson({roles:[],email:'info@pipelinelogistics.com'})
    }

    render () {
        const {
            people,
            amendPerson,
        } = this.props

        const enabledPeople = people
            .filter((person) => person.assignable)
            .sort((a, b) => (a.name <= b.name ? -1 : 1))
            .map((person) => (
            <tr key={person.id} onClick={() => amendPerson(person)}>
                <td>{person.name}</td>
                <td>{person.userName}</td>
                <td className={"alert-success"}>
                    <span>
                        <Glyphicon glyph="ok" /> Enabled
                    </span>
                </td>
                <td>{person.email}</td>
            </tr>
        ))

        const disabledPeople = people
            .filter((person) => !person.assignable)
            .sort((a, b) => (a.name <= b.name ? -1 : 1))
            .map((person) => (
            <tr key={person.id} onClick={() => amendPerson(person)}>
                <td>{person.name}</td>
                <td>{person.userName}</td>
                <td className={"alert-danger"}>
                    <span>
                        <Glyphicon glyph="remove" /> Disabled
                    </span>
                </td>
                <td>{person.email}</td>
            </tr>
        ))

        return (
            <Panel>
                <Panel.Heading>
                    <Button
                        bsSize={'large'}
                        bsStyle={'info'}
                        className={'pull-right'}
                        onClick={() => this.addPerson()} >
                        <Glyphicon glyph={'plus'} />
                    </Button>
                    <h3>People</h3>
                </Panel.Heading>
                <Panel.Body>
                    <Table responsive condensed striped hover bordered className="list-table job-list-table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Enabled</th>
                            <th>Email</th>
                        </tr>
                        </thead>
                        <tbody>
                        {enabledPeople}
                        {disabledPeople}
                        </tbody>
                    </Table>
                </Panel.Body>
                <AddPersonModal />
            </Panel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        people: state.people,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        amendPerson: (person) => {dispatch(updateKeyValue('addPerson', person))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(PeoplePage)
