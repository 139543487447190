import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Selector } from "../components"
import {updateJobsFilter} from "../state/actions"

class NotUpdatedFor extends Component {
    render () {
        const { notUpdatedFor, updateJobsFilter } = this.props

        const options = [
            { id: '1', value: '1 year'},
            { id: '2', value: '2 years'},
            { id: '3', value: '3 years'},
            { id: '4', value: '4 years'},
            { id: '5', value: '5 years'},
            { id: '6', value: '6 years'},
            { id: '7', value: '7 years'},
        ]

        return (
            <Selector
                id={'jobs-not-updated-for-selector'}
                items={options}
                selectedId={notUpdatedFor}
                title={'Not updated for'}
                valueUpdated={updateJobsFilter}
                valueUpdatedId={'notUpdatedFor'}
                hideIfNullValue={true}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        notUpdatedFor: state.job.notUpdatedFor,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateJobsFilter: (key, value) => {dispatch(updateJobsFilter(key, value))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(NotUpdatedFor)
