import React from 'react'
import { Calendar, AddCalendarPerson, ChoosePerson, PickLabel, PickWeek, SelectedJob, SelectedTaskType, RebookTask, PickWeeks, Rubbish } from '.'
import { Panel, ButtonToolbar } from 'react-bootstrap'
import { AddBooking } from "../components"

const CalendarPage = () => {
    return (
        <Panel>
            <Panel.Body>
                <ButtonToolbar className={'pull-right'}>
                    <AddBooking page={'calendar'} />
                </ButtonToolbar>
                <ButtonToolbar>
                    <PickWeek />
                    <AddCalendarPerson />
                    <ChoosePerson />
                    <PickWeeks />
                    <PickLabel />
                </ButtonToolbar>
                <ButtonToolbar>
                    <SelectedJob />
                    <SelectedTaskType />
                    <RebookTask />
                </ButtonToolbar>
                <Calendar />
                <div style={{position: 'absolute', right: '5px', bottom: '5px'}}>
                    <Rubbish />
                </div>
            </Panel.Body>
        </Panel>
    )
}

export default CalendarPage
