import React, { Component } from 'react'
import { AddFilter, JobList, NoActiveTasks, NotUpdatedFor, SearchBox, Status } from '.'
import {ButtonToolbar, Col, Row, Grid, Panel, Button, Glyphicon} from 'react-bootstrap'
import {connect} from "react-redux"
import { addRequest, populateJobs } from '../state/actions.js'

class JobPage extends Component {

    requestJobs(updatedSearchString) {
        const {
            addRequest,
            populateJobs,
            status,
            noActiveTasks = false,
            notUpdatedFor,
        } = this.props

        const searchString = updatedSearchString ? updatedSearchString : this.props.searchString

        let url = 'job?'

        if (searchString) {
            url += 'searchString=' + searchString + '&'
        }

        if (status) {
            url += 'status=' + status + '&'
        }

        if (noActiveTasks) {
            url += 'noActiveTasks=true&'
        }

        if (notUpdatedFor) {
            url += 'notUpdatedFor=' + notUpdatedFor + '&'
        }

        addRequest(
            'searchJob',
            {
                url: url,
                method: 'GET',
            },
            body => populateJobs(body)
        )
    }

    render () {
        return (
            <Panel>
                <Panel.Body>
                    <Grid fluid>
                        <Row>
                            <Col sm={6} md={6}>
                                <SearchBox onChange={searchString => this.requestJobs(searchString)} />
                            </Col>
                            <Col sm={1} md={1}>
                                <Button
                                    onClick={() => this.requestJobs()}
                                    bsStyle={'success'}
                                >
                                    <Glyphicon glyph={'search'} />
                                </Button>
                            </Col>
                            <br/>
                            <br/>
                        </Row>
                        <Row>
                            <Col sm={12} md={12}>
                                <ButtonToolbar>
                                    <Status />
                                    <NoActiveTasks />
                                    <NotUpdatedFor />
                                    <AddFilter />
                                </ButtonToolbar>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12}>
                                <JobList />
                            </Col>
                        </Row>
                    </Grid>
                </Panel.Body>
            </Panel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        searchString: state.job.searchString,
        status: state.job.status,
        noActiveTasks: state.job.noActiveTasks,
        notUpdatedFor: state.job.notUpdatedFor,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        populateJobs: (jobs) => {dispatch(populateJobs(jobs))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(JobPage)
