import moment from 'moment'
import {FORMS, TASK_TYPES} from "./data"

export const getHour = (hour, half) => {
    return moment([1, 1, 1, hour, half ? 30 : 0]).format('HH:mm')
}

export const getHourFromNumber = (hour) => {
    const baseHour = Math.trunc(hour)
    return moment([1, 1, 1, baseHour, baseHour === hour ? 0 : 30]).format('HH:mm')
}

export const cleanUpAddress = address => {
    if (!address) {
        return null
    }

    return address
        .replace(/\r/g, '')
        .replace(/,/g, '\n')
        .replace(/ +/g, ' ')
        .replace(/ \n/g, '\n')
        .replace(/\n+/g, '\n')
}

export const getTaskType = name => {
    return TASK_TYPES.find(type => type.name.toLowerCase() === name.toLowerCase())
}

export const getTimeAsNumber = (hourString) => {
    const hourMoment = moment(hourString, 'HH:mm')
    return hourMoment.hour() + (hourMoment.minute() / 60)
}

export const populateAllWeeks = (addRequest, populateWeekData, weekStartDate, displayWeeks) => {
    for (let week=0; week<displayWeeks; week++) {
        const weekStart = moment(weekStartDate).utc().add(week, 'weeks').toJSON()
        addRequest(
            'week-' + weekStart,
            {
                url: 'booking?weekStart=' + weekStart,
                method: 'GET',
            },
            body => {
                populateWeekData(weekStart, body)
            }
        )
    }
}

export const hasRole = (user, role) => {
    return (user && user.roles && user.roles.find(userRole => userRole.toLowerCase() === role.toLowerCase()))
}

export const getFormType = (formType) => FORMS.find(f => f.name.replace(/ /g, '').toLowerCase() === formType.replace(/ /g, '').toLowerCase())
