import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import {connect} from "react-redux"
import Form from "./Form"
import {getFormType} from "../shared/functions"

class FormPage extends Component {

    render () {
        const {
            jobId,
            taskId
        } = this.props.match.params

        const formType = getFormType(this.props.match.params.formName)

        if (!formType) {
            return null
        }

        let form
        // Get form from localstorage

        return (
            <Panel>
                <Panel.Body>
                    <Form jobId={jobId} taskId={taskId} formType={formType} savedForm={form} />
                </Panel.Body>
            </Panel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        form: state.form,
    }
}

export default connect(mapStateToProps)(FormPage)
