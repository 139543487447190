import {connect} from "react-redux"
import {Button, Panel, Table} from "react-bootstrap"
import React, { Component } from 'react'
import {getPersonById} from "../state/reducer"
import moment from "moment"
import DeleteForm from "./DeleteForm"

class FormList extends Component {

    render() {
        const {
            list,
            people,
            personId,
            callback
        } = this.props

        if (!list || list.length === 0) {
            return null
        }

        const forms =  list.map(f => {
            const person = getPersonById(people, f.personId)

            return <tr key={f.jobOrTaskId + f.dueDate}>
                <td>{f.formType}</td>
                {personId || !person ? null : <td>{person.name}</td>}
                <td>{moment(f.dueDate).format("L")}</td>
                <td>
                    <Button
                        href={"/#/form/job/" + f.jobId + (f.jobId === f.jobOrTaskId ? "": "/task/" + f.jobOrTaskId) + "/" + f.formType.replace(/ /g,'')}
                        bsStyle={'info'}
                        bsSize={'small'}>Complete</Button>
                    &nbsp;
                    <DeleteForm
                        id={f.jobOrTaskId}
                        formType={f.formType}
                        callback={callback}
                        size="small"
                    />
                </td>
            </tr>})

        return <Table bordered condensed hover striped className={'list-table'}>
            <thead>
                <tr className="info">
                    <th>Form</th>
                    {personId ? null : <th>Assignee</th>}
                    <th>Day</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {forms}
            </tbody>
        </Table>
    }
}

const mapStateToProps = (state) => {
    return {
        people: state.people,
        formsLastRefreshed: state.formsLastRefreshed
    }
}

export default connect(mapStateToProps)(FormList)
