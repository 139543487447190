import {addRequest} from "../state/actions"
import {connect} from "react-redux"
import {Panel} from "react-bootstrap"
import React, { Component } from 'react'
import FormList from "./FormList"

class FormListSection extends Component {
    constructor (props) {
        super(props)

        this.state = { list: [] }

        this.getForms = this.getForms.bind(this);
    }

    componentDidMount() {
        this.getForms(this.props)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.getForms(this.props)
        }
    }

    getForms(props) {
        const {
            personId,
            jobId,
            addRequest,
        } = props

        const formUrl = 'form?'
            + (personId ? 'personId=' + personId : '')
            + (jobId ? 'jobId=' + jobId : '')

        addRequest(
            'getForms-' + personId + jobId,
            {
                url: formUrl,
                method: 'GET',
            },
            body => {
                this.setState({list: body.sort((a, b) => (a.dueDate > b.dueDate) ? 1 : -1)})
            }
        )
    }

    render() {
        const {
            personId,
        } = this.props

        return <Panel bsStyle="default">
            <Panel.Heading>Forms</Panel.Heading>
            <Panel.Body>
                <FormList
                    personId={personId}
                    list={this.state.list}
                    callback={() => this.getForms(this.props)}
                />
            </Panel.Body>
        </Panel>
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
    })
}

export default connect(null, mapDispatchToProps)(FormListSection)
