import React, { Component } from 'react'
import {
    addRequest,
    populateJobAttachments,
    updateSubsectionKeyValue,
    updateSectionKeyValue, populateJobTasks
} from '../../state/actions.js'
import { connect } from 'react-redux'
import {
    Button,
    ButtonGroup,
    CloseButton,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Glyphicon,
    Modal
} from 'react-bootstrap'

import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment"
import {put} from "axios/index"
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

class TakePhotoModal extends Component {

    constructor(props) {
        super(props)

        this.add = this.add.bind(this);
        this.close = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTakePhoto = this.handleTakePhoto.bind(this);
    }

    add() {

        const {
            addRequest,
            clearTakePhoto,
            takePhoto,
            job,
            populateJobTasks,
            populateJobAttachments,
        } = this.props

        const body = {
            date: moment().format(),
            jobId: job.jobId,
            note: takePhoto.note,
            type: 'Note'
        }

        if (takePhoto.dataUri) {

            addRequest(
                'takePhoto',
                {
                    url: 'task',
                    method: 'POST',
                    body,
                },
                (data) => {
                    const name = 'photo-' + moment().format() + '.png'
                    const blob = dataURItoBlob(takePhoto.dataUri)
                    const url = 'attachment/' + job.jobId + '/' + data.taskId + '/' + name

                    addRequest(
                        url,
                        {
                            url,
                            method: 'POST',
                        },
                        body => {
                            put(
                                body.url,
                                blob,
                                {
                                    headers: {
                                        'content-type': 'image/png',
                                    }
                                }
                            )
                                .then(() => {
                                    addRequest(
                                        'job-attachments-' + job.jobId,
                                        {
                                            url: 'attachment?prefix=' + job.jobId,
                                            method: 'GET',
                                        },
                                        body => {
                                            populateJobAttachments(body)
                                        }
                                    )

                                    addRequest(
                                        'job-tasks-' + job.jobId,
                                        {
                                            url: 'task?jobId=' + job.jobId,
                                            method: 'GET',
                                        },
                                        body => populateJobTasks(body, job.jobId)
                                    )

                                    clearTakePhoto()
                                })
                        })
                })
        }
    }

    close() {
        this.props.clearTakePhoto()
    }

    handleChange(e) {
        this.props.updateTakePhoto('note', e.target.value);
    }

    handleTakePhoto(dataUri) {
        this.props.updateTakePhoto('dataUri', dataUri);
    }

    render () {
        const {
            takePhoto,
        } = this.props

        if (!takePhoto)
            return null

        return (
            <Modal show={true} onHide={this.close} bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title>Add note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal>
                        <FormGroup controlId="photo">
                            {takePhoto.dataUri
                            ? <div style={{textAlign: "center"}}>
                                <img alt="Photo preview" src={takePhoto.dataUri} />
                                    <br />
                                    <br />
                                    <Button
                                        bsStyle={'danger'}
                                        onClick={() => this.handleTakePhoto(null)}
                                    >
                                        <Glyphicon glyph={'remove'} /> Remove
                                    </Button>
                            </div>
                            : <Camera
                                onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
                                idealFacingMode = { FACING_MODES.ENVIRONMENT }
                            />}
                        </FormGroup>
                        <FormGroup controlId="note">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Caption</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="textarea"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{textAlign: "center"}}>
                    <ButtonGroup bsSize="large">
                        <Button onClick={this.close}>Close</Button>
                        <Button onClick={this.add} bsStyle="primary" disabled={!takePhoto.dataUri}>Add</Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        )
    }
}

const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

const mapStateToProps = (state) => {
    return {
        takePhoto: state.job.takePhoto,
        job: state.job.job,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        clearTakePhoto: () => {dispatch(updateSectionKeyValue('job', 'takePhoto', null))},
        updateTakePhoto: (key, value) => {dispatch(updateSubsectionKeyValue('job', 'takePhoto', key, value))},
        populateJobTasks: (tasks, jobId) => {dispatch(populateJobTasks(tasks, jobId))},
        populateJobAttachments: (attachments) => {dispatch(populateJobAttachments(attachments))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(TakePhotoModal)
