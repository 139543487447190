import React, { Component } from 'react'
import { Alert, Button, Glyphicon, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import {searchCustomers, updateNewJob, updateEditJob, addRequest, populateJob} from "../../state/actions"
import {withRouter} from "react-router-dom"

class CustomerList extends Component {
    constructor(props) {
        super(props)

        this.updateJobWithCustomer = this.updateJobWithCustomer.bind(this);
    }

    updateJobWithCustomer(customer) {
        const {
            existingJob,
            addRequest,
            populateJob,
        } = this.props

        const body = {
            ...existingJob,
            customerId: customer.customerId
        }

        body.customer = null

        addRequest(
            'updateJob',
            {
                url: 'job/' + existingJob.jobId,
                method: 'PATCH',
                body,
            },
            () => {
                addRequest(
                    'job-' + existingJob.jobId,
                    {
                        url: 'job/' + existingJob.jobId,
                        method: 'GET',
                    },
                    body => populateJob(body)
                )
            }
        )
    }

    render () {
        const {
            customers,
            updateNewJob,
            clearSearch,
        } = this.props

        const updateJob = this.props.location.pathname.indexOf('job-new') === -1 ? this.updateJobWithCustomer : updateNewJob;

        if (!customers || customers.length === 0) {
            return <Alert bsStyle={"info"}>
                No matching customers found
            </Alert>
        }

        const customersList = customers.map((customer) => (
            <tr key={customer.customerId}>
                <td>{customer.name}</td>
                <td>{customer.telephoneNumber}</td>
                <td>{customer.email}</td>
                <td>{customer.town}</td>
                <td>{customer.postcode}</td>
                <td>
                    <Button
                        bsStyle={"success"}
                        onClick={() => {
                            updateJob(customer)
                            clearSearch()
                        }}
                    >
                        <Glyphicon glyph={"arrow-right"} />
                    </Button>
                </td>
            </tr>
        ))

        return (
            <Table responsive condensed striped hover bordered>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>TelephoneNumber</th>
                        <th>Email</th>
                        <th>Town</th>
                        <th>Post code</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {customersList}
                </tbody>
            </Table>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        customers: state.customer.list,
        existingJob: state.job.job,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateNewJob: (customer) => {dispatch(updateNewJob('customer', customer))},
        updateEditJob: (customer) => {dispatch(updateEditJob('customer', customer))},
        clearSearch: () => {dispatch(searchCustomers(null))},
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        populateJob: (job) => {dispatch(populateJob(job))},
    })
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerList))
