import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateCalendarValue,addRequest, populateWeek } from '../state/actions.js'
import {Selector} from "../components"
import {populateAllWeeks} from "../shared/functions"

class PickWeeks extends Component {
    constructor(props) {
        super(props)

        this.pickWeeks = this.pickWeeks.bind(this);
    }

    pickWeeks(key, value) {
        const { weekStartDate, addRequest, populateWeekData, updateCalendarValue } = this.props

        updateCalendarValue(key, value)

        populateAllWeeks(addRequest, populateWeekData, weekStartDate, value)
    }

    render () {
        const { displayWeeks } = this.props

        const options = []
        for (let w=1; w<5; w++) {
            options.push({
                id: w,
                value: w
            })
        }

        return (
            <Selector
                id={'PickWeeks'}
                items={options}
                selectedStyle={'primary'}
                selectedId={displayWeeks}
                title={'Weeks'}
                valueUpdated={this.pickWeeks}
                valueUpdatedId={'displayWeeks'}
                hideIfNullValue={false}
                closeButton={false}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        weekStartDate: state.calendar.weekStartDate,
        displayWeeks: state.calendar.displayWeeks,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateCalendarValue: (key, value) => {dispatch(updateCalendarValue(key, value))},
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        populateWeekData: (weekStart, bookings) => {dispatch(populateWeek(weekStart, bookings))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(PickWeeks)
