import React, { Component } from 'react'
import { updateTasksFilter } from '../state/actions.js'
import { connect } from 'react-redux'
import { Selector } from "../components"
import { TASK_TYPES } from "../shared/data"

class Type extends Component {
    render () {
        const { type, updateTasksFilter } = this.props

        const options = TASK_TYPES
            .map(t => t.name)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort((a, b) => a < b ? -1 : 1)
            .map(type => ({
                    id: type,
                    value: type,
                }))

        return (
            <Selector
                id={'tasks-type-selector'}
                items={options}
                selectedId={type}
                title={'Type'}
                valueUpdated={updateTasksFilter}
                valueUpdatedId={'type'}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        type: state.tasks.type,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateTasksFilter: (key, value) => {dispatch(updateTasksFilter(key, value))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Type)
