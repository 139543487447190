import React, { Component } from 'react'
import { Button, Glyphicon } from 'react-bootstrap'
import { connect } from 'react-redux'
import {addNewCustomer, addRequest, clearNewJob, searchCustomers, updateNewJob, copyNewJobAddressFromCustomer} from "../../state/actions"
import {withRouter} from "react-router-dom"
import { JobForm } from '..'
import {cleanUpAddress} from "../../shared/functions"

class Job extends Component {
    constructor(props) {
        super(props)

        this.add = this.add.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearCustomer = this.clearCustomer.bind(this);
    }

    add() {

        const {
            addRequest,
            clearNewJob,
            job,
            history
        } = this.props

        const body = {
            ...job,
            customerId: job.customer.customerId,
            address: cleanUpAddress(job.address),
        }

        body.customer = null

        addRequest(
            'addJob',
            {
                url: 'job',
                method: 'POST',
                body,
            },
            body => {
                history.push('/job/' + body.jobId)

                clearNewJob()
            }
        )
    }

    handleChange(e) {
        this.props.updateNewJob(e.target.id, e.target.value)
    }

    clearCustomer() {
        this.props.updateNewJob('customer', null)
    }

    render() {
        const {
            addNewCustomer,
            job = {},
            copyNewJobAddressFromCustomer,
            searchCustomers,
        } = this.props

        return (
            <div>
                <JobForm
                    job={job}
                    copyAddressFromCustomer={copyNewJobAddressFromCustomer}
                    addNewCustomer={addNewCustomer}
                    clearCustomer={this.clearCustomer}
                    searchCustomers={searchCustomers}
                    handleChange={this.handleChange}
                    existingJob={false}
                    />
                <Button onClick={this.add} className={'pull-right'} bsStyle="primary"><Glyphicon glyph={"plus"} /> Add job</Button>
            </div>
        )
    }
}

const JobWithRouter = withRouter(Job)

const mapStateToProps = (state) => {
    return {
        job: state.job.new,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        addNewCustomer: () => {dispatch(addNewCustomer())},
        updateNewJob: (key, value) => {dispatch(updateNewJob(key, value))},
        searchCustomers: () => {dispatch(searchCustomers(''))},
        clearNewJob: () => {dispatch(clearNewJob())},
        copyNewJobAddressFromCustomer: () => {dispatch(copyNewJobAddressFromCustomer())},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(JobWithRouter)

