import React, { Component } from 'react'
import { DayPerson } from '.'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import { connect } from 'react-redux'
import {updateHover} from "../state/actions"

class Day extends Component {
    constructor(props) {
        super(props)

        this.cellHover = this.cellHover.bind(this);
    }

    cellHover(hour) {
        const { updateHover, date } = this.props
        updateHover(date, hour)
    }

    render () {
        const { people, date, dayStartHour, dayEndHour, hover, alwaysDisplayPersonId } = this.props

        const renderPeople = people.map(personId => {
            return <DayPerson
                key={personId + date}
                date={date}
                personId={personId}
                alwaysDisplay={alwaysDisplayPersonId === personId}
            />
        })

        let matchHour = -1

        if (hover && hover.date === date) {
            matchHour = hover.hour
        }

        let hours = []

        for (let i=dayStartHour; i<dayEndHour; i++) {
            hours.push(<tr
                key={i}
                className={matchHour === i ? 'hour-table-hover' : ''}
                onMouseOver={() => this.cellHover(i) }
            >
                <td>{moment([1, 1, 1, i]).format('HH:mm')}</td>
            </tr>)
        }

        return (
            <tr>
                <td>
                    <div className="col-xs-6">
                        {date.format("ddd")}
                        <br/>
                        {date.format("MMM Do")}
                    </div>
                    <Table striped condensed className="pull-right hour-table hour-table-times col-xs-6">
                        <tbody>
                            {hours}
                        </tbody>
                    </Table>
                </td>
                {renderPeople}
            </tr>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dayStartHour: state.calendar.dayStartHour,
        dayEndHour: state.calendar.dayEndHour,
        hover: state.calendar.hover,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        updateHover: (date, hour) => {dispatch(updateHover(date, hour))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Day)
