import React, { Component } from 'react'
import { addPerson } from '../state/actions.js'
import { connect } from 'react-redux'
import { DropdownButton, MenuItem } from 'react-bootstrap'

class AddCalendarPerson extends Component {
    constructor(props) {
        super(props)
        this.addSelectedPerson = this.addSelectedPerson.bind(this);
    }

    addSelectedPerson(personId) {
        if (this.props.allPeople.find((person) => ( person.id === personId ))) {
            this.props.addThisPerson(personId)
        }
    }

    render () {
        const { people, allPeople } = this.props

        const options = allPeople
            .filter(person => !(people.find((personId) => ( person.id === personId ))))
            .sort((a, b) => (a.name <= b.name ? -1 : 1))
            .map(person => (
                    <MenuItem
                        key={person.id}
                        eventKey={person.id}
                    >
                        {person.name}
                    </MenuItem>
                )
            )

        if (options.length === 0) {
            return null
        }

        return (
            <DropdownButton
                id={'person-selector'}
                className="hidden-xs"
                bsStyle={'primary'}
                title={'Add person'}
                onSelect={this.addSelectedPerson}>
                {options}
            </DropdownButton>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        people: state.calendar.people,
        allPeople: state.assignablePeople,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addThisPerson: person => {dispatch(addPerson(person))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCalendarPerson)
