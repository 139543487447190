import React, { Component } from 'react'
import { Alert, Glyphicon, Table } from 'react-bootstrap'
import { Task } from '.'
import moment from "moment"

class TaskList extends Component {
    state = {
        sortField: 'due',
        sortOrderAsc: false, 
    }
    
    render() {
        const { tasks, showJobInfo, showExpanded } = this.props

        if (!tasks || tasks.length === 0) {
            if (showJobInfo) {
                return null
            } else {
                return <Alert bsStyle="warning">No tasks to display</Alert>
            }
        }

        const taskRows = tasks
            .sort(this.compareItems)
            .map(t => <Task
                key={t.taskId}
                task={t}
                showJobInfo={showJobInfo}
                showExpanded={showExpanded}
            />)

        return (
            <Table bordered condensed hover striped className={showJobInfo ? 'list-table' : ''}>
                <thead>
                <tr className="info">
                    <th
                        className={showJobInfo ? 'hidden-xs' : 'hide'}
                        onClick={() => this.updateState('job')}
                        style={{cursor: 'pointer'}}>
                        Job {this.getGlyph('job')}
                    </th>
                    <th
                        className={showJobInfo ? '' : 'hide'}
                        onClick={() => this.updateState('customer')}
                        style={{cursor: 'pointer'}}>
                        Customer {this.getGlyph('customer')}
                    </th>
                    <th
                        className={showJobInfo ? 'hidden-xs' : 'hide'}
                        onClick={() => this.updateState('address')}
                        style={{cursor: 'pointer'}}>
                        Address {this.getGlyph('address')}
                    </th>
                    <th
                        onClick={() => this.updateState('type')}
                        style={{cursor: 'pointer'}}>
                        Type {this.getGlyph('type')}
                    </th>
                    <th className={showJobInfo ? 'hidden-xs' : ''}>
                        Assigned to
                    </th>
                    <th
                        className={showJobInfo ? 'hidden-xs' : ''}
                        onClick={() => this.updateState('status')}
                        style={{cursor: 'pointer'}}>
                        Status {this.getGlyph('status')}
                    </th>
                    <th
                        className="hidden-xs"
                        onClick={() => this.updateState('due')}
                        style={{cursor: 'pointer'}}>
                        Due {this.getGlyph('due')}
                    </th>
                    <th className="hidden-xs">
                        Bookings
                    </th>
                </tr>
                </thead>
                {taskRows}
            </Table>
        )
    }
    
    getGlyph = (field) => {
        if (this.state.sortField === field) {
            return <Glyphicon
                className={'pull-right'}
                glyph={this.state.sortOrderAsc ? 'chevron-up' : 'chevron-down'}
            />
        }
        return null
    }

    updateState = (field) => {
        if (this.state.sortField === field) {
            this.setState({
                sortOrderAsc: !this.state.sortOrderAsc,
            })
        } else {
            this.setState({
                sortField: field,
                sortOrderAsc: true,
            }) 
        }
    }

    compareItems = (a, b) => {
        let aValue = this.getItemValueForSort(a)
        aValue = aValue ? aValue : ''
        const compareResult = aValue.localeCompare(this.getItemValueForSort(b))
        return this.state.sortOrderAsc ? compareResult : compareResult * -1
    }
    
    getItemValueForSort = (task) => {
        switch (this.state.sortField) {
            case 'job':
                return task.description
            case 'customer':
                return task.customer
            case 'address':
                return task.address ? task.address : ''
            case 'type':
                return task.type
            case 'status':
                return task.status
            case 'due':
            default :
                return moment(task.dueDate).toJSON()
        }
    }
}

export default TaskList

