import React, { Component } from 'react'
import { connect } from 'react-redux'
import {DropdownButton, Glyphicon, MenuItem} from 'react-bootstrap'
import {updateAddBooking} from "../state/actions"
import {hasRole} from "../shared/functions"

class AddBookingPerson extends Component {
    constructor(props) {
        super(props)
        this.addSelectedPerson = this.addSelectedPerson.bind(this);
    }

    addSelectedPerson(personId) {
        if (this.props.allPeople.find((person) => ( person.id === personId ))) {
            this.props.addThisPerson(personId, this.props.people)
        }
    }

    render () {
        const { people, allPeople, bsSize, taskType } = this.props

        const options = allPeople
            .filter(person => !(people.find((personId) => ( person.id === personId ))))
            .filter(person => hasRole(person, taskType))
            .sort((a, b) => (a.name <= b.name ? -1 : 1))
            .map(person => (
                    <MenuItem
                        key={person.id}
                        eventKey={person.id}
                    >
                        {person.name}
                    </MenuItem>
                )
            )

        if (options.length === 0) {
            return null
        }

        return (
            <DropdownButton
                id={'person-selector'}
                bsStyle={'info'}
                bsSize={bsSize}
                title={<Glyphicon glyph="plus" />}
                onSelect={this.addSelectedPerson}
                noCaret={true}
                style={{"margin": "2px"}}
            >
                {options}
            </DropdownButton>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        people: state.calendar.addBooking.people,
        allPeople: state.assignablePeople,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addThisPerson: (person, people) => {dispatch(updateAddBooking('people', [...people, person]))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBookingPerson)
