import React, { Component } from 'react'
import { addRequest, updateReset } from './state/actions.js'
import { connect } from 'react-redux'
import {Alert, Button, ButtonGroup, Col, ControlLabel, Form, FormControl, FormGroup, Modal} from 'react-bootstrap'

class Reset extends Component {

    state = {
        user: '',
        message: ''
    }

    constructor(props) {
        super(props)

        this.reset = this.reset.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
    }

    close() {
        this.setState({
            message: '',
            user: '',
        })
        this.props.updateReset(false)
    }

    reset() {
        const {
            addRequest,
        } = this.props

        this.setState({message: ''})

        addRequest(
            'reset',
            {
                url: 'user/' + this.state.user + '/reset',
                method: 'POST',
            },
            () => {
                this.setState({message: 'A email has been sent to your registered email address.'});
            }
        )
    }

    handleChange(e) {
        this.setState({user: e.target.value});
    }

    render () {
        const {
            token,
            reset,
        } = this.props

        let message = null

        if (this.state.message) {
            message = (
                <Alert bsStyle="info">
                    {this.state.message}
                </Alert>
            )
        }


        if (token || !reset) {
            return null
        } else {
            return (
                <Modal show={true} enforceFocus={true} keyboard={false} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Reset password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form horizontal>
                            <FormGroup>
                                <Col xs={12} sm={3}>
                                    <ControlLabel>Username</ControlLabel>
                                </Col>
                                <Col xs={12} sm={9}>
                                    <FormControl type="text" id="userName" value={this.state.user} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            {message}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer style={{textAlign: "center"}}>
                        <ButtonGroup bsSize="large">
                            <Button onClick={this.reset} bsStyle="primary">Reset password</Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        reset: state.reset,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        updateReset: (reset) => {dispatch(updateReset(reset))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Reset)
