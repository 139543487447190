import React, { Component } from 'react'
import { FormControl, FormGroup } from 'react-bootstrap'
import { connect } from 'react-redux'
import { searchCustomers } from "../../state/actions"

class SearchBox extends Component {

    render () {
        const {
            searchCustomers,
        } = this.props

        return <FormGroup>
            <FormControl
                    type="text"
                    placeholder="Customer name, phone number, etc."
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            searchCustomers(e.target.value)
                        }
                    }}
                />
        </FormGroup>
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        searchCustomers: (searchString) => {dispatch(searchCustomers(searchString))},
    })
}

export default connect(null, mapDispatchToProps)(SearchBox)
