import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addViewTask, addRequest, rebook } from '../state/actions.js'
import { CALENDAR_LABELS } from "../shared/data"
import { getTimeAsNumber } from "../shared/functions"

const ROW_HEIGHT = 21

class DayPersonEntry extends Component {
    constructor(props) {
        super(props);
        this.dragStart = this.dragStart.bind(this);
    }

    dragStart(e) {
        const {
            entry,
            rebook,
        } = this.props

        e.dataTransfer.effectAllowed = "move"
        e.dataTransfer.setDragImage(document.createElement("canvas"), 0, 0)

        setTimeout(() => {
            rebook(
                entry.type,
                entry.taskId,
                entry.date,
                entry.jobId,
                entry.endAsNumber - entry.startAsNumber,
                true,
            )
        }, 10)
    }

    render() {
        const {
            entry,
            dayStartHour,
            addViewTask,
            addRequest,
            calendarLabel,
            alwaysDisplay,
            overlapNumber,
            overlapPosition,
            overlapWidth,
            rebookTask,
            rebook
        } = this.props

        const intStart = getTimeAsNumber(entry.start)
        const intEnd = getTimeAsNumber(entry.end)

        const typeClass = 'calendar-entry-type-' + entry.type.toLowerCase().replace(' ', '-')

        const height = ROW_HEIGHT * (intEnd - intStart) + 'px'

        const label = DayPersonEntry.getLabel(calendarLabel, entry)

        const overlapSection = (100 / overlapNumber)

        let completeLabel = null

        if (calendarLabel.indexOf(CALENDAR_LABELS.COMPLETE) !== -1 && entry.status === 'Complete') {
            completeLabel = <i className={'fa fa-check-circle'} style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                color: "#02bf51",
                opacity: "70%"
            }}/>
        }

        return (
            <div
                key={entry.bookingId}
                className={'calendar-entry ' + typeClass
                + (entry.confirmTask ? ' calendar-entry-provisional' : '')
                + (alwaysDisplay ? '' : ' hidden-xs')
                + (rebookTask && rebookTask.taskId === entry.taskId ? ' calendar-entry-type-rebook' : '')}
                style={{
                    top: ROW_HEIGHT * (intStart - dayStartHour) + 'px',
                    height,
                    minHeight: height,
                    whiteSpace: 'pre-line',
                    width: (overlapSection * overlapWidth) + '%',
                    marginLeft: (overlapSection * overlapPosition) + '%'
                }}
                onClick={() => addRequest(
                    'task-' + entry.taskId,
                    {
                        url: 'task?taskId=' + entry.taskId,
                        method: 'GET',
                    },
                    body => addViewTask({
                        ...body[0],
                        bookingId: entry.bookingId
                    })
                )}
                draggable={true}
                onDragStart={this.dragStart}
                onDragEnd={rebook}
            >
                {completeLabel}
                {label}
            </div>
        )
    }

    static getLabel(calendarLabel, entry) {
        return calendarLabel
            .filter(label => label !== CALENDAR_LABELS.COMPLETE)
            .map(l => {
                let label

                switch (l) {
                    case CALENDAR_LABELS.CONTACT:
                        label = entry.contact
                        break;

                    case CALENDAR_LABELS.CUSTOMER:
                        label = entry.customer
                        break;

                    case CALENDAR_LABELS.ADDRESS:
                        label = entry.address
                        break;

                    default:
                        label = entry.title
                }

                return {
                    type: l,
                    label,
                }
            })
            .filter(l => l.label !== null && l.label !== '')
            .map(l => <p key={l.type}>{l.label}</p>)
    }
}

const mapStateToProps = (state) => {
    return {
        calendarLabel: state.calendar.label,
        rebookTask: state.calendar.rebookTask,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addViewTask: (task) => {dispatch(addViewTask(task))},
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        rebook: (taskType, taskId, date, jobId, lengthInHours, isDragging) => dispatch(rebook(taskType, taskId, date, jobId, lengthInHours, isDragging)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(DayPersonEntry)
