import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Job } from '.'
import { populateJob, populateJobAttachments, populateJobTasks, addRequest, updateKeyValue } from '../../state/actions.js'

class Page extends Component {
    UNSAFE_componentWillMount() {
        this.requestJob(this.props)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.requestJob(nextProps)
    }

    requestJob(props) {
        const {
            addRequest,
            populateJob,
            populateJobTasks,
            populateJobAttachments,
            resetJob,
        } = props

        const jobId = props.match.params.jobId

        resetJob()

        addRequest(
            'job-' + jobId,
            {
                url: 'job/' + jobId,
                method: 'GET',
            },
            body => populateJob(body)
        )

        addRequest(
            'job-tasks-' + jobId,
            {
                url: 'task?jobId=' + jobId,
                method: 'GET',
            },
            body => {
                populateJobTasks(body, jobId)
                addRequest(
                    'job-attachments-' + jobId,
                    {
                        url: 'attachment?prefix=' + jobId,
                        method: 'GET',
                    },
                    body => populateJobAttachments(body)
                )
            }
        )

    }

    render() {
        return (
            <div>
                <Job />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        resetJob: () => {dispatch(updateKeyValue('job', {}))},
        populateJob: (job) => {dispatch(populateJob(job))},
        populateJobTasks: (tasks, jobId) => {dispatch(populateJobTasks(tasks, jobId))},
        populateJobAttachments: (attachments) => {dispatch(populateJobAttachments(attachments))},
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
    })
}

export default connect(null, mapDispatchToProps)(Page)
