import React, { Component } from 'react'
import { choosePerson } from '../state/actions.js'
import { connect } from 'react-redux'
import { DropdownButton, MenuItem } from 'react-bootstrap'

class AddPerson extends Component {
    constructor(props) {
        super(props)
        this.chooseSelectedPerson = this.chooseSelectedPerson.bind(this);
    }

    chooseSelectedPerson(personId) {
        if (this.props.allPeople.find((person) => ( person.id === personId ))) {
            this.props.choosePerson(personId)
        }
    }

    render () {
        const { allPeople } = this.props

        if (!allPeople || allPeople.length === 0) {
            return null
        }

        const options = allPeople
            .sort((a, b) => (a.name <= b.name ? -1 : 1))
            .map(person => (
                    <MenuItem
                        key={person.id}
                        eventKey={person.id}
                    >
                        {person.name}
                    </MenuItem>
                )
            )

        return (
            <DropdownButton
                id={'person-selector'}
                className="visible-xs"
                bsStyle={'primary'}
                title={'Choose person'}
                onSelect={this.chooseSelectedPerson}>
                {options}
            </DropdownButton>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allPeople: state.assignableCalendarPeople,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        choosePerson: person => {dispatch(choosePerson(person))}
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPerson)
