import React, { Component } from 'react'
import { Job } from '.'
import {Row, Col, Grid} from 'react-bootstrap'

class Page extends Component {
    render() {
        return (
            <Grid>
                <Row>
                    <Col>
                        <Job />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default Page
