import React, { Component } from 'react'
import { addRequest, updateCompleteTask, populateWeek, clearCompleteTask, populateJobAttachments, populateJobTasks, newAddTask, clearViewTask } from '../state/actions.js'
import { connect } from 'react-redux'
import { Button, Modal, FormControl, FormGroup, InputGroup, Col, Form, ControlLabel, Checkbox } from 'react-bootstrap'
import {TASK_STATES, TASK_TYPES} from "../shared/data"
import { Selector } from "../components"
import { put } from 'axios'
import {addRequestForTasks, populateJob, updateCalendarJob, updateCalendarTaskType, updateTasks} from "../state/actions"
import {withRouter} from "react-router-dom"
import {getTaskType} from "../shared/functions"

class CompleteTask extends Component {

    constructor(props) {
        super(props)

        this.close = this.close.bind(this);
        this.completeThisTask = this.completeThisTask.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    close() {
        this.props.clearCompleteTask()
    }

    completeThisTask() {
        const {
            addRequest,
            clearCompleteTask,
            populateWeekData,
            weekStartDate,
            viewTask,
            completeTask,
            populateJobAttachments,
            populateJobTasks,
            populateJob,
            job,
            newAddTask,
            clearViewTask,
            personId,
            status,
            taskType,
            updateTasks,
            addRequestForTasks,
            updateCalendarJob,
            updateCalendarTaskType,
            history,
            location,
        } = this.props

        const type = getTaskType(viewTask.type)

        if (type && type.next && viewTask.status === TASK_STATES.OPEN && !completeTask.nextTaskType) {
            alert('Please select the next task to be added.')
            return
        }

        if (completeTask.attachments) {
            const attachments = Array.from(completeTask.attachments)

            if ((!type || !type.restricted)
                && attachments.find(a => a.type === 'application/pdf')
                && !confirm('You are attaching a PDF that can be viewed by non-senior users. Continue?')) {
                return
            }

            attachments.forEach(a => {
                const url = 'attachment/' + viewTask.jobId + '/' + viewTask.taskId + '/' + a.name

                addRequest(
                    url,
                    {
                        url,
                        method: 'POST',
                    },
                    body => {
                        put(
                            body.url,
                            a,
                            {
                                headers: {
                                    'content-type': a.type,
                                }
                            }
                        )
                            .then(() => {
                                if (job) {
                                    addRequest(
                                        'job-attachments-' + job.jobId,
                                        {
                                            url: 'attachment?prefix=' + job.jobId,
                                            method: 'GET',
                                        },
                                        body => populateJobAttachments(body)
                                    )
                                }
                            })
                    })
            })
        }

        addRequest(
            'completeTask',
            {
                url: 'task/' + viewTask.taskId,
                method: 'PATCH',
                body: completeTask,
            },
            () => {
                if (job) {
                    const jobId = job.jobId

                    addRequest(
                        'job-tasks-' + jobId,
                        {
                            url: 'task?jobId=' + jobId,
                            method: 'GET',
                        },
                        body => populateJobTasks(body, jobId)
                    )

                    addRequest(
                        'job-' + jobId,
                        {
                            url: 'job/' + jobId,
                            method: 'GET',
                        },
                        body => populateJob(body)
                    )
                } else {
                    addRequest(
                        'week-' + weekStartDate,
                        {
                            url: 'booking?weekStart=' + weekStartDate,
                            method: 'GET',
                        },
                        body => {
                            populateWeekData(weekStartDate, body)
                        }
                    )

                    addRequestForTasks(
                        personId,
                        status,
                        taskType,
                        body => updateTasks(body)
                    )
                }

                clearCompleteTask()
                clearViewTask()
                if (completeTask.nextTaskType && completeTask.nextTaskType !== 'None') {
                    const nextTaskType = getTaskType(completeTask.nextTaskType)

                    if (nextTaskType.booking) {
                        updateCalendarJob(job)
                        updateCalendarTaskType(nextTaskType.name)

                        if (location.pathname.indexOf('calendar') === -1) {
                            history.push('/calendar')
                        }
                    } else {
                        newAddTask(completeTask.nextTaskType)
                    }
                }
            }
        )
    }

    handleCheckBoxChange = (e) => this.props.updateCompleteTask(e.target.id, e.target.checked ? true : null)

    handleChange(e) {
        this.props.updateCompleteTask(e.target.id, e.target.value);
    }

    handleFileChange(e) {
        this.props.updateCompleteTask('attachments', e.target.files);
    }

    render () {
        const {
            viewTask,
            completeTask,
            updateCompleteTask,
        } = this.props

        if (!viewTask || !completeTask)
            return null

        let type = getTaskType(viewTask.type)

        if (!type) {
            type = { name: 'Note'}
        }

        let fields

        if (type.complete) {
            fields = type.complete
                .map(field => this.getFieldSection(field, completeTask[field.typeId]))
                .filter(f => (f))
        }

        let nextTaskOptions = null

        if (type.next && viewTask.status === TASK_STATES.OPEN) {
            nextTaskOptions = TASK_TYPES
                .map(t => t.name)
                .filter(t => type.next.indexOf(t) !== -1)
                .map(type => ({
                    id: type,
                    value: type,
                }))

            if (!type.nextIsMandatory) {
                nextTaskOptions.push({
                    id: 'None',
                    value: 'None'
                })
            }
        }


        return (
            <Modal show={true} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal>
                        {fields}
                        <FormGroup controlId="note">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Note</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FormControl
                                    componentClass="textarea"
                                    onChange={this.handleChange}
                                    value={completeTask.note}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="attachments">
                            <Col xs={12} sm={3}>
                                <ControlLabel>Attachments</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <input type="file" onChange={this.handleFileChange} multiple />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            controlId="nextTaskType"
                            className={nextTaskOptions ? '' : 'hide'}
                        >
                            <Col xs={12} sm={3}>
                                <ControlLabel>Next task</ControlLabel>
                            </Col>
                            <Col xs={12} sm={9}>
                                <Selector
                                    id={'next-task-type-selector'}
                                    items={nextTaskOptions}
                                    selectedId={completeTask.nextTaskType}
                                    selectedStyle={'info'}
                                    prefixTitle={false}
                                    title={'Choose ...'}
                                    valueUpdated={updateCompleteTask}
                                    valueUpdatedId={'nextTaskType'}
                                    closeButton={false}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={this.completeThisTask}
                        className={'pull-right'}
                        bsStyle={'success'}
                    >Complete</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    getFieldSection(field, value) {
        let fieldInput

        switch (field.input) {
            case 'textbox':
                fieldInput = <FormControl
                    componentClass="textarea"
                    onChange={this.handleChange}
                    value={value}
                />
                break;

            case 'money':
                fieldInput = <InputGroup>
                    <InputGroup.Addon>£</InputGroup.Addon>
                    <FormControl
                        type="text"
                        onChange={this.handleChange}
                        value={value}
                    />
                    <InputGroup.Addon>(ex. VAT)</InputGroup.Addon>
                </InputGroup>
                break;

            case 'checkbox':
                fieldInput = <Checkbox
                    onChange={this.handleCheckBoxChange}
                    id={field.typeId}
                    checked={value}
                    value={true}
                />
                break;

            default:
                return null
        }

        return <FormGroup controlId={field.typeId} key={field.typeId}>
            <Col xs={12} sm={3}>
                <ControlLabel>{field.name}</ControlLabel>
            </Col>
            <Col xs={12} sm={9}>
                {fieldInput}
            </Col>
        </FormGroup>
    }
}

const mapStateToProps = (state) => {
    return {
        viewTask: state.viewTask,
        completeTask: state.completeTask,
        weekStartDate: state.calendar.weekStartDate,
        job: state.job.job,
        personId: state.tasks.personId,
        status: state.tasks.status,
        taskType: state.tasks.type,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        clearCompleteTask: () => {dispatch(clearCompleteTask())},
        clearViewTask: () => {dispatch(clearViewTask())},
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
        populateWeekData: (weekStart, bookings) => {dispatch(populateWeek(weekStart, bookings))},
        updateCompleteTask: (key, value) => dispatch(updateCompleteTask(key, value)),
        populateJobAttachments: (attachments) => {dispatch(populateJobAttachments(attachments))},
        populateJobTasks: (tasks, jobId) => {dispatch(populateJobTasks(tasks, jobId))},
        populateJob: (job) => {dispatch(populateJob(job))},
        newAddTask: (taskType) => {dispatch(newAddTask(taskType))},
        updateTasks: tasks => {dispatch(updateTasks(tasks))},
        addRequestForTasks: (personId, status, taskType, callback) => {dispatch(addRequestForTasks(personId, status, taskType, callback))},
        updateCalendarJob: (job) => {dispatch(updateCalendarJob(job))},
        updateCalendarTaskType: (taskType) => {dispatch(updateCalendarTaskType(taskType))},
    })
}

const CompleteTaskWithRouter = withRouter(CompleteTask)

export default connect(mapStateToProps, mapDispatchToProps)(CompleteTaskWithRouter)
