import React, { Component } from 'react'
import { Button, Glyphicon } from 'react-bootstrap'
import { connect } from 'react-redux'
import { updateSubsectionKeyValue } from '../../state/actions.js'

class TakePhoto extends Component {
    render() {
        const {
            takePhoto
        } = this.props

        return (
            <Button
                id={'job-take-photo'}
                type="button"
                bsStyle="info"
                onClick={takePhoto}
            >
                <span><Glyphicon glyph="camera" /> Photo</span>
            </Button>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        takePhoto: () => {dispatch(updateSubsectionKeyValue('job', 'takePhoto', 'note', ''))},
    })
}

export default connect(null, mapDispatchToProps)(TakePhoto)
